/* eslint-disable consistent-return */
import React, { useState, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import api from '../../services/api'
import { useSnackbar } from '../../shared/hooks'
import { useLinkProgramsService } from '../../pages/ProgramList/hooks'
import { HttpStatus } from '../../utils/HttpStatus'

export const ClassroomContext = createContext({
  programType: '',
  listObject: [],
  textMeses: {},
  listSteps: [],
  textMaterial: [],
  progresso: 0,
  material: 0,
  indexList: 0,
  setProgramType: () => '',
  setObject: () => {},
  setListObjectClear: () => [],
  setObjectClear: () => {},
  setListSteps: () => [],
  setListClassrooms: () => [],
  setListMultiplePrograms: () => [],
  setProgresso: () => 0,
  setMaterial: () => 0,
  setIndexList: () => 0,
  handleProgram: async () => 0,
  setHaveResponse: () => false,
  handleSaveListObjected: async () => null,
})

export default function ClassroomProvider({ children }) {
  const { snackbarSuccess } = useSnackbar()
  const [programType, _setProgramType] = useState([])
  const [object, _setObject] = useState({})
  const [listObject, _setListObject] = useState([])
  const [listSteps, _setListSteps] = useState([])
  const [listMultiplePrograms, _setListMultiplePrograms] = useState([])
  const [progresso, _setProgresso] = useState(0)
  const [indexList, setIndexList] = useState(0)
  const [material, _setMaterial] = useState(0)
  const [textMaterial, setTextMaterial] = useState([])
  const [responseData, setResponseData] = useState()
  const [idsClassroom, setIdsClassroom] = useState([])
  const [checkResponse, setCheckResponse] = useState(false)
  const [haveResponse, _setHaveResponse] = useState(false)

  const { linkProgramsService } = useLinkProgramsService()

  const textMeses = {
    janeiro: '',
    fevereiro: '',
    marco: '',
    abril: '',
    maio: '',
    junho: '',
    julho: '',
    agosto: '',
    setembro: '',
    outubro: '',
    novembro: '',
    dezembro: '',
  }

  const setHaveResponse = value => {
    _setHaveResponse(value)
  }

  const checkHaveResponse = async (classroomId, isView) => {
    const { data } = await api.get(`programCurricular/classRoom/${classroomId}`)
    if (data.length > 0 && isView) {
      setResponseData(data)
      setHaveResponse(true)
    }
    setCheckResponse(true)
  }

  useEffect(() => {
    if (object.classRoomId) {
      checkHaveResponse(object.classRoomId, object.isView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object.classRoomId])

  // eslint-disable-next-line consistent-return
  const handleProgram = async id => {
    const { data } = await api.get(`programCurricularComponent/program/${id}`)

    // eslint-disable-next-line no-unused-vars, no-shadow
    const object = []

    // eslint-disable-next-line no-restricted-syntax
    for (const dados of data) {
      object.push({ id: dados.curricularComponentId.id, name: dados.curricularComponentId.name })
    }
    setTextMaterial(object)

    return object.length
  }

  const setProgramType = id => {
    _setProgramType(id)
    handleProgram(id)
  }

  const setListSteps = value => {
    _setListSteps(value)
  }
  const setListMultiplePrograms = value => {
    _setListMultiplePrograms(value)
  }

  // eslint-disable-next-line no-shadow
  const setObject = values => {
    _setObject(prevState => ({
      ...prevState,
      ...values,
    }))
  }

  const setListClassrooms = values => {
    setIdsClassroom(values)
  }

  // eslint-disable-next-line no-unused-vars
  const setObjectClear = () => {
    _setObject({})
  }

  const setListObjectClear = () => {
    _setListObject([])
  }
  const setProgresso = value => {
    _setProgresso(value)
  }

  const handleSaveListObjected = async isView => {
    const list = {
      programCurricularRequests: listObject,
      classroomIds: idsClassroom,
    }

    const responsePrograms = !isView
      ? await linkProgramsService(
          Array.isArray(listMultiplePrograms) ? listMultiplePrograms : [listMultiplePrograms],
        )
      : true

    if (!isEmpty(responsePrograms)) {
      const response = await api.post(`programCurricular/saveAll`, list)
      if (response.status === HttpStatus.CREATED) {
        snackbarSuccess('Dados inseridos com sucesso!')
        return true
      }
    } else {
      return false
    }
  }

  const setMaterial = (index, meses, isGoToBack, finish) => {
    _setMaterial(index)

    if (isGoToBack) {
      if (!finish) {
        setIndexList(indexList > 0 && indexList - 1)
      }
    } else {
      if (indexList < listObject.length) {
        _setListObject(prevState => {
          const updatedList = [...prevState]
          updatedList[indexList] = { ...updatedList[index], ...{ ...object, ...meses } }
          return updatedList
        })
      } else {
        _setListObject(prevState => [...prevState, { ...object, ...meses }])
      }
      if (!finish) {
        setIndexList(indexList + 1)
      }
    }
  }

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ClassroomContext.Provider
      value={{
        programType,
        listObject,
        textMeses,
        listSteps,
        textMaterial,
        progresso,
        material,
        indexList,
        checkResponse,
        haveResponse,
        responseData,
        setCheckResponse,
        setHaveResponse,
        setResponseData,
        setProgramType,
        setObject,
        setProgresso,
        setMaterial,
        setListSteps,
        handleSaveListObjected,
        setObjectClear,
        setListObjectClear,
        setIndexList,
        setListClassrooms,
        setListMultiplePrograms,
        handleProgram,
      }}
    >
      {children}
    </ClassroomContext.Provider>
  )
}

ClassroomProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
