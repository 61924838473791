const getSelectedClassrooms = () => {
  return new Map(JSON.parse(localStorage.getItem('selectedClassrooms')))
}

const saveSelectedClassrooms = (selectedClassrooms = []) => {
  const selectedClassroomsMap = new Map()

  selectedClassrooms.forEach(selectedClassroom => {
    if (!selectedClassroomsMap.has(selectedClassroom.id)) {
      selectedClassroomsMap.set(selectedClassroom.id, selectedClassroom)
    }
  })

  const selectedClassroomsString = JSON.stringify(Array.from(selectedClassroomsMap.entries()))
  localStorage.setItem('selectedClassrooms', selectedClassroomsString)

  return selectedClassroomsMap
}

export { getSelectedClassrooms, saveSelectedClassrooms }
