export default function() {
  return {
    gridMain: {
      height: '100px',
      marginTop: '0px',
      backgroundColor: '#f4f7fc',
      borderRadius: '10px',
    },
    gridLine: {
      height: '12px',
      width: '100%',
      backgroundColor: '#d9e0eb',
    },
    gridTextQuantity: {
      fontFamily: 'Barlow,Roboto,sans-serif',
      fontWeight: '600',
      color: '#566573',
      padding: '30px',
      display: 'flex',
      alignItems: 'baseline',
    },
    textQuantityOne: {
      fontFamily: 'Barlow,Roboto,sans-serif',
      fontWeight: '900',
      color: '#273746',
      marginLeft: '5px',
      fontSize: '16px',
    },
    textQuantityTwo: {
      fontFamily: 'Barlow,Roboto,sans-serif',
      fontWeight: '600',
      color: '#566573',
      fontSize: '12px',
    },
  }
}
