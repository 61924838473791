import { Button, Dialog, Grid, Typography } from '@material-ui/core'
import React from 'react'
import close from '../../../../../components/ResearcherInstructionAndWelcome/img/close.svg'
import { useResponsiveInfo } from '../../../../../shared/hooks'

const CloseAssessmentModal = ({ open, handleClose, onExitAssessment }) => {
  const { isDesktop, isMobile, isTablet } = useResponsiveInfo()
  const returnResponsiveWidth = () => {
    if (isDesktop) {
      return '20%'
    }
    if (isMobile) {
      return '85%'
    }
    if (isTablet) {
      return '40%'
    }

    return ''
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: returnResponsiveWidth(),
          borderRadius: '8px',
        },
      }}
    >
      <Grid style={{ padding: 25 }}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography style={{ color: '#003C78', fontSize: 24, fontWeight: 700 }}>
            ATENÇÃO
          </Typography>
          <Grid style={{ display: 'flex', alignItems: 'center' }} onClick={handleClose}>
            <img src={close} alt="close-action" style={{ cursor: 'pointer', width: '20px' }} />
          </Grid>
        </Grid>
        <Grid style={{ textAlign: 'center', marginTop: 15 }}>
          <Typography style={{ color: '#003C78' }}>
            Ao sair da página, você vai parar de preencher o instrumento e{' '}
            <b>seu progresso será salvo. </b>
            <br />
            Deseja, mesmo assim, sair da página?
          </Typography>
        </Grid>
        <Grid style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            style={{ backgroundColor: '#FFCA00', color: '#003C78', fontWeight: 700 }}
            onClick={onExitAssessment}
          >
            SAIR DA AVALIAÇÃO
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default CloseAssessmentModal
