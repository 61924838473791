export const styles = theme => ({
  container: {
    position: 'fixed',
    right: '1px',
    bottom: '67px',
    zIndex: 4000,
    display: 'none',
  },
  fab: {
    zIndex: 2,
  },
  helpButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '28px',
    bottom: '5px',
    height: theme.spacing(3),
    width: '140px',
    padding: theme.spacing(1),
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    color: '#003C78',
    transition: 'all .5s ease-in',
    opacity: 1,
    boxSizing: 'content-box',
    background:
      'linear-gradient(0deg, rgba(220, 220, 220, 0.5), rgba(220, 220, 220, 0.5)), #FFFFFF',
  },
  hidden: {
    position: 'absolute',
    bottom: '5px',
    right: '28px',
    height: theme.spacing(3),
    width: '140px',
    padding: theme.spacing(1),
    opacity: 0,
    fontSize: '12px',
    overflow: 'hidden',
    boxSizing: 'content-box',
    visibility: 'hidden',
  },
  icon: {
    color: '#fff',
  },
})
