import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputLabel, Select, MenuItem, FormControl } from '@material-ui/core'

const CountryStatesSelect = ({ countryStates, handleCountryStateChange }) => {
  const inputLabel = useRef(null)
  const [selectedState, setSelectedState] = useState('')
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [inputLabel])

  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <InputLabel ref={inputLabel}>Estado</InputLabel>
      <Select
        value={selectedState}
        labelWidth={labelWidth}
        onChange={e => {
          handleCountryStateChange(e.target.value)
          setSelectedState(e.target.value)
        }}
      >
        {countryStates.map(countryState => (
          <MenuItem key={countryState.id} value={countryState}>
            {countryState.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CountryStatesSelect.propTypes = {
  countryStates: PropTypes.array.isRequired,
  handleCountryStateChange: PropTypes.func.isRequired,
}

export default CountryStatesSelect
