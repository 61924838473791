export default function styles(theme) {
  return {
    card: {
      position: 'relative',
      borderRadius: theme.spacing(1),
      padding: 0,
      height: '100%',
      width: '100%',
      minHeight: 285,
    },
    content: {
      maxWidth: '100%',
      backgroundColor: '#F3F3F3',
      '& .MuiCardHeader-content': {
        maxWidth: '100%',
      },
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '13pt',
      fontFamily: 'Barlow',
      lineHeight: '19px',
      color: '#003C78',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subtitle: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontFamily: 'Barlow',
      fontSize: '14pt',
      lineHeight: '19px',
      color: '#3E3E3E',
      paddingTop: '9px',
      paddingBottom: '12px',
    },
    actions: {
      position: 'absolute',
      right: '0px',
    },
    pointer: {
      cursor: 'pointer',
    },

    menu: {
      '& .MuiMenuItem-root': {
        margin: 0,
        padding: 0,
        borderRadius: '50%',
      },

      '& .MuiMenuItem-root:hover': {
        backgroundColor: '#e5e5e5',
      },
    },
  }
}
