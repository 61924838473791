import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TableCell, TableHead, TableRow } from '@material-ui/core'
import { ArrowDropUpOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import styles from './HeaderTable.style'

const useStyles = makeStyles(theme => styles(theme))

const TreeDataTableCell = props => {
  const { headerStyle } = props

  return <TableCell padding="none" key="key-tree-data-header" style={{ ...headerStyle }} />
}

export default function HeaderTable({ tableProps, columns, onOrderTable, sort, gridStyle }) {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          if (column?.sorting && tableProps?.dataCount > 0) {
            const iconDirection = sort?.direction?.toLowerCase()

            return (
              <TableCell key={column.field} style={tableProps.headerStyle}>
                <Grid
                  style={gridStyle}
                  className={classes.tableCellButtonWrapper}
                  onClick={() => onOrderTable(column.field)}
                >
                  {column.title}
                  {sort?.fieldName === column.field && (
                    <ArrowDropUpOutlined className={`icon ${iconDirection}`} fontSize="large" />
                  )}
                </Grid>
              </TableCell>
            )
          }

          if (index === 0 && tableProps.isTreeData) {
            return <TreeDataTableCell key={column.field} headerStyle={tableProps.headerStyle} />
          }

          return (
            <TableCell key={column.field} style={tableProps.headerStyle}>
              {column.title}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

HeaderTable.propTypes = {
  columns: PropTypes.array.isRequired,
  gridStyle: PropTypes.object,
  onOrderTable: PropTypes.func.isRequired,
  sort: PropTypes.object,
  tableProps: PropTypes.object.isRequired,
}

HeaderTable.defaultProps = {
  sort: null,
  gridStyle: {
    cursor: 'pointer',
    justifyContent: 'center',
  },
}
