import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  DialogContentText,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './RegionalCoordinatorSelfDeclarationDialog.style'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const RegionalCoordinatorSelfDeclarationDialog = ({ handleAcceptSelfDeclaration, open, user }) => {
  const classes = useStyles()

  return (
    open && (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogContent className={classes.dialog}>
            <Typography className={classes.title}>Autodeclaração</Typography>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography className={classes.text} component="label">
                Declaro ser integralmente responsável por todas as informações e dados pessoais que
                vier ter acesso no uso desta plataforma.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <Button
              color="primary"
              size="large"
              onClick={() => handleAcceptSelfDeclaration(user.id)}
            >
              Sim, podemos continuar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  )
}

RegionalCoordinatorSelfDeclarationDialog.propTypes = {
  handleAcceptSelfDeclaration: PropTypes.func.isRequired,
  open: PropTypes.bool,
  user: PropTypes.object,
}

RegionalCoordinatorSelfDeclarationDialog.defaultProps = {
  user: {},
  open: false,
}

export default React.memo(RegionalCoordinatorSelfDeclarationDialog)
