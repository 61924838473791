export default function styles() {
  return {
    container: {
      background: '#003C78',
      color: '#3E3E3E',
      height: '100%',
      paddingTop: 30,
    },
    title: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '130%',
      color: '#FCFCFC',
      cursor: 'pointer',
    },
    icon: {
      marginLeft: '20px',
      marginRight: '10px',
      color: '#FCFCFC',
    },
    titleSair: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '130%',
      color: '#FCFCFC',
      cursor: 'pointer',
      margin: '0 0 0 25px',
    },
    divider: {
      width: '100%',
      margin: '120px 0 0 0',
      background: '#FCFCFC',
    },
    exit: {
      color: '#FCFCFC',
      justifyContent: 'flex-end',
    },
    footerContainer: {
      backgroundColor: '#003C78',
      paddingTop: '20px',
      paddingBottom: '30px',
      textAlign: 'center',
      borderTop: '2px solid white',
    },
    footerContent: {
      color: 'white',
      fontSize: 14,
    },
    genericListItemContent: {
      color: 'white',
      fontSize: '14px',
      fontFamily: 'Barlow',
    },
  }
}
