import React, { useContext, useState } from 'react'
import FooterLoggedIn from './components/FooterLoggedIn'
import FooterLoggedOut from './components/FooterLoggedOut'
import ReportModal from '../ReportModal'
import AuthContext from '../../contexts/AuthContext'

const Footer = ({ history }) => {
  const { signedIn } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  return (
    <>
      <ReportModal open={open} setOpen={setOpen} />
      {signedIn ? (
        <FooterLoggedIn history={history} isLoggedIn setModal={setOpen} />
      ) : (
        <>
          <FooterLoggedOut setModal={setOpen} />
          <FooterLoggedIn history={history} setModal={setOpen} />
        </>
      )}
    </>
  )
}

export default Footer
