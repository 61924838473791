const tableOptions = {
  actionsColumnIndex: -1,
  filtering: false,
  search: false,
  showTitle: false,
  toolbar: false,
  paging: false,
  headerStyle: { backgroundColor: 'rgba(0, 60, 120, 0.2)', color: '#003C78', fontWeight: 'bold' },
  actionsCellStyle: { direction: 'rtl' },
}

const tableLocalization = {
  body: {
    emptyDataSourceMessage: `Não existem resultados para a pesquisa realizada`,
    editRow: {
      saveTooltip: 'Salvar',
      cancelTooltip: 'Cancelar',
    },
    editTooltip: 'Editar vinculo',
  },
}

const tableSortNameMapping = {
  schoolName: 'school.name',
  classroomName: 'name',
  gradeName: 'grade.name',
  schoolShiftName: 'schoolShift.name',
}

const pageSizeOptions = [10, 50, 100]

const breadcrumbItems = [
  {
    name: 'Início',
    path: '/dashboard',
  },
  {
    name: 'Programas',
  },
]

export { breadcrumbItems, tableLocalization, tableOptions, tableSortNameMapping, pageSizeOptions }
