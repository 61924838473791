import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './FooterTable.style'
import PaginationTable from '../PaginationTable'

const useStyles = makeStyles(theme => styles(theme))

export default function FooterTable({ description, offset, data, onPressChangePage }) {
  const classes = useStyles()
  const isOffsetIsZero = offset === 0
  const isOffsetIsLast = offset === data.totalPages - 1

  return (
    <Grid container justify="center" alignItems="center" className={classes.gridMain}>
      <Grid item className={classes.gridLine} />
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Grid item xs={6} className={classes.gridTextQuantity}>
          {description}
          <Typography className={classes.textQuantityOne}>
            {Number(data?.numberOfElements || 0) + Number(data?.pageable?.offset || 0)}
          </Typography>
          <Typography className={classes.textQuantityTwo}>/{data?.totalElements || 0}</Typography>
        </Grid>
        <Grid item xs={6}>
          <PaginationTable
            offset={offset}
            data={data}
            onPressChangePage={onPressChangePage}
            isOffsetIsZero={isOffsetIsZero}
            isOffsetIsLast={isOffsetIsLast}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

FooterTable.propTypes = {
  data: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
  onPressChangePage: PropTypes.func.isRequired,
}
