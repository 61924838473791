import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
} from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StyleUsageHelperModal = props => {
  const { open, onModalClose } = props

  const availableStyles = [
    {
      name: 'Negritar',
      description: () => (
        <>
          Adicione as tags <strong>{'<b>'}</strong> e <strong>{'</b>'}</strong> em volta do texto
          desejado.
        </>
      ),
      example: '<b>Texto em negrito</b>',
    },
    {
      name: 'Italico',
      description: () => (
        <>
          Adicione as tags <strong>{'<i>'}</strong> e <strong>{'</i>'}</strong> em volta do texto
          desejado.
        </>
      ),
      example: '<i>Texto em itálico</i>',
    },
    {
      name: 'Grifar',
      description: () => (
        <>
          Adicione as tags <strong>{'<u>'}</strong> e <strong>{'</u>'}</strong> em volta do texto
          desejado.
        </>
      ),
      example: '<u>Texto grifado</u>',
    },
  ]

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Como usar os estilos disponíveis?</DialogTitle>

        <DialogContent>
          <Table>
            <TableHead>
              <TableCell>Estilo</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Exemplo</TableCell>
            </TableHead>

            <TableBody>
              {availableStyles.map(style => (
                <TableRow>
                  <TableCell>
                    <strong>{style.name}</strong>
                  </TableCell>
                  <TableCell>{style.description()}</TableCell>
                  <TableCell>{style.example}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>

        <DialogActions>
          <Button onClick={onModalClose} color="primary" variant="contained">
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

StyleUsageHelperModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default StyleUsageHelperModal
