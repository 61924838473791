export default function styles(theme) {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '1px 1px 4px #00000040',
      overflow: 'auto',

      width: '800px',
      height: '650px',

      opacity: 1,
      borderRadius: theme.spacing(0.5),
    },
    termBox: {
      height: '440px',
      padding: '0px 32px',
      width: '100%',
      overflow: 'auto',
    },
    title: {
      alignItems: 'flex-end',
      display: 'flex',
      padding: ' 32px 32px 10px 32px',
      textAlign: 'center',
      letterSpacing: '0px',
      color: '#003C7B',
      opacity: 1,
      height: '130px',
      width: '100%',
    },
    termBody: {
      paddingTop: '24px',
      width: '100%',
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#3E3E3E',
      opacity: 1,
      whiteSpace: 'pre-line',
    },
    policyTermTitle: {
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#003C7B',
      opacity: 1,
      whiteSpace: 'pre-line',
      width: '100%',
      paddingTop: '24px',
    },
    buttonsGroup: {
      height: '80px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '16px 35px 16px 0px',
    },
    button: {
      width: '184px',
      height: '48px',
      border: '1px solid #003C7B',
      borderRadius: theme.spacing(0.5),
      opacity: 1,
      marginLeft: '32px',
    },
    buttonAccept: {
      width: '500px',
      height: '48px',
      border: '1px solid #003C7B',
      borderRadius: theme.spacing(0.5),
      opacity: 1,
      marginLeft: '32px',
    },
    buttonDisabled: {
      color: '#FFFF !important',
      backgroundColor: 'rgba(0, 60, 123, 0.30) !important',
      border: 'none !important',
    },
  }
}
