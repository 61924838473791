import { getSelectedClassrooms, saveSelectedClassrooms } from '../utils/selectedClassrooms'

const useSelectionClassroomChange = () => {
  return (checkedRows, checkedRow, setSelectedClassrooms, updatedClassroomsData) => {
    const savedSelectedClassrooms = Array.from(getSelectedClassrooms().values())
    const selectAll = checkedRow === undefined && checkedRows.length > 0
    const unselectAll = checkedRow === undefined && checkedRows.length === 0
    let selectedClassroomsList = []

    if (selectAll) {
      selectedClassroomsList = [...savedSelectedClassrooms, ...checkedRows]
    }

    if (unselectAll) {
      const updatedClassroomsDataMap = new Map()
      updatedClassroomsData.forEach(classroom =>
        updatedClassroomsDataMap.set(classroom.id, classroom),
      )

      selectedClassroomsList = [...savedSelectedClassrooms].filter(selectedClassroom => {
        return !updatedClassroomsDataMap.has(selectedClassroom.id)
      })
    }

    if (!selectAll && !unselectAll) {
      selectedClassroomsList = [...savedSelectedClassrooms, ...checkedRows].filter(
        selectedClassroom => {
          return selectedClassroom.id === checkedRow.id ? checkedRow.tableData.checked : true
        },
      )
    }

    // Async classrooms selection to avoid render freeze by Material Table.
    setTimeout(() => {
      const saved = saveSelectedClassrooms(selectedClassroomsList)
      setSelectedClassrooms(saved)
    }, 0)
  }
}

export { useSelectionClassroomChange }
