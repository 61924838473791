import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import { Typography, Button, Grid, Card, Paper } from '@material-ui/core'
import { AddCircleOutline, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import style from './FilterContainer.style'
import { useResponsiveInfo } from '../../shared/hooks'
import CustomButton from '../../shared/CustomButton/CustomButton'

const useStyles = makeStyles(style)

const reducer = (state, action) => {
  switch (action.type) {
    case 'show':
      return { show: true, message: 'OCULTAR FILTROS', icon: KeyboardArrowUp }
    case 'hide':
      return { show: false, message: 'MOSTRAR FILTROS', icon: KeyboardArrowDown }
    default:
      return state
  }
}

const FilterContainer = ({
  children,
  title,
  hideInitFilter,
  handleCreate,
  buttonText,
  loading,
  showBotton,
  isFilter,
  titleCard,
  ariaControls,
  customComponent,
  hideFilter,
  customButton,
}) => {
  const classes = useStyles()
  const { isDesktop } = useResponsiveInfo()
  const [state, dispatch] = useReducer(reducer, {
    show: !hideInitFilter,
    message: hideInitFilter ? 'MOSTRAR FILTROS' : 'OCULTAR FILTROS',
    icon: hideInitFilter ? KeyboardArrowDown : KeyboardArrowUp,
  })

  const toggleHideFilters = () =>
    state.show ? dispatch({ type: 'hide' }) : dispatch({ type: 'show' })

  const Icon = () => <state.icon />

  return (
    <>
      {title && (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={
            customComponent
              ? `${classes.gridTextContainer}`
              : `${classes.gridTextContainerWithOutChild}`
          }
        >
          <Grid item>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
          </Grid>

          {!loading && showBotton && (
            <Grid item xs={12} sm={6} md={4} xl={3}>
              {!customButton ? (
                <Card
                  elevation={0}
                  className={classes.button}
                  aria-controls={ariaControls}
                  aria-haspopup={ariaControls}
                  onClick={handleCreate}
                >
                  <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item>
                      <AddCircleOutline className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" className={classes.buttonText}>
                        {buttonText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              ) : (
                <Grid container justifyContent="flex-end">
                  <CustomButton
                    onClick={handleCreate}
                    aria-controls={ariaControls}
                    aria-haspopup={ariaControls}
                    text={buttonText}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {customComponent && <div className={classes.customComponent}>{customComponent}</div>}
      {isDesktop && (
        <Paper
          elevation={0}
          className={customComponent ? classes.container : classes.containerWithOutCustom}
        >
          <Grid container className={classes.containerGrid}>
            <Grid
              item
              className={state.show ? `${classes.containerText}` : `${classes.containerTextHide}`}
              justify="space-between"
              alignItems="center"
              container
            >
              {isFilter && (
                <Grid item>
                  <Typography variant="body2" className={classes.containerTitle}>
                    Filtrar
                  </Typography>
                </Grid>
              )}

              {titleCard && (
                <Grid item>
                  <Typography variant="body2" className={classes.titleCard}>
                    {titleCard}
                  </Typography>
                </Grid>
              )}
              {!hideFilter && (
                <Grid item>
                  <Button
                    variant="text"
                    className={classes.hideFiltersText}
                    onClick={toggleHideFilters}
                  >
                    {state.message} <Icon />
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container className={`${classes.containerFields} ${!state.show && classes.hide}`}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}

FilterContainer.propTypes = {
  ariaControls: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  customComponent: PropTypes.any,
  hideInitFilter: PropTypes.bool,
  isFilter: PropTypes.bool,
  loading: PropTypes.bool,
  showBotton: PropTypes.bool.isRequired,
  title: PropTypes.string,
  titleCard: PropTypes.string,
}

FilterContainer.defaultProps = {
  ariaControls: '',
  customComponent: undefined,
  hideInitFilter: false,
  title: '',
  loading: false,
  isFilter: true,
  titleCard: '',
}

export default FilterContainer
