import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { useSnackbar } from '../../shared/hooks'

const CheckboxList = ({ disciplines, checkedDisciplines, onCheck, type }) => {
  const [selectedDisciplines, setSelectedDisciplines] = useState(checkedDisciplines)
  const { snackbarInfo } = useSnackbar()

  useEffect(() => {
    onCheck(selectedDisciplines)
  }, [onCheck, selectedDisciplines])

  const isChecked = () => {
    return (
      disciplines.filter(discipline =>
        selectedDisciplines.some(sDiscipline => sDiscipline.id === discipline.id),
      ).length === disciplines.length
    )
  }

  const handleChange = () => {
    const unselectAllDisciplines = selectedDisciplines.filter(
      discipline => !disciplines.some(sDiscipline => sDiscipline.id === discipline.id),
    )
    const allDisciplines = [...selectedDisciplines, ...disciplines]
    let selectAllDisciplines

    if (type === 'classroom') {
      selectAllDisciplines = allDisciplines.reduce((accDisciplines, discipline) => {
        if (
          discipline.qtdExaminee >= 10 &&
          typeof discipline.profileIdIfExistsActiveAssessment === 'undefined'
        ) {
          return [
            ...accDisciplines.filter(disc => {
              return disc.id !== discipline.id
            }),
            discipline,
          ]
        }

        return [
          ...accDisciplines.filter(disc => {
            return disc.id !== discipline.id
          }),
        ]
      }, allDisciplines)
    } else {
      selectAllDisciplines = allDisciplines.reduce((accDisciplines, discipline) => {
        return [
          ...accDisciplines.filter(disc => {
            return disc.id !== discipline.id
          }),
          discipline,
        ]
      }, allDisciplines)
    }

    return isChecked()
      ? setSelectedDisciplines(unselectAllDisciplines)
      : setSelectedDisciplines(selectAllDisciplines)
  }

  const getCurrentYear = () => {
    return new Date().getFullYear().toString()
  }

  function orderByActiveAssessmentIfTypeEqualsClassroom(classroomList) {
    if (type === 'classroom') {
      classroomList.sort((a, b) => {
        if (
          typeof a.profileIdIfExistsActiveAssessment === typeof b.profileIdIfExistsActiveAssessment
        ) {
          return 0
        }
        if (typeof a.profileIdIfExistsActiveAssessment !== 'undefined') {
          return 1
        }
        return -1
      })
    }
  }

  function returnItemsStructure(item) {
    return (
      <Grid container key={item.id}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedDisciplines.some(it => item.id === it.id)}
                onChange={() => {
                  const found = selectedDisciplines.filter(it => it.id === item.id)

                  if (type === 'classroom' && item.qtdExaminee < 10 && found.length === 0) {
                    snackbarInfo(
                      `Necessário uma turma de, no mínimo, 
                        10 estudantes para que ela seja incluída na avaliação.`,
                    )
                    return
                  }

                  if (
                    type === 'classroom' &&
                    typeof item.profileIdIfExistsActiveAssessment !== 'undefined'
                  ) {
                    snackbarInfo(`Ação Bloqueada. Turma já possui uma avaliação Ativa`)
                    return
                  }

                  if (found.length === 0) {
                    setSelectedDisciplines([...selectedDisciplines, item])
                  } else {
                    setSelectedDisciplines(selectedDisciplines.filter(it => it.id !== item.id))
                  }
                }}
              />
            }
            value={item.id}
            label={
              type === 'classroom' ? `${item.name} (${item.qtdExaminee} estudantes)` : item.name
            }
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item>
          <FormControlLabel
            checked={isChecked()}
            control={<Checkbox onChange={handleChange} />}
            value={disciplines}
            label="SELECIONAR TODAS"
          />
        </Grid>
      </Grid>
      {
        (orderByActiveAssessmentIfTypeEqualsClassroom(disciplines),
        disciplines.map(item => {
          if (
            (type === 'classroom' && item.schoolYear.name === getCurrentYear()) ||
            type !== 'classroom'
          ) {
            return returnItemsStructure(item)
          }
          return null
        }))
      }
    </>
  )
}

CheckboxList.propTypes = {
  checkedDisciplines: PropTypes.array.isRequired,
  disciplines: PropTypes.array.isRequired,
  onCheck: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default CheckboxList
