import { createMuiTheme } from '@material-ui/core/styles'
import indigo from '@material-ui/core/colors/indigo'
import lightBlue from '@material-ui/core/colors/lightBlue'
import { common } from '@material-ui/core/colors'
import colors from './colors'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Barlow', 'Roboto', 'sans-serif'].join(','),
    fontStyle: 'normal',
    h6: { fontWeight: 600 },
  },
  palette: {
    primary: {
      ...lightBlue,
      main: '#00A8E9',
    },
    secondary: {
      ...indigo,
      main: '#003C78',
      dark: '#003C78',
      danger: '#D35D67',
    },
    white: {
      ...common,
      main: 'white',
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'secondary',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        padding: '10px 48px',
        color: 'white',
        '&$sizeLarge': {
          padding: '10px 48px',
          background: colors.primaryLargeBlue,
          '&:hover': {
            background: colors.primaryLargeDarkBlue,
          },
          '&:focus': {
            boxShadow: `inset 0px 0px 0px 2px ${colors.containedDisabled}`,
          },
          '&$disabled': {
            color: 'white',
            background: colors.primaryLargeDisabled,
          },
        },
        '&$disabled': {
          color: 'white',
          background: colors.defaultButtonDisabled,
        },
        '&:focus': {
          boxShadow: `inset 0px 0px 0px 2px ${colors.containedDisabled}`,
        },
      },
      outlinedPrimary: {
        padding: '10px 48px',
        '&:focus': {
          backgroundColor: colors.outlinedHover,
        },
        '&$disabled': {
          color: colors.outlineText,
          border: `1px solid ${colors.outlineText}`,
          opacity: '0.3',
        },
        '&$sizeLarge': {
          padding: '10px 48px',
          border: `1px solid ${colors.outlineLargeText}`,
          color: colors.outlineLargeText,
          '&:hover': {
            backgroundColor: 'background: rgba(0, 60, 120, 0.1)',
          },
          '&:focus': {
            backgroundColor: colors.outlinedHover,
          },
          '&$disabled': {
            border: `1px solid ${colors.outlineLargeText}`,
            color: colors.outlineLargeText,
            opacity: '0.3',
          },
        },
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          fontWeight: 'bold',
          opacity: '0.7',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.2em',
      },
    },
    MuiTextField: {
      root: {
        background: '#fff',
        borderRadius: '4px',
      },
    },
    MuiLinearProgress: {
      determinate: {
        background: '#FFCA00',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#F3F3F3',
        color: '#00A8E9',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        marginRight: '3px',
      },
    },
    MuiCardHeader: {
      title: {
        maxWidth: 'calc(100% - 32px)',
      },
      action: {
        position: 'absolute',
        top: '8px',
        right: '16px',
      },
    },
  },
})

export default theme
