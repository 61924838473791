export default theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  containerTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#003C78',
  },
  card: {
    height: '90px',
    background: '#F3F3F3',
    border: '1px solid #DCDCDC',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
  emptyCard: {
    marginTop: theme.spacing(4),
    border: '1px dashed #DCDCDC',
    borderRadius: '4px',
    textAlign: 'center',
  },
  emptyCardTitle: {
    color: '#A6A6A6',
    fontSize: '14px',
    padding: theme.spacing(3, 0),
  },
  rowOne: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  iconButton: { padding: 0 },
  title: {
    fontStyle: 'normal',
    textTransform: 'capitalize',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#3E3E3E',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#A6A6A6',
  },
})
