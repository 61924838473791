import React from 'react'
import PropTypes from 'prop-types'
import PaginationComponent from 'material-ui-flat-pagination'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { getIn } from 'formik'
import appTheme from '../../utils/theme'
import { useResponsiveInfo } from '../../shared/hooks'

const useStyles = makeStyles(() => ({
  rootPagination: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: '60px',
  },
  rootPaginationMobile: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: '40px',
  },
  paginationButtonNotSelected: {
    backgroundColor: '#C4C4C4',
    color: '#FFFFFF',
    marginLeft: '4px',
    marginRight: '4px',
  },
  lastItemPagination: {
    backgroundColor: 'transparent',
    color: '#00A8E9',
    boxShadow: 'none',
  },
  itemDisabled: {
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
  },
}))

const Pagination = props => {
  const classes = useStyles()
  const { isDesktop } = useResponsiveInfo()

  const { offset, data } = props

  return (
    <MuiThemeProvider theme={appTheme}>
      <CssBaseline />
      <PaginationComponent
        offset={offset}
        total={getIn(data, 'totalPages', 0)}
        previousPageLabel="< Anterior"
        nextPageLabel="Próximo >"
        otherPageColor="inherit"
        currentPageColor="primary"
        // eslint-disable-next-line react/destructuring-assignment
        onClick={(e, offseet, page) => props.onPressChangePage(page)}
        classes={{
          root: !isDesktop ? classes.rootPaginationMobile : classes.rootPagination,
          colorInheritOther: classes.paginationButtonNotSelected,
          label: classes.paginationLabel,
          rootEnd: classes.lastItemPagination,
          disabled: classes.itemDisabled,
        }}
      />
    </MuiThemeProvider>
  )
}

Pagination.propTypes = {
  data: PropTypes.shape({
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  offset: PropTypes.number.isRequired,
  onPressChangePage: PropTypes.func.isRequired,
}

export default Pagination
