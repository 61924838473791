import api from '../../services/api'
// import { getSelectedProfile } from '../user/profileHelper'

export async function validarEmail(email, isEditing, id, validateActive) {
  try {
    const { data } = validateActive
      ? await api.get('user', { params: { email } })
      : await api.get(`user/find-by-email/without-validation/${email}`)
    if (isEditing) {
      return data.id === id
    }

    return false
  } catch (error) {
    return true
  }
}

export function hideEmail(email) {
  const domain = '*****'
  const address = email.split('@', 1)[0]
  const hiddenEmail = `${address.replace(/[a,e,i,o,u]/g, '*')}@${domain}`

  return hiddenEmail
}
