import { colors } from '@material-ui/core'

export default theme => ({
  container: {
    color: theme.palette.secondary[900],
  },
  chipGray: {
    color: 'white',
    backgroundColor: colors.blueGrey[300],
  },
  chipGreen: {
    color: 'white',
    backgroundColor: colors.green[500],
  },
  emptyQueryCard: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    background: '#F3F3F3',
    borderRadius: theme.spacing(1),
    padding: 11,
    cursor: 'pointer',
    minHeight: 285,
  },
  dialogCenterContentText: {
    textAlign: 'center',
  },
  dialogCenterAction: {
    justifyContent: 'center',
  },
  dialogCenterActionButton: {
    fontWeight: 'bold',
    margin: '0 12px',
  },
  dialogMultiSelect: {
    maxWidth: '400px',
    display: 'flex',
    margin: '24px 12px',
  },
  button: {
    width: 'auto',
    display: 'flex',
    background: '#00A8E9',
    borderRadius: theme.spacing(1),
    boxShadow: '0',
    color: '#fff',
    cursor: 'pointer',
    height: theme.spacing(5),
    justifyContent: 'flex-end',
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.spacing(1.75),
    lineHeight: '17px',
    paddingLeft: '0',
    textTransform: 'uppercase',
  },
  icon: {
    height: '100%',
    display: 'flex',
  },
  select: {
    width: '400px',
  },
  clearContainer: {
    gap: '24px',
    marginBottom: '12px',
  },
  clearButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  clearButton: {
    background: 'none',
    boxShadow: 'none',
    minWidth: '32px',

    '&:hover': {
      background: 'none',
      boxShadow: 'none',
    },
  },
  clearButtonIcon: {
    color: '#1a237e',

    '&:hover': {
      color: '#00A8E9',
    },
  },
  tableCellButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.65,

      '& .icon': {
        opacity: 0.65,
      },
    },

    '& .icon': {
      opacity: 0,

      '&.asc': {
        transform: 'rotate(180deg)',
        opacity: 1,
      },

      '&.desc': {
        transform: 'rotate(0)',
        opacity: 1,
      },
    },
  },
  fieldLabel: {
    fontWeight: 'bold !important',
    fontSize: '14px !important',
    color: '#2D3648',
    marginBottom: '5px',
  },
})
