import React, { useState } from 'react'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import HelpOutline from '@material-ui/icons/HelpOutline'
import { makeStyles } from '@material-ui/core/styles'
import { styles } from './HelpButton.styles'
import ReportModal from '../ReportModal'

const useclasses = makeStyles(styles)

export default function HelpButton() {
  const classes = useclasses()
  const [showLabel, setShowLabel] = useState(false)
  const [open, setOpen] = useState(false)

  const navigateToTicket = () => {
    window.open('https://institutoayrtonsenna5795.zendesk.com/hc/pt-br/requests/new', '_blank')
  }

  return (
    <div className={classes.container} id="help-button">
      <Fab
        color="primary"
        className={classes.fab}
        onMouseEnter={() => setShowLabel(true)}
        onMouseLeave={() => setShowLabel(false)}
        onClick={navigateToTicket}
      >
        <HelpOutline size="large" className={classes.icon} />
      </Fab>
      <Typography className={showLabel ? classes.helpButtonLabel : classes.hidden}>
        Precisa de ajuda?
      </Typography>
      <ReportModal open={open} setOpen={setOpen} />
    </div>
  )
}
