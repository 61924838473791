export default () => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,

    '&:first-child': {
      paddingTop: 1,
    },

    '& .MuiListItemAvatar-root': {
      minWidth: '20px',
      marginRight: '11px',
    },
  },

  listItemCollapsed: {
    '& .MuiListItemAvatar-root': {
      marginRight: 0,
    },
  },

  division: {
    width: '100%',
    // borderBottom: '1px solid #fff',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& > .MuiListItemText-root': {
      color: '#003C78',
    },

    '& svg': {
      color: '#003C78',
    },
  },

  divisionActive: {
    '& > .MuiListItemText-root': {
      color: '#00A8E9',
    },
    '& > .MuiListItemText-root span': {
      color: '#00A8E9',
    },

    '& > .MuiListItemAvatar-root svg': {
      color: '#00A8E9',
    },
  },

  item: {
    margin: 0,
    padding: '17px 0',
    color: '#003C78',

    '& svg': {
      color: '#003C78',
    },
  },

  iconAvatar: {
    width: '46px',
    height: '46px',

    color: '#003C78',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  userLoggedName: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 600,
  },
})
