import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const INITIAL_STATE = {
  title: '',
  text: '',
  handleConfirm: undefined,
  open: false,
  data: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return { ...state, open: true, ...action.payload }
    case 'close':
      return { ...state, open: false, ...action.payload }
    case 'change_text':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const ModalContext = createContext({
  state: {
    title: '',
    text: '',
    handleConfirm: undefined,
    open: false,
    data: {},
  },
  dispatch: undefined,
})

export default function ModalContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  function openModal(props) {
    dispatch({ type: 'open', payload: { ...props } })
  }

  function closeModal() {
    dispatch({ type: 'close' })
  }

  return (
    <ModalContext.Provider value={{ state, dispatch, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

ModalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
