import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const applicationTabs = {
  NONE: {
    type: 'SELECT_NONE',
    label: 'Nenhuma',
    tab: 0,
    url: undefined,
  },
  SENNA: {
    type: 'SELECT_SENNA',
    label: 'Senna',
    tab: 0,
    url: 'assessments',
  },
  DIALOGUES: {
    type: 'SELECT_DIALOGUES',
    label: 'Diálogos socioemocionais',
    tab: 1,
    url: 'farol-aplicacoes-dialogos/dialog-assessments',
  },
  WORK_WORLD_TOOL: {
    type: 'SELECT_WORK_WORLD_TOOL',
    label: 'Realizando Escolhas',
    tab: 2,
    url: 'work-world-tool/filter',
  },
  SENNA_KIDS: {
    type: 'SELECT_SENNA_KIDS',
    label: 'Senna para Crianças',
    tab: 3,
    url: 'senna-kids-assessments',
  },
  SENNA_ADULT: {
    type: 'SELECT_SENNA_ADULT',
    label: 'Senna adultos',
    tab: 4,
    url: 'senna-adult-assessments',
  },
}

const initialState = applicationTabs.NONE

export const ApplicationTypeContext = createContext({ ...initialState })

export function ApplicationTypeContextProvider({ children }) {
  const [applicationType, setApplicationType] = useState(initialState)

  function updateStateType(value) {
    setApplicationType(value)
  }

  return (
    <ApplicationTypeContext.Provider value={{ applicationType, updateStateType }}>
      {children}
    </ApplicationTypeContext.Provider>
  )
}

ApplicationTypeContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default ApplicationTypeContextProvider
