export const base64ToBlob = async (base64, type) => {
  const base64Response = await fetch(`data:${type};base64,${base64}`)
  const blob = await base64Response.blob()
  return blob
}

export const fileToBase64 = async file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function(event) {
      return resolve(reader.result)
    }
  })
}
