export default function styles() {
  return {
    loading: {
      position: 'fixed',
      zIndex: 4200,
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,.8)',
      color: '#fff',
    },
    image: {
      paddingTop: '24px',
      width: '320px',
      height: '260px',
      background: '0% 0% no-repeat padding-box',
    },
  }
}
