import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Breadcrumbs as BreadcrumbsSenna, withStyles } from '@material-ui/core'
import { DefaultBreadcrumb, MakingChoicesBreadcrumb } from './Breadcrumbs.styles'

const BreadcrumbsItem = ({ classes, item }) =>
  item.path ? (
    <Link color="inherit" to={item.path} className={classes.itemLink}>
      {item.name}
    </Link>
  ) : (
    <span className={classes.itemText}>{item.name}</span>
  )

BreadcrumbsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ]),
}

BreadcrumbsItem.defaultProps = {
  item: [''],
}

function Breadcrumbs({ items, classes }) {
  return (
    <BreadcrumbsSenna aria-label="Breadcrumb" separator=">" className={classes.list}>
      {items.map((item, i) => (
        <BreadcrumbsItem item={item} key={item.name || i} classes={classes} />
      ))}
    </BreadcrumbsSenna>
  )
}

Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ]),
  ),
}

Breadcrumbs.defaultProps = {
  items: [''],
}

const IsMakingChoicesLocation = () => {
  return window.location.pathname && window.location.pathname.indexOf('/realizando-escolhas') === 0
}

const ActualStyle = () => {
  return IsMakingChoicesLocation() ? MakingChoicesBreadcrumb : DefaultBreadcrumb
}

export default withStyles(ActualStyle())(Breadcrumbs)
