/* eslint-disable consistent-return */
import api from '../../services/api'

const handleError = (error, snackbarError) => {
  let snackMessage = 'Ops! Sistema instável, tente novamente!'

  if (error?.response?.data) {
    const { message, detail } = error.response.data
    snackMessage = `${message}: ${detail}`
  }
  snackbarError(snackMessage)
}

/* GET - DATA */
export const getData = async ({
  url,
  options = {},
  snackbarError = () => {},
  setIsLoading = () => {},
  setLoading = () => {},
}) => {
  setIsLoading(true)
  setLoading(true)
  try {
    const { data } = await api.get(url, options)
    return data
  } catch (error) {
    handleError(error, snackbarError)
    return {}
  } finally {
    setIsLoading(false)
    setLoading(false)
  }
}

/* POST - DATA */
export const postData = async ({
  url,
  data,
  snackbarSuccess,
  successMessage,
  snackbarError,
  setIsLoading = () => {},
}) => {
  setIsLoading(true)
  try {
    await api.post(url, data)
    snackbarSuccess(successMessage)
    return data
  } catch (error) {
    handleError(error, snackbarError)
    return {}
  } finally {
    setIsLoading(false)
  }
}

/* PUT - DATA */
export const putData = async ({
  url,
  data,
  snackbarSuccess,
  successMessage,
  snackbarError,
  setIsLoading = () => {},
}) => {
  setIsLoading(true)
  try {
    await api.put(url, data)
    snackbarSuccess(successMessage)
    return data
  } catch (error) {
    handleError(error, snackbarError)
    return {}
  } finally {
    setIsLoading(false)
  }
}

/* DELETE - DATA */
export const deleteData = async ({
  url,
  snackbarSuccess,
  successMessage,
  snackbarError,
  dispatch = {},
}) => {
  try {
    await api.delete(url)
    snackbarSuccess(successMessage)
  } catch (error) {
    handleError(error, snackbarError)
    return {}
  }
  dispatch({ type: 'close' })
}

/* PATCH - DATA */
export const patch = async ({
  url,
  options = {},
  snackbarSuccess,
  successMessage,
  snackbarError = () => {},
  setIsLoading = () => {},
  setLoading = () => {},
}) => {
  setIsLoading(true)
  setLoading(true)
  try {
    const { data } = await api.patch(url, options)
    snackbarSuccess(successMessage)
    return data
  } catch (error) {
    handleError(error, snackbarError)
    return {}
  } finally {
    setIsLoading(false)
    setLoading(false)
  }
}
