export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      width: '100%',
      background: '#F3F3F3',
      boxSizing: 'border-box',
      borderRadius: '8px',
      color: '#3E3E3E',
    },
    containerWithOutCustom: {
      display: 'flex',
      width: '100%',
      background: '#F3F3F3',
      boxSizing: 'border-box',
      borderRadius: '8px',
      color: '#3E3E3E',
    },
    hide: {
      transition: 'all 0.3s linear',
      maxHeight: 0,
      opacity: 0,
    },
    containerText: {
      height: '24px',
      marginBottom: '27px',
      alignContent: 'center',
      padding: '0.5rem 0 0 0.5rem;',
    },
    containerTextHide: {
      height: '24px',
      marginBottom: '0px',
      alignContent: 'center',
    },
    containerFields: {
      display: 'flex',
      width: '100%',
      background: '#F3F3F3',
      boxSizing: 'border-box',
      color: '#3E3E3E',
      overflow: 'hidden',
      transition: 'all 0.6s linear',
      opacity: 100,

      justifyContent: 'space-evenly',
    },
    containerGrid: {
      width: '100%',
      margin: '1rem 0.5rem',
    },
    containerTitle: {
      fontSize: '16px',
      lineHeight: '17px',
    },
    title: {
      fontSize: '32px',
      lineHeight: '38px',
      color: '#003C78',
      justifyContent: 'flex-start',
      fontWeight: 600,
    },
    titleCard: {
      fontSize: '24px',
      lineHeight: '29px',
      color: '#003C78',
      justifyContent: 'flex-start',
    },
    gridTextContainer: {
      marginTop: theme.spacing(3.5),
    },
    gridTextContainerWithOutChild: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    hideFiltersText: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#00A8E9',
    },
    button: {
      display: 'flex',
      background: '#00A8E9',
      borderRadius: theme.spacing(1),
      boxShadow: '0',
      color: '#fff',
      cursor: 'pointer',
      height: theme.spacing(5),
      justifyContent: 'flex-end',
    },
    buttonText: {
      fontWeight: 600,
      fontSize: theme.spacing(1.75),
      lineHeight: '17px',
      paddingLeft: '0',
      textTransform: 'uppercase',
    },
    icon: {
      height: '100%',
      display: 'flex',
    },
    customComponent: {
      width: '100%',
    },
  }
}
