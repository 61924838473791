export default function styles() {
  return {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      background: '#3E3E3E',
      minHeight: '129px',
      padding: '20px 40px',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#FFF',
    },
    addressText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#FFF',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#003C78',
      height: '50px',
    },
    footerLogged: {
      display: 'flex',
      alignItems: 'center',
      background: '#003C78',
      justifyContent: 'center',
      height: 'auto',
      minHeight: '50px',
      zIndex: 1,
      top: 'auto',
      left: 'auto',
      bottom: '0',
      position: 'fixed',
      alignContent: 'center',
      width: '100%',
      '@media (max-height: 1400px) and (max-width: 950px)': {
        position: 'relative',
        paddingBottom: '10%',
        marginTop: '25%',
      },
      '@media (max-height: 1200px) and (max-width: 850px)': {
        position: 'relative',
        top: '50px',
        paddingBottom: '10%',
        marginTop: '15%',
      },
      '@media (max-width: 1200px) and (max-height: 800px)': {
        position: 'relative',
        top: '50px',
        paddingBottom: '10%',
        marginTop: '15%',
      },
    },
    copyrightLogged: {
      textAlign: 'center',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#FFF',
      marginLeft: '60px',
    },
    copyright: {
      textAlign: 'center',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#FFF',
    },
    termsAndPolicy: {
      alignItems: 'center',
      textAlign: 'right',
      fontSize: '12px',
      lineHeight: '14px',
      color: '#FFF',
      '@media (max-width: 800px) and (min-width: 320px)': {
        width: '100%',
      },
    },
    iconsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    icons: {
      color: '#FFF',
      height: '18px',
      width: '18px',
      marginRight: '6px',
      cursor: 'pointer',
    },
    linkText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '14px',
      fontSize: '12px',
      color: '#FFF',
      marginLeft: '27px',
    },
    link: {
      color: '#FFF',
      backgroundColor: 'transparent',
      border: 'none',
      textDecoration: 'underline',
      marginLeft: '4px',
    },
    modalStyle: {
      width: '100%',
      maxWidth: 900,
      margin: '30px auto',
      backgroundColor: '#fff',
      height: 700,
      borderRadius: 20,
      overflow: 'hidden',
    },
    logo: {
      marginRight: '18px',
      display: 'flex',
      opacity: 1,
    },
  }
}
