import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { matchSorter } from 'match-sorter'

const OneOrAllAutocomplete = ({
  options,
  label,
  handleChange,
  handleClose,
  handleReset,
  name,
  list,
  arrayHelpers: { push },
  showSelectAll = true,
}) => {
  const [selected, setSelected] = useState(null)
  const [currentList, setCurrentList] = useState([])

  useEffect(() => {
    const getCurrentList = () => {
      return options.filter(
        element => !list.find(selectedElement => element.id === selectedElement.id),
      )
    }

    const listWithOutSelected = getCurrentList()
    setCurrentList(listWithOutSelected)
  }, [list, options])

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }],
    })

  const handleFieldOption = (event, value) => {
    if (!value && typeof handleClose === 'function') handleClose()
    setSelected(value)

    if ((value && value.id) || (value && value.value === 'select-all')) {
      if (typeof handleChange === 'function') {
        handleChange(value)
      }
    }
  }

  const getCurrentList = option => {
    return list.findIndex(element => element.id === option.id)
  }

  const pushCleanObject = object => {
    push({ id: object.id, name: object.name, value: object.value })
  }

  const handleListChange = (event, selectedOption) => {
    if (selectedOption) {
      if (selectedOption.value === 'select-all') {
        currentList.filter(option => {
          if (option.value !== 'select-all' && getCurrentList(option) === -1) {
            pushCleanObject(option)
          }
          return null
        })
        setSelected(selectedOption)
      } else {
        handleReset(name)
        pushCleanObject(selectedOption)
        setSelected(selectedOption)
      }
    }

    return handleFieldOption(event, selectedOption)
  }
  const getValue = () => {
    if (selected === null) {
      if (list.length > 1) {
        const all = { name: 'SELECIONAR TODAS', value: 'select-all' }
        return all
      }
      return { ...list[0] }
    }
    return selected
  }

  return (
    <Autocomplete
      disableListWrap
      options={options}
      getOptionLabel={option => {
        if (option) {
          if (option.name) return `${option.name}`
        }
        return ''
      }}
      onKeyDown={() => {}}
      filterOptions={(opt, params) => {
        const filtered = filterOptions(opt, params)
        if (options.length > 0 && showSelectAll) {
          return [{ name: 'SELECIONAR TODAS', value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={(event, value) => handleListChange(event, value)}
      noOptionsText="Nenhuma opção disponível"
      value={getValue()}
      renderInput={params => <TextField {...params} label={label} variant="outlined" fullWidth />}
    />
  )
}

OneOrAllAutocomplete.propTypes = {
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
}

OneOrAllAutocomplete.defaultProps = {
  handleChange: () => {},
  handleClose: () => {},
  handleReset: () => {},
  options: [],
}

export default React.memo(OneOrAllAutocomplete)
