import { useState, useEffect } from 'react'

import { useSnackbar } from '../../../shared/hooks'
import { getData } from '../../../utils/fetchData/fetchData'

const useGetPrograms = classroomId => {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  const getPrograms = async () => {
    const params = {
      classroomId,
    }

    const url = classroomId === 0 || classroomId === undefined ? 'program' : 'program/classroomId'
    const options = classroomId === 0 || classroomId === undefined ? null : { params }

    const requestParams = {
      url,
      options,
      snackbarError: () => snackbarError('Falha ao buscar os programas, tente atualizar a página'),
      setLoading,
    }

    const programs = await getData(requestParams)
    setData(programs)
  }

  useEffect(() => {
    if (!isLoading || data) {
      getPrograms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    data,
  }
}

export { useGetPrograms }
