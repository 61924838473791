import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import { useSnackbar } from '../hooks'
import styles from './SchoolCards.style'

const SchoolCard = ({ key, classes, title, subtitle, handleRemove, isEditing }) => (
  <Card key={key} className={classes.card}>
    <div className={classes.rowOne}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {handleRemove && !isEditing && (
        <IconButton onClick={handleRemove} className={classes.iconButton}>
          <DeleteForeverOutlined />
        </IconButton>
      )}
    </div>
    {subtitle.map(item => {
      return (
        <Typography
          key={`key-${item.substring(1, 4)}`}
          variant="body1"
          className={classes.subtitle}
        >
          {item}
        </Typography>
      )
    })}
  </Card>
)

SchoolCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
  subtitle: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
SchoolCard.defaultProps = {
  handleRemove: null,
}

const SchoolCards = ({
  classes,
  schools,
  remove,
  schoolsWithAssessmentCreatedBySameUserList,
  isEditingProfile,
  showTitle,
  showEmptyCard,
  isEditing,
  showLoading,
  hideLoading,
}) => {
  const { snackbarError } = useSnackbar()
  const validateActiveAssessmentBySameUser = id => {
    if (
      typeof schoolsWithAssessmentCreatedBySameUserList !== 'undefined' &&
      schoolsWithAssessmentCreatedBySameUserList.length > 0
    ) {
      const response = schoolsWithAssessmentCreatedBySameUserList.find(
        validateIsActive => validateIsActive.schoolId === id,
      )
      return response !== undefined ? response.isInAnActiveAssessmentBySameUser : false
    }
    return false
  }
  return (
    <>
      {showTitle && (
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.containerTitle}>
              Escola(s) adicionada(s):
            </Typography>
          </Grid>
        </Grid>
      )}
      {schools && schools.length > 0 ? (
        <Grid container spacing={3} className={classes.container}>
          {schools.map(school => (
            <Grid item xs={6} key={school.id}>
              <SchoolCard
                isEditing={isEditing}
                classes={classes}
                title={`${school.name}`}
                subtitle={[`${school.city}/${school.uf}`, `INEP: ${school.inep}`]}
                handleRemove={
                  remove
                    ? () => {
                        if (validateActiveAssessmentBySameUser(school.id)) {
                          if (isEditingProfile) {
                            snackbarError(
                              `Exclusão Bloqueada!
                        Escola presente em uma avaliação Ativa no perfil deste Gestor!`,
                            )
                          } else {
                            snackbarError(
                              `Exclusão Bloqueada!
                        Escola presente em uma avaliação Ativa no perfil deste Professor!`,
                            )
                          }
                        } else {
                          setTimeout(() => {
                            remove(schools.findIndex(s => s.id === school.id))
                          })
                        }
                      }
                    : null
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        showEmptyCard && (
          <Grid item xs={6}>
            <div className={classes.emptyCard}>
              <Typography variant="body1" className={classes.emptyCardTitle}>
                Você ainda não adicionou uma escola. Que tal começar agora?
              </Typography>
            </div>
          </Grid>
        )
      )}
    </>
  )
}

SchoolCards.propTypes = {
  classes: PropTypes.object.isRequired,
  remove: PropTypes.func,
  schools: PropTypes.array.isRequired,
  showEmptyCard: PropTypes.bool,
  showTitle: PropTypes.bool,
}
SchoolCards.defaultProps = {
  showTitle: true,
  showEmptyCard: true,
  remove: null,
}

export default withStyles(styles)(React.memo(SchoolCards))
