export default function styles() {
  return {
    termBody: {
      paddingTop: '24px',
      width: '100%',
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#3E3E3E',
      opacity: 1,
      whiteSpace: 'pre-line',
    },
    policyTermTitle: {
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#003C7B',
      opacity: 1,
      whiteSpace: 'pre-line',
      width: '100%',
      paddingTop: '24px',
    },
    policyTermSubTitle: {
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#3E3E3E',
      fontWeight: 'bold',
      fontFamily: 'Barlow, Roboto, sans-serif',
      opacity: 1,
      whiteSpace: 'pre-line',
      width: '100%',
      paddingTop: '24px',
      fontSize: '15px',
    },
    policyTermSubBody: {
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#3E3E3E',
      fontWeight: 'normal',
      fontFamily: 'Barlow, Roboto, sans-serif',
      opacity: 1,
      whiteSpace: 'pre-line',
      width: '100%',
      paddingTop: '24px',
      fontSize: '15px',
    },
    policyTermTitleItem: {
      textAlign: 'justify',
      letterSpacing: '0px',
      color: '#3E3E3E',
      fontWeight: 'bold',
      fontFamily: 'Barlow, Roboto, sans-serif',
      opacity: 1,
      whiteSpace: 'pre-line',
      width: '100%',
      paddingTop: '24px',
    },
  }
}
