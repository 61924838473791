import React, { useState } from 'react'

const SidebarContext = React.createContext({
  handleMenuChangeToRoute: () => {},
  setMenuItems1: () => {},
  menuItems1: [],
  setMenuItems2: () => {},
  menuItems2: [],
  setMenuItems3: () => {},
  menuItems3: [],
  setMenuItems4: () => {},
  menuItems4: [],
  history: null,
})

const SidebarProvider = props => {
  const { children, history } = props
  const [menuItems1, setMenuItems1] = useState([])
  const [menuItems2, setMenuItems2] = useState([])
  const [menuItems3, setMenuItems3] = useState([])
  const [menuItems4, setMenuItems4] = useState([])

  const handleMenuChangeToRoute = route => {
    const newMenuItems1 = menuItems1.map(item => ({
      ...item,
      active: item.route === route,
    }))

    const newMenuItems2 = menuItems2.map(item => ({
      ...item,
      active: item.route === route,
    }))

    const newMenuItems3 = menuItems3.map(item => ({
      ...item,
      active: item.route === route,
    }))

    const newMenuItems4 = menuItems4.map(item => ({
      ...item,
      active: item.route === route,
    }))

    setMenuItems1(newMenuItems1)
    setMenuItems2(newMenuItems2)
    setMenuItems3(newMenuItems3)
    setMenuItems4(newMenuItems4)
  }

  const value = {
    handleMenuChangeToRoute,
    setMenuItems1,
    menuItems1,
    setMenuItems2,
    menuItems2,
    setMenuItems3,
    menuItems3,
    setMenuItems4,
    menuItems4,
    history,
  }

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}

const useSidebar = () => {
  return React.useContext(SidebarContext)
}

export { SidebarProvider, useSidebar }
