export default () => ({
  container: {
    opacity: '1',
    width: '100%',
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#003C78',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#003C78',
  },
  buttonsGroup: { display: 'flex', alignItems: 'center', float: 'right' },
  img: {
    maxWidth: '1000px',
    maxHeight: '700px',
    objectFit: 'contain',
    '@media (max-width: 1024px) and (min-width: 279px)': {
      width: '100%',
      height: '100%',
    },
  },
  video: {
    maxWidth: '1000px',
    maxHeight: '700px',
    boxShadow: 'rgba(0, 0, 0, 0.60) 0px 5px 15px',
    borderRadius: '3px',
    objectFit: 'contain',
    '@media (max-width: 1024px) and (min-width: 279px)': {
      width: '100%',
      height: '100%',
    },
  },
  audio: {
    maxWidth: '1000px',
    marginTop: '240px',
    boxShadow: 'rgba(0, 0, 0, 0.60) 0px 5px 15px',
    borderRadius: '27px',
    objectFit: 'contain',
  },
  progress: {
    textAlign: 'center',
  },
  item: {
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: '150x',
    width: '100%',
  },
  icon: {
    color: '#A6A6A6',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  textItem: {
    display: 'flex',
  },
  textItemChildren: {
    textAlign: 'center',
    padding: '4px',
  },
})
