import api from '../services/api'

const checkStudentExistence = codInep => api.get(`examinees?codExaminee=${codInep}`)
export const validateStudent = prevCodInep => async value => {
  if (prevCodInep && prevCodInep === value) return true
  if (value.length === 8) {
    try {
      await checkStudentExistence(value)
      return false
    } catch (error) {
      return true
    }
  }
  return false
}

export const sortStudentsByName = studentArray => {
  if (studentArray.length <= 0) return studentArray
  return studentArray.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
}
