export default function styles() {
  return {
    rootPagination: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      padding: '30px',
    },
    icon: {
      fontSize: '20px',
    },
  }
}
