import { useState } from 'react'

import api from '../../../services/api'
import { useSnackbar } from '../../../shared/hooks'

const useUnlinkProgramsService = () => {
  const { snackbarSuccess, snackbarError } = useSnackbar()
  const [isLoading, setLoading] = useState(false)

  const unlinkProgramsService = classroomId => {
    setLoading(true)

    try {
      const response = api.delete(`program/classroom/${classroomId}`)
      api.delete(`programCurricular/classroom/${classroomId}`)
      snackbarSuccess('Programa(s) desvinculado(s) com sucesso!')

      return response
    } catch (error) {
      snackbarError('Falha ao desvincular o(s) programa(s)')

      return null
    } finally {
      setLoading(false)
    }
  }

  return {
    isLoading,
    unlinkProgramsService,
  }
}

export { useUnlinkProgramsService }
