const PROFILES = {
  TEACHER: {
    id: 1,
    description: 'Professor',
    apiUrl: 'teachers',
    role: 'TEACHER',
  },
  SCHOOL_MANAGER: {
    id: 2,
    description: 'Gestor Escolar',
    apiUrl: 'schoolmanagers',
    role: 'SCHOOL_MANAGER',
  },
  ADMINISTRATOR: {
    id: 5,
    description: 'Administrador',
    apiUrl: 'administrators',
    role: 'ADMINISTRATOR',
  },
  NETWORK_MANAGER: {
    id: 7,
    description: 'Gestor de Rede',
    apiUrl: 'network-managers',
    role: 'NETWORK_MANAGER',
  },
  REGIONAL_COORDINATOR: {
    id: 8,
    description: 'Coordenador da Regional',
    apiUrl: 'regional-coordinators',
    role: 'REGIONAL_COORDINATOR',
  },
  RESEARCHER: {
    id: 9,
    description: 'Pesquisador',
    apiUrl: 'researchers',
    role: 'RESEARCHER',
  },
  EXAMINEE: {
    id: 99,
    description: 'Estudante',
    apiUrl: 'examinees',
    role: 'EXAMINEE',
  },
  PARTNERSHIP_COLLABORATOR: {
    id: 10,
    description: 'Colaborador de Parceria',
    apiUrl: 'partnership-collaborator',
    role: 'PARTNERSHIP_COLLABORATOR',
  },
  EXTERNAL_COORDINATOR: {
    id: 11,
    description: 'Coordenador externo',
    apiUrl: 'external-coordinators',
    role: 'EXTERNAL_COORDINATOR',
  },
  DIRECTOR: {
    id: 13,
    description: 'Diretor',
    apiUrl: 'directors',
    role: 'DIRECTOR',
  },
  LMS: {
    id: 6,
    description: 'Lms',
    apiUrl: 'lms',
    role: 'ROLE_LMS',
  },
  EDUCATOR_TECHNICIAN: {
    id: 12,
    description: 'Técnico de educação',
    apiUrl: 'educator-technicians',
    role: 'EDUCATOR_TECHNICIAN',
  },
  RESPONDER: {
    id: 14,
    description: 'Respondente',
    apiUrl: 'responder',
    role: 'RESPONDER',
  },
}

export default PROFILES
