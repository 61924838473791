export default theme => ({
  dialog: {
    width: '392px',
    margin: '10px',
    textAlign: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '38px',
    textAlign: 'center',
    color: '#003C78',
    marginBottom: theme.spacing(2),
  },
  text: {
    padding: '9.5px 0',
    fontSize: '16px',
    lineHeight: '140%',
    textAlign: 'center',
    color: '#3E3E3E',
    marginTop: theme.spacing(3),
    width: '328px',
    margin: '0 auto',
  },
  buttonContainer: {
    justifyContent: 'center',
    paddingBottom: '24px',
  },
  buttonConfirm: {
    background: '#003C78',
  },
  buttonClose: {
    background: '#003C78',
  },
  img: { marginBottom: '28.94px' },
})
