import { Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import TextFieldForm from './TextFieldForm'

const ResearcherAssessmentTextField = ({
  question,
  questionsWithoutAnswer,
  blockNextButton,
  setFieldValue,
  submitForm,
}) => {
  const questionTextSize = question.answerKey.textSize
  const questionTextType = question.answerKey.textType
  const invalidValueErrorMessage =
    questionTextType === 'LETTERS'
      ? 'Resposta inválida. Esse campo aceita somente letras e números.'
      : 'Resposta inválida. Esse campo aceita somente números'

  const validationSchemaNumbers = Yup.object().shape({
    textValue: Yup.string()
      .required('Preencha o campo com sua resposta')
      .nullable()
      .max(questionTextSize === 'SHORT' ? 300 : 500, 'Passou do máximo!')
      .matches(/^[0-9]+$/, { excludeEmptyString: true, message: invalidValueErrorMessage }),
  })

  const validationSchemaLetters = Yup.object().shape({
    textValue: Yup.string()
      .required('Preencha o campo com sua resposta')
      .nullable()
      .max(questionTextSize === 'SHORT' ? 300 : 500, 'Passou do máximo!'),
  })

  const [textValue, setTextValue] = useState({
    textValue: question.responseSelected?.value ?? null,
  })
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    submitForm(question, { id: question.answerKey.items[0].id, value: textValue.textValue })
  }

  const validateText = useCallback(async () => {
    try {
      if (questionTextType === 'LETTERS') {
        await validationSchemaLetters.validate(textValue)
      } else {
        await validationSchemaNumbers.validate(textValue)
      }

      setError(null)
      if (questionsWithoutAnswer === 0) {
        blockNextButton(false)
      }
    } catch (e) {
      setError(e.message)
      if (questionsWithoutAnswer === 0) {
        blockNextButton(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTextType, textValue, validationSchemaLetters, validationSchemaNumbers])

  useEffect(() => {
    validateText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue])

  const submitText = async () => {
    if (error === null) {
      handleSubmit()
    }
  }

  return (
    <Grid>
      <TextFieldForm
        question={question}
        setFieldValue={setFieldValue}
        value={textValue}
        error={error}
        submitText={submitText}
        setValue={setTextValue}
      />
    </Grid>
  )
}

export default ResearcherAssessmentTextField
