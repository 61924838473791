import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { matchSorter } from 'match-sorter'

const GradeAutocomplete = ({
  options,
  label,
  handleChange,
  handleClose,
  grades,
  arrayHelpers: { push },
  isEditing,
}) => {
  const [selected, setSelected] = useState(null)
  const [currentList, setCurrentList] = useState([])

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }],
    })

  useEffect(() => {
    const getCurrentList = () => {
      return options.filter(grade => !grades.find(selectedGrade => grade.id === selectedGrade.id))
    }

    const listWithOutSelected = getCurrentList()
    setCurrentList(listWithOutSelected)
  }, [grades, options])

  const handleGrades = (event, value) => {
    if (!value && typeof handleClose === 'function') {
      handleClose()
    }

    if ((value && value.id) || (value && value.value === 'select-all')) {
      if (typeof handleChange === 'function') handleChange(value)
    }
  }

  const handleGradeListChange = (event, selectedOption) => {
    if (selectedOption) {
      if (selectedOption.value === 'select-all') {
        currentList.filter(option => {
          if (option.value !== 'select-all') {
            push(option)
          }
          return null
        })
        setSelected(selectedOption)
      } else {
        push(selectedOption)
        setSelected(selectedOption)
      }
    }

    return handleGrades(event, selectedOption)
  }

  return (
    <Autocomplete
      disabled={isEditing && isEditing === true}
      disableCloseOnSelect
      disableListWrap
      options={currentList}
      getOptionLabel={option => {
        if (option) {
          if (option.name) return `${option.name}`
        }
        return null
      }}
      onKeyDown={() => {}}
      filterOptions={(opt, params) => {
        const filtered = filterOptions(opt, params)
        if (options.length > 0 && currentList.length > 0) {
          return [{ name: 'SELECIONAR TODAS', value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={(event, value) => handleGradeListChange(event, value)}
      noOptionsText="Todas as séries já se encontram em uma avaliação em andamento"
      value={selected}
      renderInput={params => <TextField {...params} label={label} variant="outlined" fullWidth />}
    />
  )
}

GradeAutocomplete.propTypes = {
  grades: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
}

GradeAutocomplete.defaultProps = {
  handleChange: () => {},
  handleClose: () => {},
  options: [],
}

export default React.memo(GradeAutocomplete)
