import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useLoading, useSnackbar } from '../../shared/hooks'
import api from '../../services/api'

export const SennaAdultsContext = createContext()

export default function SennaAdultsProvider({ children }) {
  const [step, _setStep] = useState(0)
  const [testingSessionId, _setTestingSessionId] = useState(0)
  const [questions, _setQuestions] = useState([])
  const [hash, _setHash] = useState('')
  const [loading, _setLoading] = useState(true)
  const [status, _setStatus] = useState('')
  const [firstAccess, _setFirstAccess] = useState(true)

  const { showLoading, hideLoading } = useLoading()
  const { showRequestErrorDetail, snackbarSuccess } = useSnackbar()
  const history = useHistory()

  const setLoading = value => {
    _setLoading(value)
  }

  const setStep = value => {
    _setStep(value)
  }

  const setTestingSessionId = value => {
    _setTestingSessionId(value)
  }

  const setQuestions = value => {
    _setQuestions(value)
  }

  const setStatus = value => {
    _setStatus(value)
  }

  const setHash = value => {
    _setHash(value)
  }

  const setFirstAccess = value => {
    _setFirstAccess(value)
  }

  const onLoad = async assessmentId => {
    showLoading()
    setLoading(true)
    const requestParam = { assessmentId }
    await api
      .get('senna-adults-testing-session', { params: requestParam })
      .then(response => {
        const { data } = response
        setTestingSessionId(data.id)
        setStep(data.step)
        setStatus(data.status)
        setHash(data.hash)
      })
      .catch(error => {
        showRequestErrorDetail(error)
        history.push('/aplicacoes-respondente-sa')
      })
      .finally(() => {
        setLoading(false)
        hideLoading()
      })
  }

  const fetchQuestions = async (_testingSessionId, _step) => {
    showLoading()
    setLoading(true)
    const requestParam = { testingSessionId: _testingSessionId, step: _step }
    await api
      .get('senna-adults-questions', { params: requestParam })
      .then(response => {
        const { data } = response
        setQuestions(data)
      })
      .catch(error => {
        showRequestErrorDetail(error)
        history.push('/aplicacoes-respondente-sa')
      })
      .finally(() => {
        setLoading(false)
        hideLoading()
      })
  }

  const sendAnswers = async request => {
    const requestParam = {
      assessmentId: request.assessmentId,
      testingSessionId: request.testingSessionId,
      questions: request.questionsDTO,
      step: request.step,
    }
    setLoading(true)
    await api
      .post('/senna-adults-answer/register', requestParam)
      .then()
      .catch(error => {
        showRequestErrorDetail(error)
      })
      .finally(() => {
        setLoading(false)
        hideLoading()
      })
  }

  const updateStep = async (_testingSessionId, assessmentId) => {
    const requestParam = {
      id: _testingSessionId,
      assessmentId,
    }
    setLoading(true)
    await api
      .put('/senna-adults-testing-session/update-step', requestParam)
      .then()
      .catch(error => {
        showRequestErrorDetail(error)
      })
      .finally(() => {
        setLoading(false)
        hideLoading()
      })
  }

  const finish = async (_testingSessionId, assessmentId) => {
    const requestParam = {
      id: _testingSessionId,
      assessmentId,
    }
    setLoading(true)
    await api
      .put('/senna-adults-testing-session/finish', requestParam)
      .then(() => {
        snackbarSuccess('Aplicação finalizada com sucesso!')
      })
      .catch(error => {
        showRequestErrorDetail(error)
      })
      .finally(() => {
        setLoading(false)
        hideLoading()
      })
  }

  const reset = () => {
    setStep(0)
    setTestingSessionId(0)
    setStatus('')
    setQuestions([])
    setLoading(true)
    setHash('')
  }

  return (
    <SennaAdultsContext.Provider
      value={{
        step,
        setStep,
        testingSessionId,
        setTestingSessionId,
        onLoad,
        questions,
        setQuestions,
        fetchQuestions,
        setLoading,
        loading,
        sendAnswers,
        updateStep,
        finish,
        reset,
        status,
        hash,
        firstAccess,
        setFirstAccess,
      }}
    >
      {children}
    </SennaAdultsContext.Provider>
  )
}

SennaAdultsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
