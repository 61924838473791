import React from 'react'
import {
  Slide,
  Button,
  Dialog,
  withStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { ReportProblemOutlined } from '@material-ui/icons'

import styles from './style'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const WarningAlert = ({ open, classes, setOpen, title, description, handleFunction }) => (
  <Dialog
    open={open}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    TransitionComponent={Transition}
  >
    <div className={classes.box}>
      <ReportProblemOutlined className={classes.icon} />

      <DialogTitle id="alert-dialog-slide-title" className={classes.titleBox}>
        {title}
      </DialogTitle>

      <DialogContent className={classes.contentBox}>
        <DialogContentText id="alert-dialog-slide-description">
          <span>{description}</span>
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.buttonsActions}>
        <Button
          className={classes.button}
          onClick={() => {
            handleFunction()
            setOpen(false)
          }}
        >
          Sim
        </Button>

        <Button className={classes.button} variant="outlined" onClick={() => setOpen(false)}>
          Não
        </Button>
      </DialogActions>
    </div>
  </Dialog>
)

export default withStyles(styles)(WarningAlert)
