import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

const FormInput = ({ form: { handleChange, handleBlur }, ...props }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{ autoComplete: 'off' }}
      {...props}
    />
  )
}

FormInput.propTypes = {
  form: PropTypes.object.isRequired,
}

export default FormInput
