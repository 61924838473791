import { useEffect, useState } from 'react'

import { PROFILES } from '../../../shared'
import { useSnackbar } from '../../../shared/hooks'
import { getData } from '../../../utils/fetchData/fetchData'

import { pageSizeOptions } from '../constants'

const useGetClassrooms = () => {
  const [data, setData] = useState({ content: [] })
  const [filterForm, setFilterForm] = useState({
    page: 0,
    size: pageSizeOptions[0],
    schoolId: null,
    classroomId: null,
    teachingNetworkId: null,
    status: null,
    gradeId: null,
    sort: null,
  })
  const [isLoading, setLoading] = useState(false)

  const { snackbarError } = useSnackbar()

  const getClassroomsByFilters = async () => {
    const sort = filterForm.sort ? Object.values(filterForm.sort).join(',') : undefined

    const params = {
      profileId: PROFILES.NETWORK_MANAGER.id,
      page: filterForm.page,
      size: filterForm.size,
      schoolId: filterForm.schoolId || undefined,
      classroomId: filterForm.classroomId || undefined,
      teachingNetworkId: filterForm.teachingNetworkId || undefined,
      status: filterForm.status,
      gradeId: filterForm.gradeId || undefined,
      sort,
    }

    const requestParams = {
      url: 'program/classroom',
      options: { params },
      snackbarError,
      setLoading,
    }

    const responseData = await getData(requestParams)
    const classroomsList = responseData.content.map(classroom => {
      const hasLinkedPrograms = Array.isArray(classroom.programs)
      const linkedPrograms = hasLinkedPrograms
        ? classroom.programs.map(program => ({
            id: Number(program.id),
            name: String(program.name),
          }))
        : []

      return {
        id: classroom.id,
        schoolName: classroom.school.name,
        classroomName: classroom.name,
        gradeName: classroom.grade.name,
        gradeOrder: classroom.grade.ordem,
        schoolShiftName: classroom.schoolShift.name,
        isProgramLinked: classroom.programLinked,
        linkedPrograms,
        haveAnswers: classroom.haveAnswers,
      }
    })

    setData({ ...responseData, content: classroomsList })
  }

  useEffect(() => {
    getClassroomsByFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterForm])

  return {
    isLoading,
    data,
    setFilterForm,
    filterForm,
  }
}

export { useGetClassrooms }
