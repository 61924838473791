const DefaultBreadcrumb = () => ({
  list: {
    position: 'relative',
    zIndex: 10,
    fontSize: '13px',
  },
  itemLink: {
    color: '#00A8E9',
  },
  itemText: {
    color: '#A6A6A6',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

const MakingChoicesBreadcrumb = () => ({
  list: {
    position: 'relative',
    zIndex: 10,
    fontSize: '14px',
    fontFamily: 'Barlow',
    fontWeight: '700',
    lineHeight: '150%',
    fontStyle: 'normal',
  },
  itemLink: {
    color: '#CBD2E0',
  },
  itemText: {
    color: '#003C78',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

export { DefaultBreadcrumb, MakingChoicesBreadcrumb }
