import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask'
import api from '../../services/api'
import { HttpStatus } from '../../utils/HttpStatus'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    right: '1.25%',
    top: '25%',
  },
}))

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const checkCpfExists = async (form, field) => {
  const { values } = form
  const { name } = field

  const formattedCpf = values[name]
    .split('.')
    .join('')
    .split('-')
    .join('')

  try {
    const response = await api.get('user', { params: { cpf: formattedCpf } })
    if (response.data) form.setFieldError(name, 'CPF já cadastrado')
  } catch (error) {
    // eslint-disable-next-line no-console
    if (error.response.status !== HttpStatus.NOT_FOUND) console.log(error.response.data.message)
  }
}

const CpfInput = ({ form, field, validateCpf, isEditing, ...props }) => {
  const { touched, errors } = form
  const { onBlur, onChange, name, value } = field
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <TextField
        {...props}
        onBlur={event => {
          if (validateCpf) checkCpfExists(form, field)
          onBlur(event)
        }}
        onChange={onChange}
        name={name}
        id={name}
        value={value}
        label="CPF"
        fullWidth
        variant="outlined"
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        helperText={touched[name] ? errors[name] : null}
        error={touched[name] && Boolean(errors[name])}
        disabled={isEditing}
      />
      {/* <Tooltip
        title="Informar seu CPF é necessário para que não haja duplicidade nos cadastros."
        className={classes.tooltip}
      >
        {isEditing && props.regionalCoordinator ? (
          <InfoOutlinedIcon color="disabled" />
        ) : (
          <InfoRounded color="disabled" />
        )}
      </Tooltip> */}
    </div>
  )
}

CpfInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  validateCpf: PropTypes.bool,
}

CpfInput.defaultProps = {
  validateCpf: false,
}

export default CpfInput
