import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import LogoIas from '../../shared/imgs/logo.svg'

const styles = {
  container: {
    width: '100%',
    marginTop: 30,
    overflow: 'hidden',
  },
  modal: {
    maxWidth: 900,
    backgroundColor: '#fff',
    height: 700,
    top: 30,
    margin: '0 auto',
    borderRadius: 20,
  },
  iframe: {
    width: '100%',
    border: 'none',
    height: '70%',
    backgroundColor: '#fff',
  },
  image: { height: '130px', paddingTop: '35px', margin: '0px auto', display: 'block' },
}
const ReportModal = ({ open, setOpen }) => {
  return (
    <>
      <Modal style={styles.container} open={open} onClose={() => setOpen(false)}>
        <div style={styles.modal}>
          <img src={LogoIas} alt="alt" style={styles.image} />
          <iframe
            style={styles.iframe}
            title="tomchat"
            src="https://institutoayrtonsenna5795.zendesk.com/hc/pt-br/requests/new"
          />
        </div>
      </Modal>
    </>
  )
}

ReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default ReportModal
