export default function styles() {
  return {
    textFont: {
      fontFamily: 'Barlow,Roboto,sans-serif',
    },
    colorText: {
      color: '#003C78',
    },

    title: {
      marginTop: '10px',
      fontFamily: 'Barlow,Roboto,sans-serif',
      fontWeight: '1000',
      fontSize: '24px',
      lineHeight: '50px',
      textAlign: 'left',
      color: '#003C78',
      marginBottom: '10px',
    },
    media: {
      display: 'table',
      marginRight: '0px',
      width: '100%',
      textAlign: 'left',
      alignContent: 'left',
      marginBottom: '20px',
    },
    text: {
      textAlign: 'left',
      fontFamily: 'Barlow,Roboto,sans-serif',
      fontSize: '20x',
      color: '#003c78',
      // lineHeight: '27px',
      width: '100%',
      marginBottom: '10px',
    },
    textSmall: {
      textAlign: 'left',
      fontFamily: 'Barlow,Roboto,sans-serif',
      fontSize: '16px',
      color: '#003c78',
      // lineHeight: '23px',
      width: '100%',
      marginBottom: '20px',
    },
    button: {
      background: '#ffca00!important',
      color: '#003c78',
      fontSize: '16px',
      marginTop: '20px',
      fontWeight: '700',
    },
    cardUser: {
      marginTop: '15px',
      marginBottom: '100px',
    },
  }
}
