import {
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  List,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useState, useContext } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { ListItemButton, ListItemIcon } from '@mui/material'
import styles from './MenuMobile.style'
import history from '../../services/history'
import UserService from '../../services/UserService'
import home from './img/home.svg'
import closeMenu from './img/closeMenu.svg'
import aplication from './img/aplication.svg'
import relatorio from './img/relatorio.svg'
import { AuthContext } from '../../contexts'

const useStyles = makeStyles(styles)

const MenuMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const classes = useStyles()
  const { currentUser } = useContext(AuthContext)

  const roles = currentUser?.user?.profiles[0]?.role

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  const handleExit = useCallback(() => {
    UserService.forceLogout()
  }, [])

  const handleHome = useCallback(() => {
    history.push('/')
  }, [])

  const handleReportLibrary = useCallback(() => {
    history.push('/biblioteca-relatorios')
  }, [])

  const permissions = () => {
    switch (roles) {
      case 'ROLE_PARTNERSHIP_COLLABORATOR':
        return history.push('/aplicacoes-respondente')
      case 'ROLE_RESEARCHER':
        return history.push('/aplicacoes-pesquisador')
      case 'ROLE_EXAMINEE':
        return history.push('/aplicacoes-estudante')
      case 'ROLE_RESPONDER':
        return history.push('/aplicacoes-respondente-sa')
      default:
        return history.push('/aplicacoes')
    }
  }

  const GenericMenuListItem = ({ icon, label, handleAction }) => {
    return (
      <ListItem
        onClick={() => {
          handleMenuClose()
          handleAction()
        }}
        style={{ padding: '0px 20px 5px 5px' }}
      >
        <ListItemButton>
          <ListItemIcon style={{ minWidth: 30 }}>
            <img alt="Logo Senna" src={icon} />
          </ListItemIcon>
          <ListItemText
            primary={label}
            className={classes.genericListItemContent}
            disableTypography
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return (
    <>
      <IconButton onClick={handleHamburgerClick} edge="start" color="secondary" aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isMenuOpen} onClose={handleMenuClose}>
        <List className={classes.container}>
          <GenericMenuListItem icon={home} label="Início" handleAction={handleHome} />
          <GenericMenuListItem icon={aplication} label="Avaliação" handleAction={permissions} />
          <GenericMenuListItem
            icon={relatorio}
            label="Biblioteca de relatórios"
            handleAction={handleReportLibrary}
          />
          <GenericMenuListItem
            icon={closeMenu}
            label="Fechar menu"
            handleAction={handleMenuClose}
          />
        </List>
        <Grid className={classes.footerContainer}>
          <Typography onClick={handleExit} className={classes.footerContent}>
            Sair da plataforma
          </Typography>
        </Grid>
      </Drawer>
    </>
  )
}

export default MenuMobile
