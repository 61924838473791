export default () => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,

    '&:first-child': {
      paddingTop: 1,
    },

    '& .MuiListItemIcon-root': {
      minWidth: '20px',
      marginRight: '11px',
    },

    '&:hover': {
      background: '#00a8e9 !important',
    },
  },

  listItemActive: {
    background: '#00a8e9',

    '&:hover': {
      background: '#00a8e9 !important',
    },
  },

  listSubItem: {
    padding: '0 0 0 44px',
    background: '#b1e9ff',

    '&:hover': {
      background: '#00a8e9 !important',
    },
  },

  listItemCollapsed: {
    '& .MuiListItemIcon-root': {
      marginRight: 0,
    },
  },

  listItemText: {
    // textTransform: 'uppercase',
    '& > span': {
      fontWeight: 600,
    },
  },

  listSubItemText: {
    '& > span': {
      fontSize: '14px',
    },
  },

  listSubItemTextActive: {
    '& > span': {
      textDecoration: 'underline',
    },
  },

  division: {
    width: '100%',
    // borderBottom: '1px solid #fff',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& > .MuiListItemText-root': {
      color: '#003C78',
    },

    '& svg': {
      color: '#003C78',
    },
  },

  divisionActive: {
    '& > .MuiListItemText-root': {
      color: '#0b4680',
    },

    '& svg': {
      color: '#0b4680',
    },
  },

  item: {
    margin: 0,
    padding: '17px 0',
  },

  iconButton: { padding: '0px' },
})
