import React, { useCallback } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import UserService from '../services/UserService'

const RouteWrapper = ({ component, isPrivate, ...rest }) => {
  const isLocationContaisIdpHint = () => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams && searchParams.has('idpHint') && searchParams.get('idpHint') === 'lex'
  }

  const login = useCallback(async () => {
    if (isLocationContaisIdpHint()) {
      await UserService.doLoginLex()
    } else {
      await UserService.doLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!UserService.isLoggedIn() && isPrivate) {
    login()
  }

  return <Route {...rest} component={component} />
}

RouteWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper
