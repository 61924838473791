import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  itemsFilter: {
    descriptionItem: '',
    notebookTool: [],
  },
  keyFilter: {
    keyName: '',
  },
}

export const FilterItemKeyContext = createContext({ ...initialState })

export default function FilterContextProvider({ children }) {
  const [filters, setFiltersState] = useState(initialState)

  function resetFilterState() {
    setFiltersState(initialState)
  }

  return (
    <FilterItemKeyContext.Provider value={{ filters, setFiltersState, resetFilterState }}>
      {children}
    </FilterItemKeyContext.Provider>
  )
}

FilterContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
