export default function styles(theme) {
  return {
    searchInput: {
      width: '100%',
    },
    input: {
      height: '20px',
    },
    addButton: {
      marginBottom: theme.spacing(4),
    },
    addIcon: {
      margin: '0 auto',
    },
    studentCard: {
      marginTop: theme.spacing(2),
    },
    studentNameStarter: {
      fontSize: '18px',
      fontStyle: 'normal',
      lineHeight: '22px',
      fontWeight: '500',
      color: '#3E3E3E',
    },
    studentName: {
      fontSize: '18px',
      fontStyle: 'normal',
      lineHeight: '22px',
      fontWeight: '500',
      color: '#01a8e8',
    },
    studentInep: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17px',
      color: '#A6A6A6',
      marginTop: theme.spacing(0.5),
    },
    studentSchool: {
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '17px',
      fontWeight: '500',
      color: '#A6A6A6',
      marginTop: theme.spacing(0.5),
    },
    btnContainer: {
      position: 'absolute',
      left: '0',
      bottom: '0',
      width: '100%',
      padding: theme.spacing(2),
    },
    btnSubmit: {
      margin: `${theme.spacing(1)}px 0`,
      color: '#fff',
      fontWeight: 600,
      backgroundColor: theme.palette.secondary[900],
    },
  }
}
