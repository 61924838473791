import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'

const SelectIAS = ({
  error,
  callback,
  helperText,
  form: { handleChange, handleBlur, values },
  label,
  id,
  items,
}) => {
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <FormControl variant="outlined" error={error} style={{ width: '100%' }}>
      <InputLabel ref={inputLabel} htmlFor="outlined-select">
        {label}
      </InputLabel>
      <Select
        onChange={e => {
          handleChange(e)
          if (typeof callback === 'function') callback(e.target.value)
        }}
        onBlur={handleBlur}
        labelWidth={labelWidth}
        inputProps={{ name: id }}
        value={values[id] || ''}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem disabled selected>
          Selecione
        </MenuItem>
        {items.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

SelectIAS.propTypes = {
  callback: PropTypes.func,
  error: PropTypes.bool,
  form: PropTypes.object.isRequired,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
}

SelectIAS.defaultProps = {
  callback: undefined,
  error: false,
  helperText: '',
}

export default SelectIAS
