/* eslint-disable max-len */
import Keycloak from 'keycloak-js'
import { removeSelectedProfile } from '../utils/user/profileHelper'

const kcInitOptionsLexIdp = {
  idpHint: 'lex',
}

const _kc = new Keycloak({
  realm: 'ias',
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: 'bncc',
})

const clearLocalStorage = () => {
  localStorage.removeItem('regionalIds')
  localStorage.removeItem('currentUser')
  localStorage.removeItem('import-selected')
  localStorage.removeItem('showedInstructionInstrumentId')
  removeSelectedProfile()
}

const doLogin = () => {
  clearLocalStorage()
  _kc.login()
}

const doLoginLex = () => {
  _kc.login(kcInitOptionsLexIdp)
}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = onAuthenticatedCallback => {
  _kc
    .init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
    })
    .then(() => {
      onAuthenticatedCallback()
    })
}

const doLogout = () => {
  clearLocalStorage()
  _kc.logout()
}

const getToken = () => {
  return new Promise(resolve => {
    if (_kc.isTokenExpired()) {
      _kc
        .updateToken()
        .then(function(refreshed) {
          if (refreshed) {
            resolve(_kc.token)
          }
        })
        .catch(() => {
          doLogout()
        })
    } else {
      resolve(_kc.token)
    }
  })
}

const getRefreshToken = () => _kc.refreshToken

const isLoggedIn = () => !!_kc.authenticated

const getUsername = () => _kc.tokenParsed?.preferred_username

const getUserId = () => _kc.tokenParsed?.sub

const getName = () => _kc.tokenParsed?.name

const getEmail = () => _kc.tokenParsed?.email

const getPartnershipCollaboratorName = () => {
  const name = _kc.tokenParsed?.name
  return name ?? 'Respondente'
}

const getProfiles = () =>
  _kc.realmAccess?.roles
    .filter(role => role.startsWith('ROLE_') && role !== 'ROLE_LMS')
    .map(profile => profile.replace('ROLE_', ''))

const hasRole = roles => roles.some(role => _kc.hasRealmRole(`ROLE_${role}`))

const forceLogout = () => {
  doLogout()
}

const UserService = {
  initKeycloak,
  doLogin,
  doLoginLex,
  doLogout,
  isLoggedIn,
  getToken,
  getUsername,
  getName,
  hasRole,
  getProfiles,
  getEmail,
  forceLogout,
  getRefreshToken,
  getUserId,
  getPartnershipCollaboratorName,
}

export default UserService
