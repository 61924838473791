import { useState } from 'react'

import { useSnackbar } from '../../../shared/hooks'
import { postData } from '../../../utils/fetchData/fetchData'

const useLinkProgramsService = () => {
  const { snackbarSuccess, snackbarError } = useSnackbar()
  const { isLoading, setLoading } = useState(false)

  const linkProgramsService = classroomsToLink => {
    // Avoid request without program IDs.
    if (classroomsToLink[0].programIds.length === 0) {
      return null
    }

    return postData({
      url: 'program/classroom',
      data: classroomsToLink,
      snackbarSuccess: () => snackbarSuccess('Programa(s) vinculado(s) com sucesso!'),
      snackbarError: details => snackbarError(details),
      setIsLoading: setLoading,
    })
  }

  return {
    isLoading,
    linkProgramsService,
  }
}

export { useLinkProgramsService }
