import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InfoRounded from '@material-ui/icons/InfoRounded'
import MaskedInput from 'react-text-mask'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    right: '1.25%',
    top: '25%',
  },
}))

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const PhoneInput = ({ form, field, labelName, ...props }) => {
  const { touched, errors } = form
  const { onBlur, onChange, name } = field
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <TextField
        {...props}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        id={name}
        label={labelName}
        fullWidth
        variant="outlined"
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        helperText={touched[name] ? errors[name] : null}
        error={touched[name] && Boolean(errors[name])}
      />
      <Tooltip title="Informe o telefone com DDD" className={classes.tooltip}>
        <InfoRounded color="disabled" />
      </Tooltip>
    </div>
  )
}

PhoneInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default PhoneInput
