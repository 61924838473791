export function saveSelectedProfile(profile) {
  localStorage.setItem('selectedProfile', JSON.stringify(profile))
}

export function getSelectedProfile() {
  const profile = localStorage.getItem('selectedProfile')
  if (profile !== null) {
    return JSON.parse(profile)
  }
  return null
}
export function removeSelectedProfile() {
  localStorage.removeItem('selectedProfile')
}
