import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import Delete from '@material-ui/icons/DeleteOutlineSharp'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F3F3F3',
    border: '1px solid #DCDCDC',
    padding: theme.spacing(1),
    borderRadius: theme.typography.pxToRem(4),
  },
  carTitle: {
    width: '100%',
    margin: '0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: theme.typography.pxToRem(18),
    color: '#3E3E3E',
  },
  cardIcon: {
    textAlign: 'center',
  },
  stateCityName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 200,
  },
}))

const SchoolTable = ({ arrayHelpers: { remove }, rows }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      {rows.map((row, index) => (
        <Grid item xs={12} md={6} key={row.id}>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Typography className={classes.carTitle}>{row.name}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.cardIcon}>
                <Delete
                  onClick={() => {
                    remove(index)
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.stateCityName}>
                {row.city_.name}/{row.city_.state.uf}
              </Typography>
            </Grid>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

SchoolTable.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
}

export default SchoolTable
