import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { matchSorter } from 'match-sorter'
import { Autocomplete } from '@material-ui/lab'

const RegionalAutocomplete = ({
  options,
  label,
  handleChange,
  handleClose,
  arrayHelpers: { push, unshift },
  regionals,
}) => {
  const [selected, setSelected] = useState(null)
  const [currentList, setCurrentList] = useState([])

  useEffect(() => {
    const getCurrentList = () => {
      return options
        .filter(
          regional => !regionals.find(selectedRegional => regional.id === selectedRegional.id),
        )
        .sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
    }

    const listWithOutSelected = getCurrentList()
    setCurrentList(listWithOutSelected)
  }, [options, regionals])

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }],
    })

  const handleRegional = value => {
    if (!value && typeof handleClose === 'function') handleClose()

    setSelected(value)

    if (value && value.id) {
      const selectedRegionals = regionals.filter(regional => regional.id === value.id)

      if (selectedRegionals.length === 0) unshift(value)
      if (typeof handleChange === 'function') handleChange(value)
    }
  }

  const handleRegionalChange = (event, selectedOption) => {
    if (selectedOption && selectedOption.value === 'select-all') {
      currentList.filter(option => {
        if (option.value !== 'select-all') {
          push(option)
        }
        return null
      })
      setSelected(selectedOption)
    }

    return handleRegional(selectedOption)
  }

  return (
    <Autocomplete
      disableCloseOnSelect
      disableListWrap
      options={currentList}
      getOptionLabel={option => {
        if (option.name) {
          return `${option.name}`
        }
        return null
      }}
      onKeyDown={() => {}}
      filterOptions={(opt, params) => {
        const filtered = filterOptions(opt, params)
        if (options.length > 0 && currentList.length > 0) {
          return [{ name: 'SELECIONAR TODAS', value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={(event, value) => handleRegionalChange(event, value)}
      noOptionsText="Nenhuma opção disponível"
      value={selected}
      renderInput={params => <TextField {...params} label={label} variant="outlined" fullWidth />}
    />
  )
}

RegionalAutocomplete.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  regionals: PropTypes.array.isRequired,
}

RegionalAutocomplete.defaultProps = {
  handleChange: () => {},
  handleClose: () => {},
  handleReset: () => {},
  options: [],
}

export default React.memo(RegionalAutocomplete)
