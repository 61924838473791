import React from 'react'
import { withStyles, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'

import styles from './style'

const Menu = ({
  icon: Icon,
  quizIcon,
  label,
  route,
  active,
  classes,
  history,
  collapsed,
  subMenuItems,
  handleClick = () => {},
}) => {
  const isRenderSubMenuItems = active && !collapsed && Array.isArray(subMenuItems)

  return (
    <>
      <ListItem
        component="button"
        className={`${classes.listItem} ${collapsed && classes.listItemCollapsed} ${active &&
          classes.listItemActive}`}
        onClick={() => {
          history.push(`/${route}`)
          handleClick(route)
        }}
      >
        <div className={`${classes.division} ${active && classes.divisionActive}`}>
          <ListItemIcon className={classes.item}>
            {quizIcon ? (
              <IconButton
                key={`${Math.random()}-${quizIcon}`}
                className={classes.iconButton}
                color="primary"
              >
                {quizIcon || <quizIcon />}
              </IconButton>
            ) : (
              <Icon />
            )}
          </ListItemIcon>
          {!collapsed && (
            <ListItemText className={classes.listItemText} variant="subtitle">
              {label}
            </ListItemText>
          )}
        </div>
      </ListItem>
      {isRenderSubMenuItems &&
        subMenuItems.map(
          subMenuItem =>
            subMenuItem.role && (
              <ListItem
                key={subMenuItem.route}
                component="button"
                className={`${classes.listItem} ${classes.listSubItem}`}
                onClick={() => {
                  history.push(`/${subMenuItem.route}`)
                  handleClick(subMenuItem.route)
                }}
              >
                <div className={`${classes.division} `}>
                  <ListItemIcon
                    className={classes.item}
                    style={{ opacity: subMenuItem.active ? 1 : 0 }}
                  >
                    <ArrowRight />
                  </ListItemIcon>

                  <ListItemText
                    className={`${classes.listItemText} ${
                      classes.listSubItemText
                    } ${subMenuItem.active && classes.listSubItemTextActive}`}
                    variant="subtitle"
                  >
                    {subMenuItem.label}
                  </ListItemText>
                </div>
              </ListItem>
            ),
        )}
    </>
  )
}

export default withStyles(styles)(Menu)
