import PROFILES from './profiles'

const ROLES = Object.freeze({
  ALL: [
    PROFILES.ADMINISTRATOR.id,
    PROFILES.TEACHER.id,
    PROFILES.SCHOOL_MANAGER.id,
    PROFILES.NETWORK_MANAGER.id,
    PROFILES.REGIONAL_COORDINATOR.id,
    PROFILES.RESPONDER.id,
  ],
  EDUCATOR_INSTRUMENT: [
    PROFILES.TEACHER.id,
    PROFILES.SCHOOL_MANAGER.id,
    PROFILES.NETWORK_MANAGER.id,
    PROFILES.REGIONAL_COORDINATOR.id,
    PROFILES.EXTERNAL_COORDINATOR.id,
    PROFILES.DIRECTOR.id,
    PROFILES.EDUCATOR_TECHNICIAN.id,
  ],
  PROFILE: [PROFILES.TEACHER.id, PROFILES.SCHOOL_MANAGER.id],
  TEACHER: [PROFILES.TEACHER.id, PROFILES.SCHOOL_MANAGER.id, PROFILES.NETWORK_MANAGER.id],
  SCHOOL_MANAGER: [PROFILES.SCHOOL_MANAGER.id, PROFILES.NETWORK_MANAGER.id],
  ADMIN: [PROFILES.ADMINISTRATOR.id],
  NETWORK_MANAGER: [PROFILES.NETWORK_MANAGER.id],
  REGIONAL_COORDINATOR: [PROFILES.REGIONAL_COORDINATOR.id],
  RESEARCHER: [PROFILES.RESEARCHER.id],
  REPORTS_ACCESS: [PROFILES.REGIONAL_COORDINATOR.id, PROFILES.NETWORK_MANAGER.id],
  EXAMINEE: [PROFILES.EXAMINEE.id],
  PARTNERSHIP_COLLABORATOR: [PROFILES.PARTNERSHIP_COLLABORATOR.id],
  EXTERNAL_COORDINATOR: [PROFILES.EXTERNAL_COORDINATOR.id],
  DIRECTOR: [PROFILES.DIRECTOR.id],
  EDUCATOR_TECHNICIAN: [PROFILES.EDUCATOR_TECHNICIAN.id],
  RESPONDER: [PROFILES.RESPONDER.id],
})

export default ROLES
