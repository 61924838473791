import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, makeStyles } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'

const useStyles = makeStyles({
  button: {
    backgroundColor: '#FFCA00',
    '&:hover': {
      backgroundColor: '#FFCA00',
    },
  },
  text: {
    color: '#003C7B',
    fontFamily: 'Barlow',
    fontWeight: 1000,
    fontSize: 16,
    textShadow: '0 0',
    textWrap: 'nowrap',
  },
})

const CustomButton = ({ text, onClick, icon, ...params }) => {
  const classes = useStyles()
  return (
    <Button {...params} className={classes.button} size="large" startIcon={icon} onClick={onClick}>
      <Typography className={classes.text}>{text}</Typography>
    </Button>
  )
}

CustomButton.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

CustomButton.defaultProps = {
  icon: <AddIcon htmlColor="#003C7B" />,
}

export default CustomButton
