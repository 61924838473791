import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Modal from '@material-ui/core/Modal'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#ffffff',
    maxHeight: '90vh',
    padding: '20px',
    overflow: 'auto',
  },
}))

const Terms = ({ modalTitle, isOpen }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(isOpen)

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className={classes.modal}>
        <Typography variant="h5">{modalTitle}</Typography>
        <Typography>
          `LICENÇA DE USO DO INSTRUMENTO DE AVALIAÇÃO SOCIOEMOCIONAL DE TITULARIDADE DO INSTITUTO
          AYRTON SENNA (“INSTRUMENTO SENNA”) Considerando que o Usuário já tomou conhecimento e
          aceitou os termos e condições dos Termos de Uso e da Política de Privacidade da plataforma
          digital provisoriamente denominada “Plataforma Senna”, administrada pelo Instituto Ayrton
          Senna (“IAS”), são apresentadas as seguintes condições aplicáveis à utilização do
          Instrumento Senna pelo Usuário: 1. Para fins de entendimento e interpretação desta
          Licença, fica estabelecido que: (a) Instituto é o Instituto Ayrton Senna, titular do
          Instrumento Senna e administrador da plataforma digital por meio da qual o Instrumento
          Senna é disponibilizado; (b) Instrumento Senna é o instrumento de avaliação
          socioemocional, de titularidade do Instituto, oferecido por meio da plataforma digital
          administrada pelo Instituto; (c) Licença é o presente documento, que estabelece as
          condições e termos, bem como as regras para a utilização do Instrumento Senna pelo
          Usuário; e (d) Usuário é toda pessoa natural para qual tenha sido disponibilizada login e
          senha de acesso à plataforma digital, e destinatária da presente autorização de uso do
          Instrumento Senna. 2. De plano, o Usuário reconhece e declara que: a) foi regularmente
          credenciado, pelo Instituto ou por terceiro por ele indicado, para a aplicação do
          Instrumento Senna, por meio da participação e conclusão da correspondente atividade de
          formação online ou presencial referente à ferramenta; b) atua em instituição de ensino, na
          rede pública ou privada, cadastrada na Plataforma; e c) utilizará o Instrumento Senna de
          acordo com as regras desta Licença para aplicação nas turmas/classes/grupos de escola sob
          sua responsabilidade, e tão somente para fins pedagógicos e de política educacional. 3.
          Assim, condicionado ao atendimento às condições e aos termos desta Licença, o Instituto
          licencia o uso do Instrumento Senna ao Usuário, de forma gratuita e não exclusiva, única e
          exclusivamente para fins de utilização na instituição de ensino na qual o Usuário atua
          como educador, pelo prazo necessário para a coleta e análise de dados e resultados, em
          respeito aos objetivos do credenciamento realizado pelo Usuário para aplicação do
          Instrumento Senna. 4. Por conta da presente Licença, o Usuário assume, ainda, em seu
          próprio nome, as seguintes responsabilidades, perante o Instituto: a) Utilizar-se do
          Instrumento Senna única e exclusivamente no limite desta Licença; b) Assumir sob sua
          integral e exclusiva responsabilidade, todos os custos e demais ônus decorrentes do
          desenvolvimento, implementação, execução e resultado da aplicação do Instrumento Senna; c)
          Cumprir com todos os procedimentos éticos e legais pertinentes à utilização do Instrumento
          Senna, incluindo mas não se limitando, à obtenção do devido consentimento de todos os
          participantes investigados na aplicação do Instrumento Senna através da formalização de
          autorizações por estes sujeitos e/ou seus representantes legais, manifestando a sua
          anuência à participação na aplicação do Instrumento Senna, autorização esta que deverá
          conter, no mínimo, a indicação do conteúdo a ser coletado, o objetivo da aplicação e a
          voluntariedade da participação do indivíduo respondente, observando as regras e formatos
          regulados pelos órgãos pertinentes; d) Fazer constar no documento que será utilizado pelo
          Usuário para a obtenção por escrito do consentimento do titular dos dados pessoais
          utilizados, submetidos ao Instrumento Senna, que os dados coletados serão compartilhados
          com o Instituto para fins de armazenamento em sua base de dados e tratamento para fins de
          consecução de resultados e análises relacionadas aos seus objetivos sociais; e) Transferir
          e/ou inserir na plataforma digital do Instituto, sem qualquer limitação, a base de dados
          oriunda da aplicação/utilização do Instrumento Senna, compromentendo-se, ainda, a não
          realizar nenhum tratamento e/ou processamento desses dados coletados, de modo que
          reconhece que toda e qualquer análise de resultados será efetivada por meio da plataforma
          digital do Instituto; f) Defender e isentar o Instituto de todas e quaisquer reclamações
          e/ou eventuais pleitos relacionados à coleta e tratamento de todos os dados pessoais
          repassados pelo Usuário ao Instituto; g) Não obstante o disposto no item ‘e’ acima,
          guardar, sob sua integral responsabilidade e ônus, assegurando o sigilo e zelando pela
          privacidade de toda e qualquer informação, dado, resultado ou documento coletado dos
          indivíduos submetidos ao Instrumento Senna, mantendo os dados e/ou resultados da aplicação
          em arquivo, físico ou digital, por um período mínimo de 05 (cinco) anos após o término da
          aplicação do Instrumento Senna; h) Exceto quando prévia e expressamente autorizado pelo
          respectivo titular, não divulgar, revelar, publicar, direta ou indiretamente, por qualquer
          meio de comunicação, dados, imagens e/ou informações contendo o nome dos indivíduos ou
          outras variáveis que permitam a identificação dos sujeitos submetidos ao Instrumento
          Senna, mesmo após o término de vigência desta Licença, sujeitando-se às penalidades
          cabíveis na hipótese de violação ao disposto; i) Não divulgar quaisquer métodos,
          tecnologias, e/ou quaisquer outras informações que afetem assim a confidencialidade a
          respeito do funcionamento do Instrumento Senna, mesmo após o término de vigência desta
          Licença, sujeitando-se às penalidades cabíveis na hipótese de violação ao disposto; j)
          Utilizar o Instrumento Senna na íntegra e na forma como foi disponibilizado, sendo vedado
          efetuar qualquer modificação, cópia, tradução, derivação, extensão e/ou ampliação em
          quaisquer materiais, dados e/ou tecnologias ora licenciados; k) Operar o Instrumento Senna
          na plataforma digital sob a sua inteira responsabilidade, por meio do login e senha a ele
          designados, de forma que se compromete a não compartilhar essas informações com terceiros
          e a não comprometer a segurança da plataforma, na sua utilização, com quaisquer
          dispositivos maliciosos ou componentes nocivos, declarando que detém conhecimentos prévios
          e suficientes de hardware e software para usufruir da presente Licença; l) O Usuário se
          compromete a manter identificada a titularidade do Instituto e a autoria de: Oliver P.
          John, Filip de Fruyt, Ricardo Primi e Daniel Domingues dos Santos, sobre o Instrumento
          Senna no momento de sua utilização e comunicação, comprometendo-se a utilizá-lo única e
          exclusivamente conforme previsto nesta Licença, sendo vedado sublicenciar todo e qualquer
          material disponibilizado pelo Instituto sem a sua prévia aprovação, por escrito; m) Não
          praticar ou permitir qualquer ação que comprometa a integridade da aplicação adequada do
          Instrumento Senna e respectivamente da(s) base(s) de dados oriunda(s) desta coleta,
          incluindo mas sem se limitar, adaptações, aplicação parcial e manipulação dos resultados;
          e n) Não utilizar isoladamente e/ou de forma individualizada as informações e resultados
          da aplicação do Instrumento Senna, para qualquer finalidade diversa do resultado auferido
          pelo Instrumento, incluindo, mas não se limitando, para tomada de decisões sobre a
          identidade de pessoas, para suspensão de benefícios ou outros tipos de atos punitivos, sem
          a devida certificação desta identidade em outras fontes. 5. O Instituto considerará
          automaticamente rescindida a presente Licença, sem quaisquer ônus ao Instituto, caso o
          Usuário se torne inadimplente em relação a quaisquer das obrigações aqui acordadas, ou
          caso o Usuário perca as condicionantes para qual esteja autorizado a utilizar o
          Instrumento Senna, independentemente de perdas e danos que seu inadimplemento vier a
          causar. 5.1. Em função do quanto exposto na Cláusula 5 acima, o Usuário deverá isentar o
          Instituto de todo e qualquer gasto despendido em sua defesa. Com relação à referida
          indenização, esta englobará tanto os danos sofridos pelo Instituto, quanto aos titulares
          de eventuais dados coletados e ainda aos autores mencionados no item ‘l’ acima, caso o
          Instituto tenha que os indenizar em decorrência à referida inadimplência do Usuário. 6. É
          terminantemente proibido ao Usuário reproduzir, distribuir, alterar, utilizar engenharia
          reversa ou valer-se de qualquer tentativa de reverter o Instrumento e/ou quaisquer de seus
          componentes. 7. O Instituto não é responsável, direta ou indiretamente, por quaisquer
          danos e/ou prejuízos causados pela utilização dos arquivos eventualmente disponíveis para
          download ou transferência em relação ao Instrumento Senna, bem como por quaisquer
          problemas técnicos ou não relacionados à conexão do Usuário à Internet. 8. Em quaisquer
          casos de rescisão desta Licença, o Usuário se compromete a cessar por completo e de
          imediato o uso do Instrumento Senna, ficando o Instituto, em caso de descumprimento pelo
          Usuário desta obrigação, desde logo autorizado a adotar os procedimentos jurídicos e
          técnicos adequados, sujeitando-se o Usuário, ainda, às perdas e danos que tal conduta vier
          a causar ao Instituto. 9. O Usuário declara que não apoia, incentiva ou divulga, de
          qualquer forma, direta ou indiretamente, ações ou atividades voltadas, patrocinadas e/ou
          associadas ao terrorismo, bem como não se beneficia e/ou se beneficiará, sob qualquer
          pretexto, de mão-de-obra infantil e/ou de estrangeiros em situação ilegal no país ou
          análoga à de escravo, em suas atividades e nem apoia e/ou apoiará sua utilização por parte
          de seus parceiros e fornecedores, conforme estabelecido pela Constituição Federal,
          Consolidação das Leis do Trabalho e Estatuto da Criança e do Adolescente. 10. O Usuário
          declara que, em decorrência desta Licença: (i) não fornecerá ou se comprometerá a
          fornecer, a quem quer que seja, bem como não aceitará ou se comprometerá a aceitar de quem
          quer que seja, tanto por conta própria quanto por intermédio de outrem, qualquer
          pagamento, doação, compensação, vantagem financeira ou não-financeira, e benefícios de
          qualquer espécie, que constituam prática ilegal ou de corrupção nos termos da legislação
          do Brasil, em especial, mas sem se limitar, quanto ao disposto na Lei Federal n. 12.846,
          de 1º de agosto de 2013; e (ii) compromete-se a observar o Código de Conduta e Política
          Anticorrupção do Instituto, disponíveis no seguinte endereço:
          www.institutoayrtonsenna.com.br/programadeintegridade, ficando ajustado, ainda, que
          referidos documentos poderão ser alterados pelo Instituto ao longo da vigência deste
          instrumento, sendo tais modificações aplicáveis a esta Licença a partir da data de
          publicação de suas novas versões no endereço mencionado acima. 11. Qualquer conflito
          originário, relativo ou decorrente desta Licença e relacionado a quaisquer de suas
          alterações subsequentes, incluindo, sem limitação, sua formação, validade, eficácia,
          interpretação, execução, descumprimento ou extinção, será submetido à mediação,
          previamente à instauração de procedimento judicial ou arbitragem, exceto no caso de
          medidas urgentes ou acautelatórias. A mediação terá lugar em São Paulo/SP. O idioma a ser
          utilizado na mediação será a Língua Portuguesa. 12. Não obtida a resolução do conflito
          pela mediação, na forma da Cláusula 11, acima, as Usuário e Instituto elegem o Foro da
          Comarca de São Paulo, por mais privilegiado que outro seja, para dirimir as controvérsias
          originadas da presente Licença. EU LI E COMPREENDI OS TERMOS E DISPOSIÇÕES DESTA LICENÇA
          DE USO DO INSTRUMENTO SENNA E ESTOU CIENTE DE SEU INTEIRO TEOR, ACEITANDO SUAS CONDIÇÕES.`
        </Typography>
      </div>
    </Modal>
  )
}

Terms.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
}

export default Terms
