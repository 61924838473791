import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, CircularProgress, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import styles from './StudentListModal.styles'
import CardIAS from '../CardIAS'
import api from '../../services/api'
import { sortStudentsByName } from '../../utils/studentHelper'

const useStyles = makeStyles(styles)

export default function StudentListModal({ context, callback }) {
  const classes = useStyles()
  const [inep, setInep] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [fetching, setFetching] = useState(false)
  const {
    activeClassroom,
    setStudentListModal,
    setClassModal,
    setStudentsModal,
    examinees,
    setExaminees,
  } = useContext(context)
  const handleModal = setStudentsModal || setStudentListModal

  const fetchStudent = async () => {
    if (!fetching && !examinees.some(student => student.codExaminee === inep)) {
      try {
        setFetching(true)
        setErrorMessage('')
        const response = await api.get('examinees', { params: { codExaminee: inep } })
        const sortedExaminees = sortStudentsByName([response.data, ...examinees])
        setExaminees(sortedExaminees)
        setInep('')
      } catch (error) {
        setErrorMessage(error.response.data.detail)
      }
      setFetching(false)
    }
  }

  const removeStudent = id => setExaminees(examinees.filter(stud => stud.id !== id))

  const addStudentsToClassroom = async () => {
    try {
      await api.put('classrooms', {
        ...activeClassroom,
        examinees,
      })
      handleModal({ type: 'close' })
      if (typeof setClassModal === 'function') setClassModal({ type: 'close' })
      if (typeof callback === 'function') callback()
    } catch (error) {
      setErrorMessage(error.response.data.detail)
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextField
            name="inep"
            label="Digite o Código do estudante"
            variant="outlined"
            className={classes.searchInput}
            inputProps={{
              autoComplete: 'off',
              className: classes.input,
              value: inep,
            }}
            onChange={e => setInep(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') fetchStudent()
            }}
            error={Boolean(errorMessage)}
            helperText={errorMessage || ''}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.addButton}
            size="large"
            color="primary"
            onClick={fetchStudent}
            fullWidth
          >
            Adicionar à turma
          </Button>
        </Grid>
      </Grid>
      {fetching ? (
        <CircularProgress />
      ) : (
        examinees &&
        examinees.length > 0 &&
        examinees.map(examinee => (
          <Grid item xs={12} key={examinee.id} className={classes.studentCard}>
            <CardIAS handleRemove={() => removeStudent(examinee.id)}>
              <Typography
                variant="body1"
                className={examinee.starter ? classes.studentNameStarter : classes.studentName}
              >
                {examinee.name}
              </Typography>
              <Typography variant="body1" className={classes.studentInep}>
                {examinee.codExaminee}
              </Typography>
              {examinee.school && (
                <Typography variant="subtitle1" className={classes.studentSchool}>
                  {examinee.school.name}
                </Typography>
              )}
            </CardIAS>
          </Grid>
        ))
      )}
      <Grid item xs={12} container className={classes.btnContainer}>
        <Grid item xs={12}>
          <Button
            type="button"
            disabled={examinees.length === 0}
            onClick={addStudentsToClassroom}
            fullWidth
            className={classes.btnSubmit}
          >
            SALVAR ESTUDANTES
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

StudentListModal.propTypes = {
  callback: PropTypes.func,
  context: PropTypes.any.isRequired,
}

StudentListModal.defaultProps = {
  callback: undefined,
}
