import React from 'react'

import { makeStyles } from '@material-ui/styles'
import { Typography, Link } from '@material-ui/core'

import { introTerm, chapterOne, chapterTwo, chapterThree } from '../labels'
import styles from './TermsBody.style'

const useStyles = makeStyles(styles)

const TermsBody = () => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.termBody}>
        {introTerm.body}
        <Link
          href="https://www.institutoayrtonsenna.org.br/pt-br/instituto.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {introTerm.linkOne}
        </Link>
        {introTerm.bodyContinueOne}
      </Typography>
      <Typography className={classes.policyTermTitleItem}>{introTerm.aviso}</Typography>
      <Typography className={classes.termBody}>{introTerm.bodyContinue}</Typography>
      <Typography variant="h5" className={classes.policyTermTitle}>
        {chapterOne.title}
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.oneSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.oneSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twoSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twoSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.threeSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.threeSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourDotOneSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourDotOneSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourDotTwoSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourDotTwoSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourDotThreeSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourDotThreeSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourDotFourSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourDotFourSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourDotASubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourDotASubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fiveSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fiveSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.sixSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.sixSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.sevenSubTitle}
        <Typography display="inline">
          {chapterOne.sevenSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.eightSubTitle}
        <Typography display="inline">
          {chapterOne.eightSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.nineSubTitle}
        <Typography display="inline">
          {chapterOne.nineSubBody}
          <Link
            href="https://institutoayrtonsenna.org.br/politica-de-privacidade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {chapterOne.linkTwo}
          </Link>
          {chapterOne.nineSubBodyContinueOne}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.tenSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.tenSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.elevenSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.elevenSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotOneSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotOneSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotTwoSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotTwoSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotThreeSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotSubThreeBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotFourSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotFourSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotFiveSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotFiveSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotSixSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotSixSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.twelveDotSevenSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.twelveDotSevenSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.thirteenSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.thirteenSubBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterOne.fourteenSubTitle}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterOne.fourteenSubBody}
        </Typography>
      </Typography>
      <Typography variant="h5" className={classes.policyTermTitle}>
        {chapterTwo.titleOne}
      </Typography>
      <Typography className={classes.policyTermSubTitle} style={{ fontWeight: 'normal' }}>
        {chapterTwo.bodyOne}
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterTwo.subTitleOne}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwo}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwoBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleOneDotOneDotOneDotOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleOneDotOneDotOneBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleOneDotOneDotOneDotOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleOneDotOneDotOneDotOneBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleOneDotOneDotOneDotTwo}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleOneDotOneDotOneDotTwoBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleOneDotOneDotOneDotThree}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleOneDotOneDotOneDotThreeBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThree}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThreeBody}
          <Link
            href="https://institutoayrtonsenna.org.br/politica-de-privacidade/"
            target="_blank"
            rel="noopener noreferrer"
            >
              {chapterTwo.linkThree}
            </Link>
            <Typography display="inline" className={classes.policyTermSubBody}>
              {chapterTwo.subTitleThreeBodyContinue}
            </Typography>
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleOneDotThree}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleOneDotThreeBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleOneDotFour}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleOneDotFourBody}
        </Typography>
      </Typography>
      <Typography variant="h5" className={classes.policyTermSubTitle}>
        {chapterTwo.titleTwo}
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleFor}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleForBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwoDotOneDotOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwoDotOneDotOneBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleFive}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleFiveBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwoDotTwoDotOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwoDotTwoDotOnebody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwoDotTwoDotTwo}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwoDotTwoDotTwobody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleSix}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleSixBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleSeven}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleSevenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleEight}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleEightBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleNine}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleNineBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleEleven}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleElevenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwelve}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwelveBody}
        </Typography>
      </Typography>
      <Typography variant="h5" className={classes.policyTermSubTitle}>
        {chapterTwo.titleThree}
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirteen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirteenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleFourteen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleFourteenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleFifteen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleFifteenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleSixteen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleSixteenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterTwo.titleFor}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleSeventeen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleSeventeenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleEighteen}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleEighteenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterTwo.titleFive}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwenty}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentyOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyOneBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentyTwo}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyTwoBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subtitleTwentyThree}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subtitleTwentyThreeBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentyFour}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyFourBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentyFive}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyFiveBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterTwo.titleSix}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentySix}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentySixBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterTwo.titleSeven}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentySeven}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentySevenBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentyEight}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyEightBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleTwentyNine}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleTwentyNineBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirty}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirtyBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirtyOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirtyOneBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirtyTwo}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirtyTwoBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirtyThree}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirtyThreeBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterTwo.titleEight}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirtyFour}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirtyFourBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterTwo.subTitleThirtyFive}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterTwo.subTitleThirtyFiveBody}
        </Typography>
      </Typography>
      <Typography variant="h5" className={classes.policyTermTitle}>
        {chapterThree.titleOne}
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterThree.titleTwo}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterThree.subTitleOne}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterThree.subTitleOneBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterThree.subTitleTwo}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterThree.subTitleTwoBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterThree.titleThree}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterThree.subTitleThree}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterThree.subTitleThreeBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterThree.subTitleFour}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterThree.subTitleFourBody}
        </Typography>
      </Typography>
      <Typography className={classes.policyTermSubTitle}>{chapterThree.titleFour}</Typography>
      <Typography className={classes.policyTermSubTitle}>
        {chapterThree.subTitleFive}
        <Typography display="inline" className={classes.policyTermSubBody}>
          {chapterThree.subTitleFiveBody}
        </Typography>
      </Typography>
    </>
  )
}

export default TermsBody
