/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Slide,
} from '@material-ui/core'
import { getSanitizedHtmlString } from '../../utils/SanitizeHtml'
import styles from '../ResearcherInstructionAndWelcome/ResearcherInstructionAndWelcome.style'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(styles)

const StylePreviewHelperModal = props => {
  const { open, title, description, onModalClose } = props
  const classes = useStyles()

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Pré-visualização dos estilos</DialogTitle>

        <DialogContent>
          <Grid
            container
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid item className={classes.title} xs={12}>
              {title}
            </Grid>

            <Grid className={classes.textSmall} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: getSanitizedHtmlString(description) }} />
            </Grid>

            <Grid className={classes.media} xs={12}>
              <Button
                style={{ visibility: 'visible' }}
                className={classes.button}
                onClick={() => {}}
              >
                Começar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onModalClose} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

StylePreviewHelperModal.propTypes = {
  description: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default StylePreviewHelperModal
