import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const MakingChoicesContext = createContext({
  isSchoolRegistred: false,
  setIsSchoolRegistred: () => {},
  isClassRegistred: false,
  setIsClassRegistred: () => {},
  isStudentRegistred: false,
  setIsStudentRegistred: () => {},
  schools: null,
  setSchools: () => {},
  teacherSchools: null,
  setTeacherSchools: () => {},
  classRoom: null,
  setClassRoom: () => {},
  teacherClassRooms: null,
  setTeacherClassRooms: () => {},
  countStudents: 0,
  setCountStudents: () => {},
})

export default function MakingChoicesProvider({ children }) {
  const [isSchoolRegistred, _setIsSchoolRegistred] = useState(false)
  const [isClassRegistred, _setIsClassRegistred] = useState(false)
  const [isStudentRegistred, _setIsStudentRegistred] = useState(false)
  const [schools, _setSchools] = useState(null)
  const [teacherSchools, _setTeacherSchools] = useState(null)
  const [classRoom, _setClassRoom] = useState(null)
  const [teacherClassRooms, _setTeacherClassRooms] = useState(null)
  const [countStudents, _setCountStudents] = useState(0)

  const setIsSchoolRegistred = value => {
    _setIsSchoolRegistred(value)
  }

  const setIsClassRegistred = value => {
    _setIsClassRegistred(value)
  }

  const setIsStudentRegistred = value => {
    _setIsStudentRegistred(value)
  }

  const setSchools = value => {
    _setSchools(value)
  }

  const setTeacherSchools = value => {
    _setTeacherSchools(value)
  }

  const setClassRoom = value => {
    _setClassRoom(value)
  }

  const setCountStudents = value => {
    _setCountStudents(value)
  }

  const setTeacherClassRooms = value => {
    _setTeacherClassRooms(value)
  }

  return (
    <MakingChoicesContext.Provider
      value={{
        isSchoolRegistred,
        setIsSchoolRegistred,
        isClassRegistred,
        setIsClassRegistred,
        isStudentRegistred,
        setIsStudentRegistred,
        schools,
        setSchools,
        teacherSchools,
        setTeacherSchools,
        classRoom,
        setClassRoom,
        teacherClassRooms,
        setTeacherClassRooms,
        countStudents,
        setCountStudents,
      }}
    >
      {children}
    </MakingChoicesContext.Provider>
  )
}

MakingChoicesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
