/* eslint-disable max-len */
export default isMobile => ({
  loadingSpinner: {
    position: 'fixed',
    top: '40%',
    left: '50%',
  },
  headerGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  centeredGrid: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: '#003C78',
  },
  accountGrid: {
    display: 'contents',
  },
  greeting: {
    fontSize: 30,
    color: '#003C78',
    fontWeight: 'bold',
    marginTop: -5,
    marginLeft: 5,
  },
  closeGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  fullscreenDiv: {
    width: '100%',
    height: '100%',
  },
  answerGrid: {
    alignContent: 'center',
  },
  attachment: {
    width: 'unset',
  },
  buttonPlay: {
    transform: 'translate(-50%, -50%)',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '16px',
    position: 'fixed',
    zIndex: 4200,
    top: '50%',
    left: '50%',
    background: 'rgba(0,0,0,.8)',
  },
  buttonFullscreen: {
    position: 'absolute',
    right: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '8px',
  },
  progress: {
    marginTop: '1rem',
    backgroundColor: '#fff',
  },
  buttonsGrid: {
    padding: '10px',
  },
  buttonBack: {
    width: 100,
    fontWeight: '800',
  },
  buttonNext: {
    width: 100,
    fontWeight: '800',
    backgroundColor: '#ffca00',
    border: 'none',
    color: '#FCFCFC',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#ffca00',
      border: 'none',
    },
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '5px',
  },
  attachmentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  attachmentMultipleChoice: {
    width: 'unset',
    display: 'flex',
    justifyContent: 'center',
  },
  optionCenterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionHorizontalContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '7px',
  },
  hiddenRadio: {
    display: 'none',
  },
  alternative: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '5px',
    borderRadius: '8px',
    marginRight: '10px',
    marginLeft: '10px',
    padding: '12px',
    marginTop: '10px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alternativeButton: {
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#25b814 !important',
      color: 'white',
    },
  },
  childAnswer: {
    fontWeight: 'bold',
    fontStyle: 'bold',
    color: '#363636',
  },
  checked: {
    // Style for when the Radio button is checked
    color: '#003C78 !important', // The color you want for the checked (inner circle) state
    '&.Mui-checked': {
      // Ensuring the inner circle has the correct color
      color: '#FFF !important',
    },
  },
  menuItem: {
    borderBottom: '1px solid #858585',
    color: '#858585',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: !isMobile ? '#B1E9FF !important' : 'transparent',
      color: !isMobile ? '#003C78 !important' : '#858585',
      fontWeight: !isMobile ? 600 : 400,
      borderBottom: !isMobile ? '0px solid #858585' : '1px solid #858585',
    },
    '&.Mui-selected': {
      backgroundColor: '#B1E9FF !important',
      color: '#003C78 !important',
      fontWeight: 600,
    },
  },
})
