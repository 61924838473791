import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import pluralize from 'pluralize'
import { makeStyles } from '@material-ui/core/styles'
import MultiSelect from './components/MultiSelect'

const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
})

const CheckboxSelectedAutocomplete = ({
  options = [],
  label,
  handleChange,
  onBlur = () => {},
  handleClear,
  keyFilterField,
  disabled,
  values = [],
  arrayHelpers,
  error,
  helperText,
  showSelectAll,
  limitTags,
  formatLabel,
  forcePopupIcon,
  orderField,
  chip,
  size,
  InputLabelProps,
  bold,
  placeholder,
  ...props
}) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState(values)

  const getOption = option => {
    return (
      <span className={bold ? classes.boldText : ''}>
        {formatLabel ? formatLabel(option) : option.name || ''}
      </span>
    )
  }

  const handleToggleOption = selected => {
    const isNoValueSelected = selected.findIndex(v => v.value === 'noValue') !== -1
    if (!isNoValueSelected) {
      setSelectedOptions(selected)

      const lastSelected = selected[selected.length - 1]
      if (
        lastSelected &&
        values &&
        values.findIndex(value => value.id === lastSelected.id) === -1
      ) {
        arrayHelpers.push(lastSelected)
      }
    }
  }

  const handleClearOptions = () => {
    if (handleClear) {
      handleClear()
      setSelectedOptions([])
    } else {
      setSelectedOptions([])
    }
  }

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(options)
      options.map(option => {
        const valuesIds = values.map(s => s.id)
        if (!valuesIds.includes(option.id)) {
          return arrayHelpers.push(option)
        }
        return options
      })
    } else {
      handleClearOptions()
      options.map(option => arrayHelpers.remove(option))
    }
  }

  const handleArraySelected = () => {
    if (values.length > 0) {
      values.forEach((value, index) => {
        const checkPostionIsPresent = selectedOptions.findIndex(
          selected => selected.id === value.id,
        )
        if (checkPostionIsPresent === -1) {
          arrayHelpers.remove(index)
        }
      })
    }
  }

  useEffect(() => {
    handleChange(selectedOptions)
    handleArraySelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  return (
    <MultiSelect
      {...props}
      chip={chip}
      items={options}
      disabled={disabled}
      getOptionLabel={getOption}
      selectedValues={values}
      label={label}
      keyFilterField={keyFilterField}
      placeholder={placeholder || `${pluralize(label, values.length)}`}
      selectAllLabel="SELECIONAR TODAS"
      noOptionsText="Nenhuma opção disponível"
      onToggleOption={handleToggleOption}
      onClearOptions={handleClearOptions}
      onSelectAll={handleSelectAll}
      onBlur={onBlur}
      error={error}
      size={size}
      helperText={helperText}
      showSelectAll={showSelectAll}
      limitTags={limitTags}
      forcePopupIcon={forcePopupIcon}
      orderField={orderField}
      InputLabelProps={InputLabelProps}
    />
  )
}

CheckboxSelectedAutocomplete.propTypes = {
  bold: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  showSelectAll: PropTypes.bool,
}

CheckboxSelectedAutocomplete.defaultProps = {
  handleChange: () => {},
  options: [],
  showSelectAll: true,
  bold: false,
}

export default React.memo(CheckboxSelectedAutocomplete)
