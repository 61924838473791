import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const LoadingContext = createContext({
  isLoading: false,
  showLoading: () => {},
  needPopUp: false,
  setNeedPopUp: () => {},
})

export default function LoadingContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false)
  const [needPopUp, setNeedPopUp] = useState(false)

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, needPopUp, setNeedPopUp }}>
      {children}
    </LoadingContext.Provider>
  )
}

LoadingContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
