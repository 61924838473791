import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const BatchRegistrationContext = createContext()

export const BatchRegistrationProvider = ({ children }) => {
  const [isSidebarCollapsed, _setIsSidebarCollapsed] = useState(true)

  const setIsSidebarCollapsed = value => {
    _setIsSidebarCollapsed(value)
  }

  return (
    <BatchRegistrationContext.Provider value={{ isSidebarCollapsed, setIsSidebarCollapsed }}>
      {children}
    </BatchRegistrationContext.Provider>
  )
}

export default BatchRegistrationProvider

BatchRegistrationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
