import React from 'react'
import PropTypes from 'prop-types'
import ChipIas from '../ChipIas'

const DisciplineBadges = ({ ellipsis = true, item, onDelete }) => {
  return <ChipIas onDelete={() => onDelete(item)} ellipsis={ellipsis} label={item.name} />
}

DisciplineBadges.propTypes = {
  ellipsis: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
}

DisciplineBadges.defaultProps = {
  ellipsis: false,
}

export default DisciplineBadges
