const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 40px',
    '@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape)': {
      padding: '5px 40px',
    },
  },
  logo: { height: '50px' },
  logoDesktop: { height: '65px' },
  textContainer: { display: 'flex', color: '#FCFCFC', width: '20px' },
})

export default styles
