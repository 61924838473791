import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import styles from './ClassroomCards.style'

const ClassroomCard = ({ key, classes, title, subtitle, handleRemove, isEditing }) => (
  <Card key={key} className={classes.card}>
    <div className={classes.rowOne}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {handleRemove && !isEditing && (
        <IconButton onClick={handleRemove} className={classes.iconButton}>
          <DeleteForeverOutlined />
        </IconButton>
      )}
    </div>
    <Typography
      key={`key-${subtitle.substring(1, 4)}`}
      variant="body1"
      className={classes.subtitle}
    >
      {subtitle}
    </Typography>
  </Card>
)

ClassroomCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
ClassroomCard.defaultProps = {
  handleRemove: null,
}

const ClassroomCards = ({
  classes,
  classrooms,
  remove,
  showTitle,
  showEmptyCard,
  isEditing,
  showLoading,
  hideLoading,
}) => {
  return (
    <>
      {showTitle && (
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.containerTitle}>
              Turmas(s) adicionadas(s):
            </Typography>
          </Grid>
        </Grid>
      )}
      {classrooms && classrooms.length > 0 ? (
        <Grid container spacing={3} className={classes.container}>
          {classrooms.map(classroom => (
            <Grid item xs={6} key={classroom.id}>
              <ClassroomCard
                isEditing={isEditing && isEditing === true}
                classes={classes}
                title={`${classroom.name}`}
                subtitle={`ESCOLA: ${classroom.school.name}`}
                handleRemove={
                  remove
                    ? () => {
                        showLoading()

                        setTimeout(() => {
                          remove(classrooms.findIndex(c => c.id === classroom.id))
                          hideLoading()
                        })
                      }
                    : null
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        showEmptyCard && (
          <Grid item xs={6}>
            <div className={classes.emptyCard}>
              <Typography variant="body1" className={classes.emptyCardTitle}>
                Você ainda não adicionou uma turma. Que tal começar agora?
              </Typography>
            </div>
          </Grid>
        )
      )}
    </>
  )
}

ClassroomCards.propTypes = {
  classes: PropTypes.object.isRequired,
  classrooms: PropTypes.array.isRequired,
  remove: PropTypes.func,
  showEmptyCard: PropTypes.bool,
  showTitle: PropTypes.bool,
}
ClassroomCards.defaultProps = {
  showTitle: true,
  showEmptyCard: true,
  remove: null,
}

export default withStyles(styles)(React.memo(ClassroomCards))
