export default function styles(theme) {
  return {
    rootSteps: {
      width: '100%',
    },
    stepsContainer: {
      width: '100%',
      height: '60px',
      padding: '50px',
    },
    stepsContainerMobile: {
      width: '100%',
      height: '60px',
      padding: '0 20px',
    },
    stepsPosition: {
      margin: 'auto',
      display: 'inline',
    },
    iconContainer: {
      '& .MuiStepIcon-text': {
        fill: 'white',
        fontSize: '40px',
      },
    },
    link: { cursor: 'pointer' },

    button: {
      width: '100%',
    },
    progress: {
      marginTop: '1rem',
    },
    buttons: {
      justifyContent: 'flex-end',
    },
    titleStep: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '2.5vh',
      color: '#003C78',
    },
    greetingSubtitle: {
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
      fontSize: '2.5vh',
    },
    topItem: {
      textAlign: 'center',
    },
  }
}
