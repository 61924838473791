import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import styles from './RegionalCards.style'

const RegionalCard = ({ classes, title, subtitle, handleRemove }) => (
  <Card className={classes.card}>
    <div className={classes.rowOne}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {handleRemove && (
        <IconButton onClick={handleRemove} className={classes.iconButton}>
          <DeleteForeverOutlined />
        </IconButton>
      )}
    </div>
    {subtitle.map(item => {
      return (
        <Typography
          key={`key-${item.substring(1, 4)}`}
          variant="body1"
          className={classes.subtitle}
        >
          {item}
        </Typography>
      )
    })}
  </Card>
)

RegionalCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
  subtitle: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
RegionalCard.defaultProps = {
  handleRemove: null,
}

const RegionalCards = ({ classes, regionals, remove, showTitle, showEmptyCard }) => {
  return (
    <>
      {showTitle && (
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.containerTitle}>
              Regional(is) adicionada(s):
            </Typography>
          </Grid>
        </Grid>
      )}
      {regionals && regionals.length > 0 ? (
        <Grid container spacing={3} className={classes.container}>
          {regionals.map(regional => (
            <Grid item xs={6} key={regional.id}>
              <RegionalCard
                classes={classes}
                title={`${regional.name}`}
                subtitle={
                  regional.number
                    ? [`${regional.state.name}`, `${regional.number}`]
                    : [`${regional.state.name}`]
                }
                handleRemove={
                  remove ? () => remove(regionals.findIndex(s => s.id === regional.id)) : null
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        showEmptyCard && (
          <Grid item xs={6}>
            <div className={classes.emptyCard}>
              <Typography variant="body1" className={classes.emptyCardTitle}>
                Você ainda não adicionou uma escola. Que tal começar agora?
              </Typography>
            </div>
          </Grid>
        )
      )}
    </>
  )
}

RegionalCards.propTypes = {
  classes: PropTypes.object.isRequired,
  regionals: PropTypes.array.isRequired,
  remove: PropTypes.func,
  showEmptyCard: PropTypes.bool,
  showTitle: PropTypes.bool,
}
RegionalCards.defaultProps = {
  showTitle: true,
  showEmptyCard: true,
  remove: null,
}

export default withStyles(styles)(React.memo(RegionalCards))
