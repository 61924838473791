export default () => ({
  root: {
    height: '100%',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.4)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  box: {
    width: '392px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
    background: '#fff',
    paddingTop: '20px',
    borderRadius: '4px',
    paddingBottom: '8px',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  icon: {
    color: '#00A8E9',
    width: '50px',
    height: '50px',
  },

  titleBox: {
    color: '#003C78',
    lineHeight: '28px',
    paddingTop: '16px',
    paddingBottom: '8px',
    textAlign: 'center',

    '& .MuiTypography-h6': {
      fontSize: '1.5rem',
    },
  },

  contentBox: {
    color: '#3E3E3E',
    width: '328px',
    height: '88px',
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '140%',
    paddingLeft: '5px',
    paddingRight: '5px',

    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  link: {
    color: '#003C78',
    fontSize: '14px',
    fontWeight: 'bold',
    textDecoration: 'none',
  },

  buttonsActions: {
    width: '300px',
    justifyContent: 'space-around',
  },

  button: {
    width: '122px',
    height: '48px',
    fontSize: '14px',
    marginTop: '8px',
    fontWeight: 600,
    lineHeight: '19px',
    borderRadius: '4px',
  },
})
