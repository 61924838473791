const widthSide = 273
export default () => ({
  root: {
    width: widthSide,
    height: '100%',
    background: '#F0F0F0',
    borderRadius: 0,
    overflow: 'hidden auto',
    justifyContent: 'space-between',
    transition: 'width .4s linear',
    display: 'flex',
    flexDirection: 'column',

    '& button': {
      border: 'none',
      cursor: 'pointer',

      '&:hover': {
        background: '#e5e5e5',
      },

      '&:focus': {
        border: 'none',
        outline: 'none',
      },
    },
  },

  drawer: {
    width: widthSide,
    flexShrink: 0,
  },
  drawerPaper: {
    top: 'auto',
    display: 'flex',
    height: 'calc(100vh - 119px)',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  listItemText: {
    textTransform: 'uppercase',
    '& > span': {
      fontWeight: 600,
    },
  },

  listItem: {
    '& .MuiListItemIcon-root': {
      minWidth: '20px',
      marginRight: '11px',
    },
  },

  listItemCollapsed: {
    justifyContent: 'center',

    '& .MuiListItemIcon-root': {
      marginRight: 0,
    },
  },

  collapsed: {
    width: '52px',
    paddingLeft: 0,
  },

  espacoEducador: {
    marginTop: '37px',
    paddingLeft: '28px',

    flexDirection: 'column',
    alignItems: 'flex-start',

    '&:hover': {
      background: '#e5e5e5',

      '& > .Sidebar-icon-104': {
        background: '#F0F0F0',
      },
    },
  },

  icon: {
    width: '46px',
    height: '46px',
    padding: '8px',
    background: '#E5E5E5',
    borderRadius: '50%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > img': {
      width: '40px',
      height: '40px',
    },
  },

  content: {
    width: '160px',
  },

  titleContet: {
    color: '#003C78',
    display: 'block',
    fontSize: '14px',
    marginTop: '10px',
    fontFamily: 'Barlow',
    fontWeight: 500,
    lineHeight: '17px',
    textTransform: 'uppercase',
  },

  descriptionContent: {
    color: '#3E3E3E',
    fontSize: '14px',
    fontFamily: 'Barlow',
    lineHeight: '14px',
  },

  // Colapsed
  espacoEducadorCollapse: {
    marginTop: '110px',

    '&.MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  iconCollapse: {
    width: '36px',
    height: '36px',
    boxShadow: '0 19px 38px rgba(0,0,0,0.3), 0 4px 8px rgba(0,0,0,0.22)',
    background: '#E5E5E5',
    borderRadius: '50%',
    zIndex: 1,
    position: 'relative',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > img': {
      width: '30px',
      height: '30px',
    },

    '&:hover ~ div': {
      opacity: 1,
      visibility: 'visible',
      zIndex: 1,

      transition: '0.4s',
    },
  },

  contentCollapse: {
    width: '124px',
    height: '90px',
    background: '#E5E5E5',
    padding: '0 8px',
    borderRadius: '2px',
    fontSize: '14px',
    fontWeight: 500,
    opacity: 0,
    boxShadow: '0 19px 38px rgba(0,0,0,0.3), 0 4px 8px rgba(0,0,0,0.22)',

    position: 'absolute',
    left: 'calc(100% + 1px)',

    '&::after': {
      content: 'no-open-quote',
      position: 'absolute',
      top: '44%',
      right: '100%',
      borderStyle: 'solid',
      borderColor: 'transparent #E5E5E5 transparent transparent',
      borderWidth: '6px',
    },

    '& span': {
      fontSize: '10px',
    },
  },

  item: {
    color: '#003C78',

    display: 'flex',
    alignItems: 'center',

    '& svg': { color: '#003C78' },
  },
  itemText: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  linkStyle: {
    fontFamily: 'Barlow',
    font: 'normal',
    fontSize: '16px',
  },

  divider: {
    width: '100%',
    borderBottom: '1px solid #fff',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& > .MuiListItemText-root': {
      color: '#003C78',
    },

    '& svg': {
      color: '#003C78',
    },
  },

  dividerTitle: {
    marginLeft: '16px',
    fontFamily: 'Barlow',
    font: 'normal',
    fontSize: '11px',
    margin: '10px 0',
    color: '#003C78',
  },
})
