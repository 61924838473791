export default function() {
  return {
    tableCellButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',

      '&:hover': {
        cursor: 'pointer',
        opacity: 0.65,

        '& .icon': {
          opacity: 0.65,
        },
      },

      '& .icon': {
        opacity: 0,

        '&.asc': {
          transform: 'rotate(180deg)',
          opacity: 1,
        },

        '&.desc': {
          transform: 'rotate(0)',
          opacity: 1,
        },
      },
    },
  }
}
