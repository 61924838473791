import axios from 'axios'
import UserService from './UserService'
import { HttpStatus } from '../utils/HttpStatus'

const api = () => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1/`,
    timeout: 4200000,
  })

  const forceLogout = () => {
    UserService.forceLogout()
  }

  axiosInstance.interceptors.request.use(async request => {
    if (UserService.isLoggedIn()) {
      const token = await UserService.getToken()
      if (token) {
        request.headers.common.Authorization = `Bearer ${token}`
      }
    }

    return request
  })

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === HttpStatus.UNAUTHORIZED) return forceLogout()

      if (!error.response) {
        return Promise.reject(
          new Error({
            response: {
              data: {
                error: 'NETWORK_ERROR',
                errorDescription: 'Timed out',
              },
            },
          }),
        )
      }

      return Promise.reject(error)
    },
  )
  return axiosInstance
}

export default api()
