export default theme => ({
  root: {
    height: '100%',
    backgroundColor: 'white',
    padding: '10px',
    overflow: 'auto',
  },
  breadcrumb: {
    margin: 0,
    color: theme.palette.secondary[900],
  },
  typography: { marginBottom: theme.spacing(4) },
  container: {
    margin: theme.spacing(4),
    color: theme.palette.secondary[900],
  },
  title: {
    fontWeight: 'bold',
    fontSize: '3vh',
    color: '#003C78',
    marginTop: theme.spacing(3.5),
  },
  welcome: {
    fontWeight: 'bold',
    fontSize: '2vh',
    color: '#003C78',
    marginTop: theme.spacing(1.0),
  },
  intro: { marginTop: theme.spacing(3) },
  buttons: {
    display: 'flex',
    textAlign: '-webkit-right',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  button: {
    fontWeight: 'bold',
  },
  welcomeContainer: {
    width: '100%',
    height: '60px',
    padding: '50px',
  },
  greetingSubtitle: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    fontSize: '2.5vh',
  },
  topItem: {
    textAlign: 'center',
  },
})
