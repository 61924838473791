import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { matchSorter } from 'match-sorter'
import { Autocomplete } from '@material-ui/lab'

const UnityAutocomplete = ({
  options,
  label,
  handleChange,
  handleClose,
  arrayHelpers: { form, unshift },
  unities,
  optionSelected,
  setOptionSelected,
  isEditing,
  showLoading,
  hideLoading,
  error,
  helperText,
  hasSelectAll,
  disableClearable,
  disabled,
}) => {
  const [currentList, setCurrentList] = useState([])
  useEffect(() => {
    const getCurrentList = () => {
      return options
        .filter(unity => !unities.find(selectedUnity => unity.id === selectedUnity.id))
        .sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
    }

    const listWithOutSelected = getCurrentList()
    setCurrentList(listWithOutSelected)
  }, [options, unities, optionSelected])

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      keys: [
        { threshold: matchSorter.rankings.CONTAINS, key: 'name' },
        { threshold: matchSorter.rankings.CONTAINS, key: 'inep' },
      ],
    })

  const handleUnity = value => {
    if (!value && typeof handleClose === 'function') handleClose()

    setOptionSelected(value)

    if (value && value.id) {
      const selectedUnities = unities.filter(unity => unity.id === value.id)

      if (selectedUnities.length === 0) unshift(value)
      if (typeof handleChange === 'function') handleChange(value)
    }
  }

  const handleUnityChange = async (event, selected) => {
    if (selected && selected.value === 'select-all') {
      showLoading()

      setTimeout(() => {
        form.setValues({
          cities: form.values.cities,
          name: form.values.name,
          number: form.values.number,
          unities: [...unities, ...currentList.filter(unity => unity.value !== 'select-all')],
          state: form.values.state,

          regional: form.values.regional,
          rows: form.values.rows,

          competencies: form.values.competencies,
          description: form.values.description,
          grades: form.values.grades,
          regionals: form.values.regionals,
          unityYear: form.values.unityYear,

          userId: form.values.userId,
          email: form.values.email,
          cpf: form.values.cpf,

          status: form.values.status,
          initialDate: form.values.initialDate,
          finalDate: form.values.finalDate,
          classrooms: [],
        })
        hideLoading()
      })
    }
    return handleUnity(selected)
  }

  return (
    <Autocomplete
      disabled={(isEditing && isEditing === true) || disabled}
      disableCloseOnSelect
      disableListWrap
      options={currentList}
      getOptionLabel={option => {
        if (option) {
          if (option.name && option.inep) return `${option.inep} - ${option.name}`
          if (option.name && !option.inep) return `${option.name}`
        }
        return null
      }}
      disableClearable={disableClearable}
      onKeyDown={() => {}}
      filterOptions={(opt, params) => {
        const filtered = filterOptions(opt, params)
        if (options.length > 0 && currentList.length > 0 && hasSelectAll) {
          return [{ name: 'SELECIONAR TODAS', value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={(event, value) => handleUnityChange(event, value)}
      noOptionsText="Nenhuma opção disponível"
      value={optionSelected}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
}

UnityAutocomplete.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  disableClearable: PropTypes.bool,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  hasSelectAll: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  unities: PropTypes.array.isRequired,
}

UnityAutocomplete.defaultProps = {
  handleChange: () => {},
  handleClose: () => {},
  handleReset: () => {},
  options: [],
  error: false,
  hasSelectAll: true,
  disableClearable: false,
}

export default React.memo(UnityAutocomplete)
