import { useEffect, useState } from 'react'

import { useSnackbar } from '../../../shared/hooks'
import { getData } from '../../../utils/fetchData/fetchData'

const useFilterOptions = () => {
  const [state, setState] = useState([])

  const getFilterOptions = requestParams => {
    getData(requestParams).then(data => {
      const isDataList = data && Array.isArray(data)
      const isDataWithContent = data && data.content && Array.isArray(data.content)

      if (isDataList) {
        setState(data)
      } else if (isDataWithContent) {
        setState(data.content)
      }
    })
  }

  return { state, setState, getFilterOptions }
}

const useTeachingNetworksFilterOptions = currentUser => {
  const { state: teachingNetworks, getFilterOptions: getTeachingNetworks } = useFilterOptions()
  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  useEffect(() => {
    if (!currentUser || !currentUser.teachingNetwork || isLoading) {
      return
    }

    const { id } = currentUser.teachingNetwork

    const requestParams = {
      url: `teaching-networks/network-manager-at/${id}`,
      snackbarError: () => snackbarError('Falha ao buscar as redes, tente atualizar a página'),
      setLoading,
    }

    getTeachingNetworks(requestParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return {
    teachingNetworks,
    isTeachingNetworksLoading: isLoading,
  }
}

const useSchoolsFilterOptions = (
  currentUser,
  isImplementador,
  formFilterValues,
  setFieldValue,
  hasSelectedTeachingNetwork,
) => {
  const { state: schools, setState: setSchools, getFilterOptions: getSchools } = useFilterOptions()

  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  useEffect(() => {
    setFieldValue('school', { name: '' })
    setSchools([])

    if (isImplementador) {
      if (!hasSelectedTeachingNetwork || isLoading) {
        return
      }
    } else if (!currentUser || !currentUser.teachingNetwork || isLoading) {
      return
    }

    let { id } = ''
    let isFilter

    if (isImplementador) {
      id = formFilterValues.teachingNetwork.id
      isFilter = true
    } else {
      id = currentUser.teachingNetwork.id
      isFilter = false
    }

    const params = {
      teachingNetwork: id,
      isFilter,
    }

    const requestParams = {
      url: `regionals/schools/teachingNetwork/${id}`,
      options: { params },
      snackbarError: () => snackbarError('Falha ao buscar as escolas, tente atualizar a página'),
      setLoading,
    }

    getSchools(requestParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedTeachingNetwork])

  return {
    schools,
    isSchoolsLoading: isLoading,
  }
}

const useClassroomsFilterOptions = (profile, formFilterValues, setFieldValue) => {
  const {
    state: classrooms,
    setState: setClassrooms,
    getFilterOptions: getClassrooms,
  } = useFilterOptions()
  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  const hasSelectedSchool = formFilterValues.school && formFilterValues.school.id

  useEffect(() => {
    setFieldValue('classroom', { name: '' })
    setClassrooms([])

    if (!hasSelectedSchool || isLoading) {
      return
    }

    const { id } = profile
    const params = {
      profileId: id,
      school_id: formFilterValues.school.id,
      size: 100,
    }

    const requestParams = {
      url: `classrooms`,
      options: { params },
      snackbarError: () => snackbarError('Falha ao buscar as turmas, tente atualizar a página'),
      setLoading,
    }

    getClassrooms(requestParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFilterValues.school, formFilterValues.grade])

  return {
    classrooms,
    isClassroomsLoading: isLoading,
  }
}

const useGradesFilterOptions = classrooms => {
  const { state: grades, setState: setGrades } = useFilterOptions()

  useEffect(() => {
    const gradesFiltered = classrooms
      .map(classroom => classroom.grade)
      .filter((value, index, self) => {
        const gradesId = self.map(grade => grade.id)
        return gradesId.indexOf(value.id) === index
      })
      .sort()

    setGrades(gradesFiltered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classrooms])

  return { grades }
}

export {
  useSchoolsFilterOptions,
  useClassroomsFilterOptions,
  useTeachingNetworksFilterOptions,
  useGradesFilterOptions,
}
