const colors = {}

// Blue colors
const royal = '197, 100%, 46%'
colors.RoyalBlue = `hsla(${royal}, 1)`
colors.defaultButtonDisabled = `hsla(${royal}, 0.3)`

// Large Button colors
colors.primaryLargeBlue = 'hsla(210, 100%, 24%, 1)'
colors.primaryLargeDarkBlue = 'hsla(210, 100%, 14%, 1)'
colors.primaryLargeBorder = 'hsla(48, 100%, 50%, 1)'
colors.primaryLargeDisabled = 'hsla(210, 100%, 24%, 0.3)'

// border Button colors
colors.containedDisabled = 'hsla(210, 100%, 24%, 0.3)'

// outlined button
colors.outlinedHover = 'hsla(48, 100%, 50%, 0.1)'
colors.outlineLargeText = 'hsla(210, 100%, 24%, 1)'
colors.outlineText = 'hsla(197, 100%, 46%, 1)'

export default Object.freeze(colors)
