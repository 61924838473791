import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import { useSnackbar } from '../hooks'
import styles from './UnityCards.style'

const UnityCard = ({ key, classes, title, subtitle, handleRemove, isEditing }) => (
  <Card key={key} className={classes.card}>
    <div className={classes.rowOne}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {handleRemove && !isEditing && (
        <IconButton onClick={handleRemove} className={classes.iconButton}>
          <DeleteForeverOutlined />
        </IconButton>
      )}
    </div>
    {subtitle.map(item => {
      return (
        <Typography
          key={`key-${item.substring(1, 4)}`}
          variant="body1"
          className={classes.subtitle}
        >
          {item}
        </Typography>
      )
    })}
  </Card>
)

UnityCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
  subtitle: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
UnityCard.defaultProps = {
  handleRemove: null,
}

const UnityCards = ({
  classes,
  unities,
  remove,
  unitiesWithAssessmentCreatedBySameUserList,
  isEditingProfile,
  showTitle,
  showEmptyCard,
  isEditing,
  showLoading,
  hideLoading,
}) => {
  const { snackbarError } = useSnackbar()
  const validateActiveAssessmentBySameUser = id => {
    if (
      typeof unitiesWithAssessmentCreatedBySameUserList !== 'undefined' &&
      unitiesWithAssessmentCreatedBySameUserList.length > 0
    ) {
      const response = unitiesWithAssessmentCreatedBySameUserList.find(
        validateIsActive => validateIsActive.schoolId === id,
      )
      return response !== undefined ? response.isInAnActiveAssessmentBySameUser : false
    }
    return false
  }
  return (
    <>
      {showTitle && (
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.containerTitle}>
              Unidade(s) adicionada(s):
            </Typography>
          </Grid>
        </Grid>
      )}
      {unities && unities.length > 0 ? (
        <Grid container spacing={3} className={classes.container}>
          {unities.map(unity => (
            <Grid item xs={6} key={unity.id}>
              <UnityCard
                isEditing={isEditing}
                classes={classes}
                title={`${unity.name}`}
                subtitle={[`${unity.description || unity.name}`]}
                handleRemove={
                  remove
                    ? () => {
                        if (validateActiveAssessmentBySameUser(unity.id)) {
                          if (isEditingProfile) {
                            snackbarError(
                              `Exclusão Bloqueada!
                        Unidade presente em uma avaliação Ativa no perfil deste Gestor!`,
                            )
                          } else {
                            snackbarError(
                              `Exclusão Bloqueada!
                        Unidade presente em uma avaliação Ativa no perfil deste Professor!`,
                            )
                          }
                        } else {
                          setTimeout(() => {
                            remove(unities.findIndex(s => s.id === unity.id))
                          })
                        }
                      }
                    : null
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        showEmptyCard && (
          <Grid item xs={6}>
            <div className={classes.emptyCard}>
              <Typography variant="body1" className={classes.emptyCardTitle}>
                Você ainda não adicionou uma Unidade. Que tal começar agora?
              </Typography>
            </div>
          </Grid>
        )
      )}
    </>
  )
}

UnityCards.propTypes = {
  classes: PropTypes.object.isRequired,
  remove: PropTypes.func,
  showEmptyCard: PropTypes.bool,
  showTitle: PropTypes.bool,
  unities: PropTypes.array.isRequired,
}
UnityCards.defaultProps = {
  showTitle: true,
  showEmptyCard: true,
  remove: null,
}

export default withStyles(styles)(React.memo(UnityCards))
