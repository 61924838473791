import React from 'react'
import PropTypes from 'prop-types'
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import appTheme from '../../utils/theme'
import styles from './PaginationTable.style'

const useStyles = makeStyles(theme => styles(theme))

const PaginationTable = props => {
  const classes = useStyles()

  const { offset, data, onPressChangePage, isOffsetIsZero, isOffsetIsLast } = props

  return (
    <MuiThemeProvider theme={appTheme}>
      <CssBaseline />
      <Grid className={classes.rootPagination}>
        <ArrowBackIosOutlined
          className={classes.icon}
          style={{
            cursor: isOffsetIsZero ? 'default' : 'pointer',
            marginRight: '15px',
          }}
          onClick={() => (!isOffsetIsZero ? onPressChangePage(offset - 1) : '')}
          color={isOffsetIsZero ? 'disabled' : 'primary'}
        />
        <Typography style={{ fontWeight: '600', color: '#566573', fontSize: '14px' }}>
          Página {offset + 1} de {data.totalPages || 1}
        </Typography>
        <ArrowForwardIosOutlined
          className={classes.icon}
          style={{
            cursor: isOffsetIsLast ? 'default' : 'pointer',
            marginLeft: '15px',
          }}
          onClick={() => (!isOffsetIsLast ? onPressChangePage(offset + 1) : '')}
          color={isOffsetIsLast ? 'disabled' : 'primary'}
        />
      </Grid>
    </MuiThemeProvider>
  )
}

PaginationTable.propTypes = {
  data: PropTypes.shape({
    totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isOffsetIsLast: PropTypes.bool.isRequired,
  isOffsetIsZero: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  onPressChangePage: PropTypes.func.isRequired,
}

export default PaginationTable
