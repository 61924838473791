import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import api from '../../services/api'

export const NetworkManagerContext = createContext()

export default function LoadingContextProvider({ children }) {
  const [firstAccess, _setFirstAccess] = useState(true)

  const setFirstAccess = value => {
    _setFirstAccess(value)
  }

  const getRegionalIds = async () => {
    if (firstAccess) {
      await api
        .get('network-manager-regional')
        .then(response => {
          const { data } = response
          localStorage.setItem('regionalIds', JSON.stringify(data))
        })
        .finally(() => {
          setFirstAccess(false)
        })
    }
  }

  return (
    <NetworkManagerContext.Provider value={{ getRegionalIds }}>
      {children}
    </NetworkManagerContext.Provider>
  )
}

LoadingContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
