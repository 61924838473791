import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import styles from './LoadingModal.styles'
import { LoadingContext } from '../../contexts/LoadingContext/LoadingContext'
import popUpImage from './popup-image.png'

const useStyles = makeStyles(styles)

export default function LoadingModal(props) {
  const classes = useStyles()
  const { isLoading, needPopUp } = useContext(LoadingContext)

  return (
    isLoading && (
      <div {...props} className={classes.loading}>
        <CircularProgress />
        {needPopUp ? (
          <>
            <Typography variant="h6">
              Estamos processando sua solicitação, por favor aguarde
            </Typography>
            <Typography variant="body1">
              Ao finalizar, certifique-se de permitir os Pop-ups na barra de pesquisa seguindo o
              exemplo.
            </Typography>
            <img src={popUpImage} alt="alt" className={classes.image} />
          </>
        ) : (
          <>
            <Typography variant="h6">Estamos processando sua solicitação</Typography>
            <Typography variant="body1">
              Isso pode demorar alguns segundos, por favor aguarde
            </Typography>
          </>
        )}
      </div>
    )
  )
}
