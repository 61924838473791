import api from '../services/api'

export async function validateDuplicateCPF(cpfArg, isEditing, validateActive) {
  if (cpfArg === undefined) return false

  const cpf = cpfArg.replace(/[^\d]+/g, '')
  try {
    const response = validateActive
      ? await api.get('user', { params: { cpf } })
      : await api.get(`user/find-by-cpf/without-validation/${cpf}`)

    if (response.data) {
      if (isEditing) return [true, response.data]
    }

    return [false, undefined]
  } catch (error) {
    return [true, undefined]
  }
}

export function validarCPF(cpfArg, isEditing) {
  if (isEditing) return true
  const cpf = cpfArg.replace(/[^\d]+/g, '')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i += 1) add += parseInt(cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9))) return false
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i += 1) add += parseInt(cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10))) return false
  return true
}

export function removeCpfMask(cpf) {
  const formatedCpf = cpf
    .split('.')
    .join('')
    .split('-')
    .join('')
  return formatedCpf
}

export function formatCpf(cpf) {
  if (!cpf || cpf.length !== 11) return false

  const cpfAtualizado = cpf.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    (regex, argumento1, argumento2, argumento3, argumento4) => {
      return `${argumento1}.${argumento2}.${argumento3}-${argumento4}`
    },
  )
  return cpfAtualizado
}
