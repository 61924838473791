import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Fade,
  Grid,
  Menu,
  MenuItem,
  CardHeader,
  IconButton,
  CardActions,
  CardContent,
} from '@material-ui/core'
import LinesEllipsis from 'react-lines-ellipsis'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined'
import { makeStyles } from '@material-ui/styles'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import styles from './CardIAS.styles'
import ModalDialogPopUp from '../ModalDialogPopUp'

const useStyles = makeStyles(styles)

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

const CardIAS = ({
  title,
  subtitle,
  children,
  Actions,
  handleRemove,
  MenuOptions,
  disabledMaxWidth,
  showMenuForOptions,
  disabledAction,
  ...props
}) => {
  const classes = useStyles()

  const maxWidth = disabledMaxWidth ? null : { maxWidth: 345 }

  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setOpenTooltip] = React.useState(false)
  const [showCloseModalDialog, setShowCloseModalDialog] = useState(false)

  const handleCloseTooltip = () => {
    setOpenTooltip(false)
  }

  const handleOpenTooltip = () => {
    setOpenTooltip(true)
  }

  const handleOpen = useCallback(e => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClose = useCallback(item => {
    handleCloseTooltip()
    item.action()
    setAnchorEl(null)
  }, [])

  return (
    <Card
      className={[
        classes.card,
        MenuOptions.map(item => item.isCardClickable && classes.pointer),
      ].join(' ')}
      style={maxWidth}
      {...props}
    >
      <CardHeader
        className={classes.content}
        title={
          <Tooltip title={title}>
            <ResponsiveEllipsis className={classes.title} text={title} maxLine="3" />
          </Tooltip>
        }
        subheader={subtitle}
        action={
          <>
            {handleRemove && (
              <IconButton
                disabled={disabledAction}
                onClick={() => {
                  setShowCloseModalDialog(true)
                }}
                color="primary"
              >
                <DeleteOutlineOutlined />
              </IconButton>
            )}
            {showMenuForOptions ? (
              <>
                <IconButton onClick={handleOpen}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  className={MenuOptions[0].label ? `${classes.menu}` : ``}
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  TransitionComponent={Fade}
                >
                  {MenuOptions.map(item => (
                    <MenuItem
                      key={`${Math.random()}-${item.title}`}
                      disabled={disabledAction || item.disabledAction}
                      onClick={() => handleClose(item)}
                    >
                      {item.label ? (
                        <Tooltip title={item.title} placement="right">
                          <IconButton
                            onClick={() => handleClose(item)}
                            color={disabledAction ? 'default' : 'primary'}
                          >
                            {item.label || <item.label />}
                          </IconButton>
                        </Tooltip>
                      ) : (
                        item.title
                      )}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              MenuOptions.map(item => (
                <>
                  {item.title ? (
                    <Tooltip
                      key={`${Math.random()}-${item.label}`}
                      open={openTooltip}
                      onClose={handleCloseTooltip}
                      onOpen={handleOpenTooltip}
                      title={item.title}
                      placement="right"
                    >
                      <IconButton
                        key={`${Math.random()}-${item.label}`}
                        disabled={disabledAction}
                        onClick={() => handleClose(item)}
                        color="primary"
                      >
                        {item.label || <item.label />}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      key={`${Math.random()}-${item.label}`}
                      disabled={disabledAction}
                      onClick={() => handleClose(item)}
                      color="primary"
                    >
                      {item.label || <item.label />}
                    </IconButton>
                  )}
                </>
              ))
            )}
          </>
        }
      />

      <CardContent
        onClick={
          !disabledAction
            ? () => {
                MenuOptions.map(item => {
                  if (item.isCardClickable) {
                    item.action()
                  }
                  return undefined
                })
              }
            : () => {
                return null
              }
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
      {Actions && <CardActions data-testid="card-actions">{Actions}</CardActions>}

      <ModalDialogPopUp
        id="modalDialogCardIAS"
        open={showCloseModalDialog}
        title="Atenção"
        text="Seus dados serão excluídos.</br>Deseja continuar?"
        textConfirm="Excluir dados"
        textClose="Voltar"
        handleClose={() => {
          setShowCloseModalDialog(false)
        }}
        handleConfirm={e => {
          handleRemove(e)
        }}
      />
    </Card>
  )
}

CardIAS.propTypes = {
  Actions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  disabledAction: PropTypes.bool,
  disabledMaxWidth: PropTypes.bool,
  handleRemove: PropTypes.func,
  MenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      isCardClickable: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  showMenuForOptions: PropTypes.bool,
  subtitle: PropTypes.any,
  title: PropTypes.string,
}

CardIAS.defaultProps = {
  Actions: undefined,
  disabledAction: false,
  disabledMaxWidth: false,
  handleRemove: null,
  MenuOptions: [],
  showMenuForOptions: false,
  subtitle: undefined,
  title: undefined,
}

export default React.memo(CardIAS)
