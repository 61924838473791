import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import StepComponent from './steps/StepComponent'
import styles from './PartnershipCollaboratorAssessmentForm.style'
import ReportGenerationDialog from '../../../components/ReportGenerationDialog'
import ApplicationContextProvider from '../../../contexts/ApplicationContext'
import CloseAssessmentModal from './components/CloseAssessmentModal/CloseAssessmentModal'

const useStyles = makeStyles(styles)

const PartnershipCollaboratorAssessmentForm = ({
  history,
  assessmentPartnershipCollaborator,
  researcherAssessment,
  getInstrument,
}) => {
  const [activeStep, setActiveStep] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showCloseModalDialog, setShowCloseModalDialog] = useState(false)
  const classes = useStyles()
  const [reportGenerationDialog, setReportGenerationDialog] = useState(false)

  useEffect(() => {
    setActiveStep(assessmentPartnershipCollaborator.step)
    setIsLoading(false)
  }, [assessmentPartnershipCollaborator])

  function getStepContent(step) {
    return (
      <ApplicationContextProvider>
        <StepComponent
          step={step}
          researcherAssessment={researcherAssessment}
          history={history}
          getInstrument={getInstrument}
          assessmentPartnershipCollaborator={assessmentPartnershipCollaborator}
          closeApplication={() => {
            setShowCloseModalDialog(true)
          }}
        />
      </ApplicationContextProvider>
    )
  }

  return (
    <>
      {isLoading ? (
        <Grid container spacing={3} className={classes.root}>
          <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </Grid>
      ) : (
        <Grid
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {getStepContent(activeStep)}
          <ReportGenerationDialog
            open={reportGenerationDialog}
            handleClose={() => setReportGenerationDialog(false)}
          />
          <CloseAssessmentModal
            open={showCloseModalDialog}
            handleClose={() => {
              setShowCloseModalDialog(false)
            }}
            onExitAssessment={() => {
              localStorage.removeItem('initialDateTimeSessionTemp')
              history.push('/aplicacoes-respondente')
            }}
          />
        </Grid>
      )}
    </>
  )
}

PartnershipCollaboratorAssessmentForm.propTypes = {
  researcherAssessment: PropTypes.object.isRequired,
}

export default withStyles(styles)(PartnershipCollaboratorAssessmentForm)
