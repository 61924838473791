import { FormGroup } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CheckboxAssessmentForm from './CheckboxAssessmentForm'

const CheckboxAssessmentControl = ({
  question,
  values,
  handleChange,
  hasNextButton,
  setFieldValue,
  blockNextButton,
}) => {
  const [selecteds, setSelecteds] = useState([])
  const [canNext, setCanNext] = useState(false)

  useEffect(() => {
    if (selecteds.length > 0 && selecteds.length <= question.answerKey.selectionQuantity) {
      setFieldValue(question.id, { parentValue: selecteds, childValue: null })
    }
  }, [question.answerKey.selectionQuantity, question.id, selecteds, setFieldValue])

  const handleChangeSelection = event => {
    const value = parseInt(event.target.value.toString())

    if (selecteds.includes(value)) {
      setSelecteds(selecteds.filter(option => option !== value))
      setCanNext(false)
      blockNextButton(true)
    } else if (selecteds.length < question.answerKey.selectionQuantity) {
      const newValue = [...selecteds, value]
      setSelecteds(newValue)
      if (newValue.length === question.answerKey.selectionQuantity) {
        setCanNext(true)
        blockNextButton(false)
      }
    }
  }

  useEffect(() => {
    if (
      (selecteds.length === question.answerKey.selectionQuantity || hasNextButton || canNext) &&
      selecteds.length !== 0
    ) {
      const valores = values
      if (!hasNextButton) {
        valores[question.id] = { parentValue: selecteds, childValue: null }
      }
      handleChange(question, selecteds, valores)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecteds])

  useEffect(() => {
    if (question.responsesSelecteds) {
      setSelecteds(question.responsesSelecteds.map(resp => resp.id))
      setCanNext(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question])

  return (
    <FormGroup>
      {question.answerKey.items.map(item => (
        <CheckboxAssessmentForm
          selecteds={selecteds}
          handleChangeSelection={handleChangeSelection}
          item={item}
        />
      ))}
    </FormGroup>
  )
}

export default CheckboxAssessmentControl
