import { validarEmail } from '../utils/email/emailHelper'
import { validarCPF, validateDuplicateCPF } from '../utils/cpfHelper'

export async function isEmailRegistered(email, isEditing, id, validateActive) {
  if (!email) return false

  const response = await validarEmail(email, isEditing, id, validateActive)

  return response
}

export function isCpfValid(cpf, isEditing) {
  if (!cpf) return false

  const response = validarCPF(cpf, isEditing)

  return response
}

export async function isCpfRegistered(cpf, isEditing, validateActive) {
  if (!cpf) return false

  const response = await validateDuplicateCPF(cpf, isEditing, validateActive)

  return response
}

export default { isEmailRegistered, isCpfValid, isCpfRegistered }
