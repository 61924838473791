export default function styles(theme) {
  return {
    chip: {
      backgroundColor: '#F3F3F3',
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '17px',
      marginRight: '3px',
    },
    icon: {
      width: '16px',
      height: '16px',
      color: theme.palette.primary.main,
    },
  }
}
