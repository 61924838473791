import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Clock from './img/clock.svg'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '392px',
    padding: '36px 32px',
    textAlign: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '38px',
    textAlign: 'center',
    color: '#003C78',
    marginBottom: theme.spacing(2),
  },
  text: {
    padding: '9.5px 0',
    fontSize: '16px',
    lineHeight: '140%',
    textAlign: 'center',
    color: '#3E3E3E',
    marginTop: theme.spacing(3),
    width: '328px',
    margin: '0 auto',
  },
  buttonContainer: {
    justifyContent: 'center',
    paddingBottom: '24px',
  },
  button: {
    background: '#003C78',
  },
  img: { marginBottom: '28.94px' },
}))

const ReportGenerationDialog = ({ open, handleClose }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className={classes.dialog}>
        <img src={Clock} alt="e-mail" className={classes.img} />
        <Typography className={classes.title}>
          A geração do seu relatório está em processamento
        </Typography>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography className={classes.text} component="label">
            Você pode acompanhar o status na página da Biblioteca de relatórios.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button onClick={handleClose} size="large" className={classes.button}>
          OK, ENTENDI
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReportGenerationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default React.memo(ReportGenerationDialog)
