import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import React from 'react'
import colors from '../../../../../../utils/theme/colors'

const useStyles = makeStyles(theme => ({
  label: {
    borderRadius: '10px',
    margin: '0 0 5px 0',
    padding: '5px',
    color: colors.primaryLargeBlue,
    border: '2px solid hsla(210, 100%, 24%, 1)',
    transition: 'background-color 0.3s ease',
    '&.checked': {
      backgroundColor: colors.primaryLargeBlue,
      color: theme.palette.common.white,
    },
  },
  checkbox: {
    color: colors.primaryLargeBlue,
    '&.Mui-checked': {
      color: theme.palette.common.white,
    },
    '&.MuiSvgIcon-root': {
      fontSize: '30px',
    },
  },
}))

const CheckboxAssessmentForm = ({ item, selecteds, handleChangeSelection }) => {
  const classes = useStyles()
  const { id, description } = item

  return (
    <FormControlLabel
      control={
        <Checkbox
          value={id}
          checked={selecteds.includes(id)}
          onChange={handleChangeSelection}
          className={classes.checkbox}
        />
      }
      label={description}
      className={`${classes.label} ${selecteds.includes(id) ? 'checked' : ''}`}
    />
  )
}

export default CheckboxAssessmentForm
