import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  DialogContentText,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './SchoolManagerSelfDeclarationDialog.style'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SchoolManagerSelfDeclarationDialog = ({ handleAcceptSelfDeclarationSchoolManager, open }) => {
  const classes = useStyles()

  return (
    open && (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogContent className={classes.dialog}>
            <Typography className={classes.title}>Autodeclaração</Typography>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography className={classes.text} component="label">
                Declaro para os devidos fins que sou responsável pelas escolas vínculadas a mim, e
                declaro ser integralmente responsável por todas as informações e dados pessoais que
                vier ter acesso no uso desta plataforma.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <Button
              color="primary"
              size="large"
              onClick={() => handleAcceptSelfDeclarationSchoolManager()}
            >
              Sim, podemos continuar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  )
}

SchoolManagerSelfDeclarationDialog.propTypes = {
  handleAcceptSelfDeclarationSchoolManager: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

SchoolManagerSelfDeclarationDialog.defaultProps = {
  open: false,
}

export default React.memo(SchoolManagerSelfDeclarationDialog)
