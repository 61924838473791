import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  applicationFilter: {
    name: null,
    school: {},
    instrument: {},
    startDate: null,
    endDate: null,
    creationYear: {},
    status: null,
    registrationDate: null,
    regional: null,
  },
  classroomFilter: {
    classroom: {},
    school: {},
    shift: {},
    schoolYear: {},
    grade: {},
  },
  studentFilter: {
    classroom: {},
    name: '',
    school: {},
    shift: {},
    schoolYear: {},
    grade: {},
  },
  studentActualFilter: {
    classrooms: [],
    name: '',
    schools: [],
    shift: {},
    schoolYear: {},
    grade: {},
  },
  teacherFilter: {
    classroom: {},
    name: '',
    school: {},
  },
  teacherActualFilter: {
    name: '',
    schools: [],
  },
  classLevel: {
    city: null,
    school: null,
    periodicity: null,
    period: null,
    status: null,
  },
  classLevelManual: {
    city: null,
    school: null,
    classroom: null,
    program: null,
    periodicity: null,
    period: null,
    status: null,
  },
}

export const FilterContext = createContext({ ...initialState })

export default function FilterContextProvider({ children }) {
  const [filters, setFiltersState] = useState(initialState)

  function resetFilterState() {
    setFiltersState(initialState)
  }

  return (
    <FilterContext.Provider value={{ filters, setFiltersState, resetFilterState }}>
      {children}
    </FilterContext.Provider>
  )
}

FilterContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
