import { createContext } from 'react'

const AuthContext = createContext({
  signedIn: false,
  signedInPlatform: false,
  setSignedIn: value => value,
  closeDrawerMenu: false,
  setCloseDrawerMenu: value => value,
})

export default AuthContext
