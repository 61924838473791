import React, { useEffect, useState, useReducer, createContext } from 'react'
import PropTypes from 'prop-types'

export const ApplicationContext = createContext({
  activeExternalPartnership: undefined,
  activeSchool: undefined,
  activeClassroom: undefined,
  setActiveClassroom: undefined,
  selectedSchools: [],
  selectedClassrooms: [],
  selectedExternalPartnerships: [],
  selectedPartnershipCollaborators: [],
  schoolClassrooms: [],
  setSchoolClassrooms: undefined,
  setActiveSchool: undefined,
  setSelectedClassrooms: undefined,
  setSchools: undefined,
  classModal: {},
  setClassModal: undefined,
  studentListModal: {},
  setStudentListModal: {},
  addStudents: false,
  partnershipCollaborators: [],
  setPartnershipCollaborators: undefined,
  setSelectedPartnershipCollaborators: undefined,
  setSelectedExternalPartnerships: undefined,
  setAddStudents: () => {},
})

function reducer(state, action) {
  switch (action.type) {
    case 'open':
      return { ...state, isOpen: true, ...action.payload }
    case 'close':
      return { ...state, isOpen: false }
    default:
      return state
  }
}

const ApplicationContextProvider = ({ children }) => {
  const [selectedSchools, setSelectedSchools] = useState([])
  const [selectedExternalPartnerships, setSelectedExternalPartnerships] = useState([])
  const [selectedClassrooms, setSelectedClassrooms] = useState([])
  const [schoolClassrooms, setSchoolClassrooms] = useState([])
  const [partnershipCollaborators, setPartnershipCollaborators] = useState([])
  const [activeSchool, setActiveSchool] = useState()
  const [activeExternalPartnership, setActiveExternalPartnership] = useState()
  const [activeClassroom, setActiveClassroom] = useState()
  const [addStudents, setAddStudents] = useState(false)
  const [selectedPartnershipCollaborators, setSelectedPartnershipCollaborators] = useState([])

  const [classModal, setClassModal] = useReducer(reducer, {
    title: 'Adicionar nova turma',
    subTitle: '',
    btnText: 'SALVAR',
    btnCallback: () => {},
    hideSave: true,
    isOpen: false,
    onClose: () => setClassModal({ type: 'close' }),
  })

  const [studentListModal, setStudentListModal] = useReducer(reducer, {
    title: 'Vincular estudantes',
    subTitle: '',
    btnText: 'SALVAR',
    btnCallback: () => {},
    hideSave: true,
    isOpen: false,
    onClose: () => setStudentListModal({ type: 'close' }),
  })

  useEffect(() => {
    if (addStudents && activeClassroom)
      setStudentListModal({ type: 'open', payload: { subtitle: activeClassroom } })
  }, [addStudents, activeClassroom, setStudentListModal])

  return (
    <ApplicationContext.Provider
      value={{
        activeSchool,
        activeClassroom,
        activeExternalPartnership,
        setActiveClassroom,
        setActiveExternalPartnership,
        selectedSchools,
        selectedExternalPartnerships,
        selectedClassrooms,
        setActiveSchool,
        setSelectedClassrooms,
        setSelectedSchools,
        setSelectedExternalPartnerships,
        classModal,
        setClassModal,
        schoolClassrooms,
        setSchoolClassrooms,
        studentListModal,
        setStudentListModal,
        addStudents,
        setAddStudents,
        partnershipCollaborators,
        setPartnershipCollaborators,
        selectedPartnershipCollaborators,
        setSelectedPartnershipCollaborators,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

ApplicationContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default ApplicationContextProvider
