import React from 'react'

import PropTypes from 'prop-types'

import { makeStyles, Chip } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import styles from './ChipIas.styles'

const useStyles = makeStyles(styles)

const EllipsisText = ({ children }) => {
  return (
    <div
      style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 100,
      }}
    >
      {children}
    </div>
  )
}
EllipsisText.propTypes = {
  children: PropTypes.node.isRequired,
}

function ChipIas({ label, ellipsis, handleDelete, isEditing }) {
  const classes = useStyles()

  return (
    <Chip
      disabled={isEditing && isEditing === true}
      className={classes.chip}
      deleteIcon={<Clear className={classes.icon} />}
      onDelete={handleDelete}
      label={ellipsis ? <EllipsisText>{label}</EllipsisText> : label}
      {...ellipsis}
    />
  )
}

ChipIas.defaultProps = {
  ellipsis: true,
  handleDelete: undefined,
}

ChipIas.propTypes = {
  ellipsis: PropTypes.bool,
  handleDelete: PropTypes.func,
  label: PropTypes.string.isRequired,
}
export default React.memo(ChipIas)
