/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import leftArrow from './icons/left-arrow.svg'

const useStyles = makeStyles(() => ({
  customContainer: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 110,
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    backgroundColor: '#003C78',
    flex: 'none',
  },
  children: {
    width: '100%',
    height: 'calc(100vh - 110px)',
    flex: 1,
  },
  btnFooter: {
    backgroundColor: '#003C78',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderRadius: 0,
  },
  back: {
    color: '#00A8E9',
    marginRight: '21px',
    cursor: 'pointer',
  },
  title: {
    color: '#FFFFFF',
    fontSize: '18px',
  },
  subTitle: {
    color: '#D8D8D8',
  },
  wrapper: {
    maxWidth: '390px',
    minWidth: '390px',
  },
}))

function SideModal({
  children,
  isOpen,
  hideSave,
  onClose,
  position,
  title,
  subTitle,
  btnText,
  btnCallback,
}) {
  const classes = useStyles()

  return (
    <Drawer anchor={position} open={isOpen} onClose={onClose}>
      <div className={classes.wrapper}>
        <Grid container className={classes.customContainer}>
          <div className={classes.header}>
            <img className={classes.back} src={leftArrow} alt="Voltar" onClick={onClose} />
            <Grid item xs={11}>
              <Typography className={classes.title}>{title}</Typography>
              <Typography className={classes.subTitle}>{subTitle}</Typography>
            </Grid>
          </div>
          <div className={classes.children}>{children}</div>
          {!hideSave && (
            <Button className={classes.btnFooter} onClick={btnCallback}>
              {btnText}
            </Button>
          )}
        </Grid>
      </div>
    </Drawer>
  )
}

SideModal.defaultProps = {
  btnCallback: () => {},
  hideSave: false,
  onClose: () => {},
  position: 'right',
}

SideModal.propTypes = {
  btnCallback: PropTypes.func,
  btnText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideSave: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  position: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SideModal
