// eslint-disable jsx-a11y/no-noninteractive-element-interactions
// eslint-disable jsx-a11y/click-events-have-key-events
import React from 'react'
import { Link, makeStyles } from '@material-ui/core'
// import Senna from './img/socioemocionais_1157.svg'
import Logo from './img/Logo - Plataforma Farol_Fundo Azul.png'
import MenuMobile from '../MenuMobile'
import { useResponsiveInfo } from '../../shared/hooks'
import styles from './Header.style'

const useStyles = makeStyles(styles)

export default function Header() {
  const { isDesktop } = useResponsiveInfo()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {!isDesktop && (
        <div className={classes.textContainer}>
          <MenuMobile />
        </div>
      )}
      {/* <div style={styles.textContainer}>
        <img alt="Logo Senna" src={Senna} />
      </div> */}
      <Link href="/">
        <img
          alt="Logo Senna"
          src={Logo}
          className={!isDesktop ? classes.logo : classes.logoDesktop}
        />
      </Link>
    </div>
  )
}
