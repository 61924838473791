import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { matchSorter } from 'match-sorter'

const RegionalAutocomplete = ({
  options,
  label,
  handleClose,
  regionals,
  arrayHelpers: { push },
  isEditing,
  error,
  helperText,
}) => {
  const [selected, setSelected] = useState(null)
  const [currentList, setCurrentList] = useState([])

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }],
    })

  useEffect(() => {
    const getCurrentList = () => {
      return options.filter(
        regional => !regionals?.find(selectedRegional => regional.id === selectedRegional.id),
      )
    }

    const listWithOutSelected = getCurrentList()
    setCurrentList(listWithOutSelected)
  }, [regionals, options])

  const handleRegionals = (event, value) => {
    if (!value && typeof handleClose === 'function') {
      handleClose()
    }
  }

  const handleRegionalListChange = (event, selectedOption) => {
    if (selectedOption) {
      if (selectedOption.value === 'select-all') {
        currentList.filter(option => {
          if (option.value !== 'select-all') {
            push(option)
          }
          return null
        })
        setSelected(selectedOption)
      } else {
        push(selectedOption)
        setSelected(selectedOption)
      }
    }

    return handleRegionals(event, selectedOption)
  }

  return (
    <Autocomplete
      disabled={isEditing && isEditing === true}
      disableCloseOnSelect
      disableListWrap
      options={currentList}
      getOptionLabel={option => {
        if (option) {
          if (option.name) return `${option.name}`
        }
        return null
      }}
      onKeyDown={() => {}}
      filterOptions={(opt, params) => {
        const filtered = filterOptions(opt, params)
        if (options.length > 0 && currentList.length > 0) {
          return [{ name: 'SELECIONAR TODAS', value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={(event, value) => handleRegionalListChange(event, value)}
      noOptionsText="Nenhuma opção disponível"
      value={selected}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
}

RegionalAutocomplete.propTypes = {
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  regionals: PropTypes.array.isRequired,
}

RegionalAutocomplete.defaultProps = {
  handleChange: () => {},
  handleClose: () => {},
  options: [],
  error: false,
}

export default React.memo(RegionalAutocomplete)
