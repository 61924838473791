import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import styles from './ModalDialogDefault.style'
import { getSanitizedHtmlString } from '../../utils/SanitizeHtml'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ModalDialogDefault({
  id,
  text,
  textConfirm,
  textClose,
  title,
  open,
  cancel,
  backgroundColor,
  handleConfirm,
  handleClose,
}) {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        id={id}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        maxWidth={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ backgroundColor }}
      >
        <DialogContent className={classes.dialog}>
          <Typography className={classes.title} style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography className={classes.text} component="label">
              <Typography
                display="inline"
                dangerouslySetInnerHTML={{ __html: getSanitizedHtmlString(text) }}
              />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Grid container direction="row" justifyContent="space-around">
            <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={6}>
              <Button
                color="white"
                size="large"
                style={{ minWidth: '200px', marginRight: '5px' }}
                onClick={handleClose}
              >
                {textClose}
              </Button>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={6}>
              <Button
                color="primary"
                size="large"
                style={{ minWidth: '200px', marginLeft: '5px' }}
                onClick={handleConfirm}
              >
                {textConfirm}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ModalDialogDefault.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
