import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import YouTube from '@material-ui/icons/YouTube'
import Instagram from '@material-ui/icons/Instagram'
import LinkedIn from '@material-ui/icons/LinkedIn'

const facebook = 'https://www.facebook.com/institutoayrtonsenna'
const twitter = 'https://twitter.com/instayrtonsenna'
const youtube = 'https://www.youtube.com/user/InstitutoAyrtonSenna'
const instagram = 'https://www.instagram.com/institutoayrtonsenna/'
const linkedin = 'https://www.linkedin.com/company/instituto-ayrton-senna/'

function handleIconClick(url) {
  window.location = url
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: '#3E3E3E',
    minHeight: '129px',
    padding: '20px 40px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    marginBottom: 7,
    lineHeight: '17px',
    color: '#FFF',
  },
  addressTextRight: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    textAlign: 'right',
    lineHeight: '14px',
    color: '#FFF',
  },
  addressText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FFF',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#003C78',
    height: '50px',
  },
  copyright: {
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FFF',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icons: {
    color: '#FFF',
    height: '25px',
    width: '25px',
    margin: '0 6px 7px 0',
    cursor: 'pointer',
  },
  linkText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '14px',
    fontSize: '12px',
    color: '#FFF',
    marginLeft: '27px',
  },
  link: {
    color: '#FFF',
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    marginLeft: '4px',
  },
}

const FooterLoggedOut = () => {
  return (
    <>
      <div style={styles.container}>
        <div>
          <Typography variant="body1" style={styles.title}>
            Instituto Ayrton Senna
          </Typography>
          <Typography variant="body1" style={styles.addressText}>
            Rua Dr. Fernandes Coelho, 85 - 15 andar
          </Typography>
          <Typography variant="body1" style={styles.addressText}>
            Telefone: (11) 2974-3000
          </Typography>
          <Typography variant="body1" style={styles.addressText}>
            Pinheiros - São Paulo - SP
          </Typography>
          <Typography variant="body1" style={styles.addressText}>
            05423-040 - Brasil
          </Typography>
        </div>
        <div>
          <Typography variant="overline" style={styles.title}>
            Siga o Instituto nas Redes Sociais
          </Typography>
          <div style={styles.iconsContainer}>
            <IconButton onClick={() => handleIconClick(facebook)}>
              <Facebook style={styles.icons} />
            </IconButton>
            <IconButton onClick={() => handleIconClick(twitter)}>
              <Twitter style={styles.icons} />
            </IconButton>
            <IconButton onClick={() => handleIconClick(youtube)}>
              <YouTube style={styles.icons} />
            </IconButton>
            <IconButton onClick={() => handleIconClick(instagram)}>
              <Instagram style={styles.icons} />
            </IconButton>
            <IconButton onClick={() => handleIconClick(linkedin)}>
              <LinkedIn style={styles.icons} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterLoggedOut
