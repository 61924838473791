import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputLabel, Select, MenuItem, FormControl } from '@material-ui/core'

const CitiesSelect = ({ cities, handleCitiesChange, setSelectedCity, selectedCity }) => {
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [inputLabel])

  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <InputLabel ref={inputLabel}>Município</InputLabel>
      <Select
        value={selectedCity}
        labelWidth={labelWidth}
        onChange={e => {
          handleCitiesChange(e.target.value)
          setSelectedCity(e.target.value)
        }}
      >
        {cities.map(city => (
          <MenuItem key={city.id} value={city}>
            {city.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CitiesSelect.propTypes = {
  cities: PropTypes.array.isRequired,
  handleCitiesChange: PropTypes.func.isRequired,
  selectedCity: PropTypes.any.isRequired,
  setSelectedCity: PropTypes.func.isRequired,
}

export default CitiesSelect
