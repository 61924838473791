import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Typography, Modal, Grid, Button } from '@material-ui/core'

import TermsBody from './TermsBody'
import styles from './TermsAndPolicy.style'

const useStyles = makeStyles(styles)

const Terms = ({ isOpen, setModalState, setAccepted }) => {
  const classes = useStyles()
  const [scrollIsFinish, setScrollIsFinish] = useState(false)

  const setTermAccepted = () => {
    setAccepted(true)
    setModalState(false)
  }
  const closeTerms = () => {
    setModalState(false)
    setAccepted(false)
  }

  const handleScrollTerm = event => {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    const isFinished = ((scrollHeight - scrollTop) / clientHeight) * 100 <= 101

    if (isFinished) {
      setScrollIsFinish(true)
    } else {
      setScrollIsFinish(false)
    }
  }

  return (
    <Modal open={isOpen} onClose={closeTerms}>
      <>
        <Grid container className={classes.modal}>
          <Grid item className={classes.title}>
            <Typography variant="h5">
              TERMOS DE USO DA PLATAFORMA SOCIOEMOCIONAIS BNCC - SISTEMA DE AVALIAÇÃO E
              MONITORAMENTO DE COMPETÊNCIAS SOCIOEMOCIONAIS{' '}
            </Typography>
          </Grid>
          <Grid id="text-box" item className={classes.termBox} onScroll={handleScrollTerm}>
            <TermsBody />
          </Grid>

          <Grid item container justify="center" className={classes.buttonsGroup}>
            <Grid item>
              <Button
                type="button"
                className={classes.button}
                color="secondary"
                variant="outlined"
                size="large"
                onClick={closeTerms}
              >
                REJEITAR
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                disabled={!scrollIsFinish}
                className={classes.buttonAccept}
                classes={{ disabled: classes.buttonDisabled }}
                onClick={setTermAccepted}
                size="large"
              >
                Declaro que li e aceito os Termos de Uso da Plataforma Socioemocionais BNCC
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Modal>
  )
}

Terms.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setAccepted: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
}

export default Terms
