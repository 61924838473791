/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Button,
  RadioGroup,
  Radio,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { v4 as uuidv4 } from 'uuid'
import { AccountCircle } from '@material-ui/icons'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import styles from './StepForm.style'
import close from '../../../../../../components/ResearcherInstructionAndWelcome/img/close.svg'
import { useLoading, useResponsiveInfo, useSnackbar } from '../../../../../../shared/hooks'
import AuthContext from '../../../../../../contexts/AuthContext'
import AttachmentVisualization from '../../../components/AttachmentVisualization/AttachmentVisualization'
import UserService from '../../../../../../services/UserService'
import CheckboxAssessmentControl from '../CheckBox/CheckBoxAssessmentControl'
import ResearcherAssessmentTextField from '../TextField/TextField'
import api from '../../../../../../services/api'

const StepForm = ({
  questions,
  handleChange,
  handleSubmit,
  handleChangeChildren,
  setFieldValue,
  closeApplication,
  values,
  handleBack,
  researcherAssessment,
  assessmentPartnershipCollaborator,
  isCardsClickable,
  setIsCardsClickable,
  handleButtonNext,
  hasNextButton,
}) => {
  const [showAnswerKeyChildren, setShowAnswerKeyChildren] = useState([])
  const [qtdQuestionsWithoutAnswers, setQtdQuestionsWithoutAnswers] = useState(0)
  const [blockNext, setBlockNext] = useState(true)
  const [childrenAnswers, setChildrenAnswers] = useState([])
  const [loadCount, setLoadCount] = useState(0)
  const [attachmentCount, setAttachmentCount] = useState(-1)
  const [isLoading, setIsLoading] = useState(true)
  const { isDesktop, isMobile, isTablet, isPortrait, isLandscape } = useResponsiveInfo()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [showFullscreenButton, setShowFullscreenButton] = useState(true)
  const { showLoading, hideLoading } = useLoading()
  const { snackbarError } = useSnackbar()
  const { currentUser } = useContext(AuthContext)
  const isSennaKids = currentUser?.codExaminee !== undefined
  const isMobileSennaKids = isSennaKids && !isDesktop

  const isIPhone = /iPhone/.test(navigator.userAgent)
  const useStyles = makeStyles(styles(isMobile))
  const classes = useStyles()

  useEffect(() => {
    const questionsWithoutAnswer = questions.filter(q => {
      if (q.answerKey.type === 'PUPPETS') {
        if (q.answerKey.items[0]?.answerKeyChildren) {
          return values[q.id]?.parentValue === null && values[q.id]?.childValue === null
        }
        return values[q.id]?.parentValue === null
      }
      return (
        values[q.id]?.parentValue === null ||
        values[q.id].parentValue?.length === 0 ||
        values[q.id].parentValue === ''
      )
    })
    setQtdQuestionsWithoutAnswers(questionsWithoutAnswer.length)
    setBlockNext(questionsWithoutAnswer.length > 0)
  }, [values, questions])

  const incrementCounter = () => {
    setLoadCount(prevCount => prevCount + 1)
  }

  const toggleAnswerKeyChildren = (question, value) => {
    const obj = showAnswerKeyChildren
    obj[question.id] = []
    let qtdChildrenAnswers = 0
    const objAnswers = childrenAnswers

    question.answerKey.items.forEach(questionItem => {
      if (parseInt(value) === questionItem.id) {
        obj[question.id][questionItem.id] = true
        if (questionItem.answerKeyChildren) {
          qtdChildrenAnswers += 1
        }
      } else {
        obj[question.id][questionItem.id] = false
      }
    })
    objAnswers[question.id] = qtdChildrenAnswers

    setChildrenAnswers(objAnswers)
    setShowAnswerKeyChildren(obj)
  }

  useEffect(() => {
    if (questions.length === 0) return

    const obj = []
    questions.forEach(question => {
      childrenAnswers[question.id] = 0
      obj[question.id] = []
      question.answerKey.items.forEach(parentAnswer => {
        parentAnswer.uuid = uuidv4()
        if (
          values[question.id]?.parentValue &&
          values[question.id].parentValue === parentAnswer.id
        ) {
          obj[question.id][parentAnswer.id] = true
          toggleAnswerKeyChildren(question, values[question.id].parentValue)
        } else {
          obj[question.id][parentAnswer.id] = false
        }
      })
    })
    setShowAnswerKeyChildren(obj)

    let attachmentCountTemp = 0
    questions.forEach(question => {
      question.notebookItemAttachments.forEach(attachment => {
        if (
          attachment.attachment.type.indexOf('video') >= 0 ||
          attachment.attachment.type.indexOf('audio') >= 0
        ) {
          attachmentCountTemp += 1
        }
      })
      question.answerKey.items.forEach(parentAnswer => {
        attachmentCountTemp += parentAnswer.attachments.filter(
          attachment =>
            attachment.type.indexOf('video') >= 0 || attachment.type.indexOf('audio') >= 0,
        ).length
      })
    })
    setAttachmentCount(attachmentCountTemp)
    localStorage.setItem('initialDateTimeSessionTemp', Date.now())
  }, [questions])

  const renderAnswerKeyChildren = (question, itemChildren) => {
    return (
      <Grid
        style={{
          display: 'flex',
          marginBottom: '10px',
        }}
      >
        {itemChildren.answerKeyChildren.items.map(option => (
          <Button
            style={{
              backgroundColor:
                option.id === values[question.id].childValue ? '#1c8410' : 'rgb(0,0,0,0.12)',
            }}
            value={option.id.toString()}
            className={`${classes.alternativeButton} ${classes.alternative}`}
            key={`${option.id}`}
            aria-label="list"
            disabled={!isCardsClickable}
            onClick={async event => {
              showLoading()
              window.event.cancelBubble = true
              event.stopPropagation()

              const childAnswer = {
                parentValue: values[question.id].parentValue,
                childValue: parseInt(option.id.toString()),
              }
              setFieldValue(question.id, childAnswer)

              const statusResponse = await handleChangeChildren(
                question,
                values[question.id].parentValue,
                option.id.toString(),
              )
              hideLoading()
              if (statusResponse === 201) {
                const updatedValues = {}
                updatedValues[question.id] = childAnswer
                await handleSubmit(updatedValues, { setSubmitting: () => {} })
              } else {
                snackbarError('Erro no salvamento da resposta')
              }
            }}
          >
            <Typography className={classes.childAnswer}>{option.description}</Typography>
          </Button>
        ))}
      </Grid>
    )
  }

  const checkIsSelected = (question, option) => option.id === values[question.id].parentValue

  const returnSelectedBackgroundColor = (question, option) => {
    const isSelected = checkIsSelected(question, option)

    if (question.answerKey.type === 'MULTIPLE_CHOICE') {
      return isSelected ? '#003C78' : 'white'
    }
    return isSelected ? '#d9f2ab' : 'white'
  }

  const returnSelectedTextColor = (question, option) => {
    const isSelected = checkIsSelected(question, option)

    if (question.answerKey.type === 'MULTIPLE_CHOICE') {
      return isSelected ? '#FFF' : '#003C78'
    }
    return ''
  }

  const hasFourItems = question => question?.answerKey?.items?.length === 4

  const getFontSize = (textLength, question) => {
    let size = 0

    if (hasFourItems(question)) {
      size = textLength <= 35 ? 25 : 20
    } else if (textLength <= 40) {
      size = 28
    } else {
      const lineLength = textLength - 40
      const factor = 2.5
      const constant = 33
      size = constant - lineLength / factor

      if (size <= 20) size = 20
    }

    return `${size}px`
  }

  const getHeightSize = () => {
    if (isMobileSennaKids && isLandscape) {
      return '100px'
    }

    if (isMobileSennaKids && isPortrait) {
      return '120px'
    }

    return '70px'
  }

  const getAttachmentPosition = option => {
    let position = ''
    option.attachments.forEach(attachment => {
      position = attachment.position
    })
    return position || 'BOTTOM'
  }

  const countPlayableAttachments = option => {
    let playableAttachments = 0
    option.attachments.forEach(attachment => {
      if (attachment.type.indexOf('video') >= 0 || attachment.type.indexOf('audio') >= 0) {
        playableAttachments += 1
      }
    })
    return playableAttachments
  }

  const renderDescriptionAndAttachmentPuppet = (option, question) => {
    return (
      <>
        <Grid className={classes.descriptionGroup}>
          <Typography
            style={{
              margin: '1em',
              fontWeight: 'bolder',
              maxWidth: '90%',
              width: '400px',
              height: getHeightSize(),
              fontSize: getFontSize(option.description.length, question),
            }}
            variant="h5"
          >
            {option.description}
          </Typography>

          <Radio
            id={option.uuid}
            key={`${option.id}`}
            center
            value={option.id.toString()}
            className={classes.hiddenRadio}
          />
        </Grid>

        {option.attachments && option.attachments.length > 0 && (
          <AttachmentVisualization
            style={{ width: 'unset' }}
            item={option}
            itemType="answer"
            incrementCounter={incrementCounter}
            answerOptionsSize={question?.answerKey?.items?.length}
          />
        )}
      </>
    )
  }

  const renderDescriptionAndAttachmentMultipleChoice = (option, question) => {
    const position = getAttachmentPosition(option)

    if (position === 'BOTTOM') {
      return (
        <>
          <Grid className={classes.optionContainer}>
            <Radio
              id={option.uuid}
              key={`${option.id}`}
              left
              value={option.id.toString()}
              classes={{ checked: classes.checked }}
            />

            <Typography
              style={{
                paddingTop: '2px',
                textAlign: 'center',
                margin: '5px',
                display: 'contents',
                fontWeight: 'bolder',
                maxWidth: '90%',
                width: '400px',
                height: getHeightSize(),
                fontSize: '18px',
                paddingLeft: '10px',
              }}
            >
              {option.description}
            </Typography>
          </Grid>

          <Grid className={classes.attachmentContainer}>
            {option.attachments && option.attachments.length > 0 && (
              <AttachmentVisualization
                className={classes.attachment}
                item={option}
                itemType="answer"
                incrementCounter={incrementCounter}
                answerOptionsSize={question?.answerKey?.items?.length}
              />
            )}
          </Grid>
        </>
      )
    }

    if (position === 'TOP') {
      return (
        <>
          <Grid className={classes.attachmentContainer}>
            {option.attachments && option.attachments.length > 0 && (
              <AttachmentVisualization
                className={classes.attachmentMultipleChoice}
                item={option}
                itemType="answer"
                incrementCounter={incrementCounter}
                answerOptionsSize={question?.answerKey?.items?.length}
              />
            )}
          </Grid>
          <Grid className={classes.optionCenterContainer}>
            <Typography
              style={{
                paddingTop: '2px',
                textAlign: 'center',
                margin: '5px',
                display: 'contents',
                fontWeight: 'bolder',
                maxWidth: '90%',
                width: '400px',
                height: getHeightSize(),
                fontSize: '18px',
              }}
            >
              {option.description}
            </Typography>

            <Radio
              id={option.uuid}
              key={`${option.id}`}
              center
              value={option.id.toString()}
              className={classes.hiddenRadio}
            />
          </Grid>
        </>
      )
    }

    if (position === 'RIGHT') {
      return (
        <Grid className={classes.optionHorizontalContainer}>
          <Radio
            id={option.uuid}
            key={`${option.id}`}
            center
            value={option.id.toString()}
            className={classes.hiddenRadio}
          />
          <Typography
            style={{
              display: 'contents',
              margin: '0.5em',
              fontWeight: 'bolder',
              maxWidth: '90%',
              width: '400px',
              height: getHeightSize(),
              fontSize: '18px',
            }}
          >
            {option.description}
          </Typography>

          {option.attachments && option.attachments.length > 0 && (
            <AttachmentVisualization
              className={classes.attachmentMultipleChoice}
              item={option}
              itemType="answer"
              incrementCounter={incrementCounter}
              answerOptionsSize={question?.answerKey?.items?.length}
            />
          )}
        </Grid>
      )
    }

    if (position === 'LEFT') {
      return (
        <Grid className={classes.optionHorizontalContainer}>
          <Radio
            id={option.uuid}
            key={`${option.id}`}
            center
            value={option.id.toString()}
            className={classes.hiddenRadio}
          />

          {option.attachments && option.attachments.length > 0 && (
            <AttachmentVisualization
              className={classes.attachmentMultipleChoice}
              item={option}
              itemType="answer"
              incrementCounter={incrementCounter}
              answerOptionsSize={question?.answerKey?.items?.length}
            />
          )}

          <Typography
            style={{
              display: 'contents',
              margin: '0.5em',
              fontWeight: 'bolder',
              maxWidth: '90%',
              width: '400px',
              height: getHeightSize(),
              fontSize: '18px',
            }}
          >
            {option.description}
          </Typography>
        </Grid>
      )
    }

    return <></>
  }

  const renderDescriptionAndAttachment = (option, question) => {
    const questionType = question.answerKey.type
    if (questionType === 'PUPPETS') {
      return renderDescriptionAndAttachmentPuppet(option, question)
    }
    if (questionType === 'MULTIPLE_CHOICE') {
      return renderDescriptionAndAttachmentMultipleChoice(option, question)
    }
    return <></>
  }

  const renderPuppets = question => (
    <Grid>
      <RadioGroup
        style={{
          width: '100%',
          justifyContent: 'center',
          display: question.orientation === 'HORIZONTAL' ? 'flex' : 'grid',
          flexWrap: isMobileSennaKids ? 'nowrap' : 'wrap',
          transform: isMobileSennaKids && isLandscape ? 'scale(0.65)' : 'scale(1)',
          ...(isDesktop
            ? {} // Algo específico para desktop
            : {
                flexDirection: isMobileSennaKids && isLandscape ? 'row' : 'column',
                marginTop: isMobileSennaKids && isLandscape ? '-75px' : '',
              }),
        }}
        row
        aria-label={`${question.id}`}
        id={`${question.id}`}
        name={`${question.id}`}
        value={`${values[question.id] !== undefined ? values[question.id].parentValue : null}`}
      >
        {question.answerKey.items.map(option => (
          <Grid
            key={option.id}
            style={{
              width: 'auto',
              height: isMobileSennaKids && isPortrait ? '100%' : '',
              marginLeft: isDesktop || (isMobileSennaKids && isLandscape) ? '15px' : '',
              marginRight: isDesktop || (isMobileSennaKids && isLandscape) ? '15px' : '',
              marginBottom: '15px',
              alignSelf: isDesktop || isLandscape ? 'start' : '',
              border: '2px solid',
              borderRadius: '10px',
              cursor: 'pointer',
              visibility: countPlayableAttachments(option) > 0 ? 'hidden' : 'visible',
              opacity: countPlayableAttachments(option) > 0 ? '0' : '1',
              backgroundColor: returnSelectedBackgroundColor(question, option),
            }}
            id={`${option.id}`}
            onClick={
              !isCardsClickable
                ? () => {}
                : () => {
                    setFieldValue(question.id, {
                      parentValue: parseInt(option.id),
                      childValue: null,
                    })
                    handleChange(question, option, values)
                    toggleAnswerKeyChildren(question, option.id)
                  }
            }
          >
            {renderDescriptionAndAttachment(option, question)}

            {option.answerKeyChildren &&
            showAnswerKeyChildren[question.id] &&
            showAnswerKeyChildren[question.id][option.id]
              ? renderAnswerKeyChildren(question, option)
              : null}
          </Grid>
        ))}
      </RadioGroup>
    </Grid>
  )

  const renderInputMultipleChoice = question => {
    return (
      <Grid>
        <RadioGroup
          style={{
            justifyContent: 'center',
            display: 'grid',
            flexWrap: 'wrap',
            transform: 'scale(1)',
          }}
          row
          aria-label={`${question.id}`}
          id={`${question.id}`}
          name={`${question.id}`}
          value={`${values[question.id] !== undefined ? values[question.id].parentValue : null}`}
        >
          {question.answerKey.items.map(option => (
            <Grid
              key={option.id}
              style={{
                width: 'auto',
                height: isMobileSennaKids && isPortrait ? '100%' : '',
                marginLeft: isDesktop || (isMobileSennaKids && isLandscape) ? '15px' : '',
                marginRight: isDesktop || (isMobileSennaKids && isLandscape) ? '15px' : '',
                marginBottom: '15px',
                alignSelf: isDesktop || isLandscape ? 'start' : '',
                border: '2px solid',
                borderRadius: '10px',
                cursor: 'pointer',
                paddingRight: '15px',
                visibility: countPlayableAttachments(option) > 0 ? 'hidden' : 'visible',
                opacity: countPlayableAttachments(option) > 0 ? '0' : '1',
                backgroundColor: returnSelectedBackgroundColor(question, option),
                color: returnSelectedTextColor(question, option),
              }}
              id={`${option.id}`}
              onClick={async () => {
                const newValues = {
                  parentValue: parseInt(option.id),
                  childValue: null,
                }
                setFieldValue(question.id, newValues)
                if (!hasNextButton) {
                  values[question.id] = newValues
                }

                handleChange(question, option, values)
                toggleAnswerKeyChildren(question, option.id)
              }}
            >
              {renderDescriptionAndAttachment(option, question)}

              {option.answerKeyChildren &&
              showAnswerKeyChildren[question.id] &&
              showAnswerKeyChildren[question.id][option.id]
                ? renderAnswerKeyChildren(question, option)
                : null}
            </Grid>
          ))}
        </RadioGroup>
      </Grid>
    )
  }

  const renderCheckBox = question => {
    return (
      <FormControl
        style={{
          width: '100%',
          justifyContent: 'center',
          display: question.orientation === 'HORIZONTAL' ? 'flex' : 'grid',
          alignItems: 'center',
          flexWrap: isMobileSennaKids ? 'nowrap' : 'wrap',
          transform: isMobileSennaKids && isLandscape ? 'scale(0.65)' : 'scale(1)',
          ...(isDesktop
            ? {} // Algo específico para desktop
            : {
                flexDirection: isMobileSennaKids && isLandscape ? 'row' : 'column',
                marginTop: isMobileSennaKids && isLandscape ? '-75px' : '',
              }),
        }}
      >
        <CheckboxAssessmentControl
          values={values}
          blockNextButton={setBlockNext}
          setFieldValue={setFieldValue}
          question={question}
          handleChange={handleChange}
          hasNextButton={hasNextButton}
        />
      </FormControl>
    )
  }

  const renderInputComboBox = question => {
    const response = question.answerKey.items.filter(e => e.id === values[question.id].parentValue)
    const returnMaxWidth = () => {
      if (isMobile) {
        return '100%'
      }
      if (isTablet) {
        return '708px'
      }

      return '1000px'
    }

    return (
      <Grid
        id="grid-combo-box"
        style={{
          justifyContent: isDesktop ? '' : 'center',
          display: isDesktop ? 'grid' : '',
          flexWrap: 'wrap',
          transform: 'scale(1)',
        }}
      >
        <Grid style={{ width: '100%' }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="combo-box-label" style={{ color: '#00000099', fontWeight: 400 }}>
              Selecione a resposta
            </InputLabel>
            <Select
              labelId="combo-box-label"
              label="Selecione a resposta"
              style={{ color: '#003C78', fontWeight: 600, maxWidth: !isMobile ? '650px' : '' }}
              value={response.length > 0 ? response[0]?.value : ''}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              }}
            >
              {question.answerKey.items.map(option => {
                return (
                  <MenuItem
                    value={option.value}
                    key={option.id}
                    className={classes.menuItem}
                    style={{
                      whiteSpace: 'normal',
                      maxWidth: returnMaxWidth(),
                    }}
                    onClick={() => {
                      const newValues = {
                        parentValue: parseInt(option.id),
                        childValue: null,
                      }
                      setFieldValue(question.id, newValues)
                      if (!hasNextButton) {
                        values[question.id] = newValues
                      }
                      handleChange(question, option, values)
                    }}
                  >
                    {option.description}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  const renderInputTextField = question => {
    return (
      <ResearcherAssessmentTextField
        blockNextButton={setBlockNext}
        question={question}
        questionsWithoutAnswer={qtdQuestionsWithoutAnswers}
        setFieldValue={setFieldValue}
        submitForm={handleChange}
      />
    )
  }

  const renderInput = question => {
    const questionType = question.answerKey.type

    if (questionType === 'PUPPETS') {
      return renderPuppets(question)
    }

    if (questionType === 'MULTIPLE_CHOICE') {
      return renderInputMultipleChoice(question)
    }

    if (question.answerKey.type === 'CHECK_BOX') {
      return renderCheckBox(question)
    }

    if (questionType === 'COMBOBOX') {
      return renderInputComboBox(question)
    }

    if (questionType === 'TEXT') {
      return renderInputTextField(question)
    }
    return <></>
  }

  const handlePersistAllTextAnswersBeforeNext = () => {
    const textQuestions = questions.filter(q => q.answerKey?.type === 'TEXT')

    return textQuestions.map(textQuestion => {
      const textQuestionId = textQuestion.answerKey.items[0].id
      const option = {
        id: textQuestionId,
        value: values[textQuestion.id].parentValue,
      }
      return api.post(`researcher-assessment-response-records`, {
        researcherAssessmentId: researcherAssessment.id,
        notebookItemId: textQuestion.id,
        answerItemId: parseInt(option.id),
        answerItemTextField: textQuestion.answerKey.type === 'TEXT' ? option : undefined,
        examineeId: currentUser.codExaminee !== undefined ? currentUser.id : '',
        initialDateTimeSessionTemp: parseInt(localStorage.getItem('initialDateTimeSessionTemp')),
      })
    })
  }

  const handleNextButtonClick = async () => {
    Promise.all(handlePersistAllTextAnswersBeforeNext()).then(async () => {
      await handleButtonNext(values)
    })
  }

  const handleFullscreenToggle = () => {
    const footerElement = document.getElementById('footerLogged-FooterLoggedIn')
    const appBarElement = document.getElementById('appBarHeaderId')
    const paperAppId = document.getElementById('paperAppId')
    const helpButton = document.getElementById('help-button')

    if (!isFullscreen) {
      if (footerElement) footerElement.style.display = 'none'
      if (appBarElement) appBarElement.style.display = 'none'
      if (paperAppId) paperAppId.style.paddingTop = '0'
      if (helpButton) helpButton.style.display = 'none'
    } else {
      if (footerElement) footerElement.style.display = ''
      if (appBarElement) appBarElement.style.display = ''
      if (paperAppId) paperAppId.style.paddingTop = ''
      if (helpButton) helpButton.style.display = 'inline'
    }
    setIsFullscreen(!isFullscreen)
  }

  const handleFullscreenClick = () => {
    if (isFullscreen) {
      setShowFullscreenButton(true)
    }
  }

  const playAttachmentsAndShowCardsByOrder = async () => {
    setIsCardsClickable(false)
    const audios = Array.from(document.getElementsByTagName('audio'))
    const videos = Array.from(document.getElementsByTagName('video'))

    const audioAndVideos = videos.concat(audios)

    let offset = 500
    let allAttachmentDurations = 0
    const secondsDelay = 1250 // 1250ms de delay
    audioAndVideos.sort((a, b) => {
      const nameA = a.id.toUpperCase()
      const nameB = b.id.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    audioAndVideos.forEach(audioOrVideo => {
      setTimeout(
        () => {
          try {
            const { style } = audioOrVideo.parentElement.parentElement.parentElement.parentElement
            style.transition = 'opacity 2s ease-out'
            style.visibility = 'visible'
            style.opacity = '1'
          } catch (e) {}
          try {
            const {
              style,
            } = audioOrVideo.parentElement.parentElement.parentElement.parentElement.parentElement
            style.transition = 'opacity 2s ease-out'
            style.visibility = 'visible'
            style.opacity = '1'
          } catch (e) {}
          try {
            const {
              style,
            } = audioOrVideo.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
            style.transition = 'opacity 2s ease-out'
            style.visibility = 'visible'
            style.opacity = '1'
          } catch (e) {}
          if (!isIPhone && !audioOrVideo.ended && !audioOrVideo.hasStarted) {
            try {
              audioOrVideo.play().catch(e => {
                console.error(e)
              })
            } catch (e) {
              console.error(e)
            }
          }
        },
        isIPhone ? 0 : offset,
      )

      offset += audioOrVideo.duration * 1000 + 1000
      // duração total dos anexos em ms + delay em ms
      allAttachmentDurations += audioOrVideo.duration * 1000 + secondsDelay
    })
    setTimeout(
      () => {
        setIsCardsClickable(true)
      },
      isIPhone ? 0 : allAttachmentDurations,
    )
  }

  const controlAttachments = useCallback(async () => {
    if (attachmentCount > 0 && loadCount === attachmentCount) {
      setIsLoading(false)
      await playAttachmentsAndShowCardsByOrder()
    } else if (attachmentCount === 0) {
      setIsLoading(false)
    }
  }, [loadCount, attachmentCount])

  useEffect(() => {
    controlAttachments()
    if ((attachmentCount > 0 && loadCount === attachmentCount) || attachmentCount === 0) {
      setIsLoading(false)
    }
  }, [loadCount, attachmentCount, controlAttachments, isIPhone])

  return (
    <>
      {isLoading && <CircularProgress className={classes.loadingSpinner} />}
      {!isSennaKids && (
        <Grid item xs={12} className={classes.headerGrid}>
          <Grid className={classes.centeredGrid}>
            <Grid xs={12} item className={classes.accountGrid}>
              <AccountCircle fontSize="large" />
              <Typography className={classes.greeting}>
                {`Olá, ${UserService.getPartnershipCollaboratorName()}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.closeGrid} onClick={closeApplication}>
            <img src={close} alt="close-action" className={classes.closeIcon} />
          </Grid>
        </Grid>
      )}
      {!isLoading && isSennaKids && isIPhone && showFullscreenButton && (
        <IconButton className={classes.buttonFullscreen} onClick={handleFullscreenToggle}>
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      )}
      <div
        onClick={isSennaKids && isIPhone && handleFullscreenClick}
        className={classes.fullscreenDiv}
      >
        <Grid
          spacing={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: isSennaKids ? 'visible' : 'auto',
          }}
        >
          {questions.map(question => (
            <Grid
              key={question.id}
              style={{
                marginBottom: !isMobileSennaKids && '10px',
                background: researcherAssessment.contrast && !isSennaKids ? '#F5F5F5' : '',
                marginTop: isSennaKids ? 0 : 30,
                padding: !isMobileSennaKids && '10px',
                width: isSennaKids || (isMobile && !isSennaKids) ? '100%' : '650px',
                height: '100%',
              }}
            >
              {!isMobileSennaKids ? (
                <Typography
                  variant="h5"
                  style={{
                    color: '#003C78',
                    fontSize: isSennaKids ? 'clamp(28px, 2vw, 60px)' : '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: '20px',
                    marginTop: !isDesktop ? '0' : '20px',
                  }}
                >
                  {question.description}
                </Typography>
              ) : null}
              <Grid className={classes.answerGrid}>
                <Grid>
                  <AttachmentVisualization
                    className={classes.attachment}
                    item={question}
                    itemType="question"
                    incrementCounter={incrementCounter}
                    answerOptionsSize={question?.answerKey?.items?.length}
                  />
                </Grid>
                {renderInput(question)}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid item xs={12} className={classes.progress}>
        <Grid justifyContent="space-between" container xs={12}>
          {!isLoading && assessmentPartnershipCollaborator.researcherAssessment.showBackButton ? (
            <Grid item xs={6} sm={2} className={classes.buttonsGrid}>
              <Button
                variant="outlined"
                onClick={() => handleBack()}
                color="secondary"
                className={classes.buttonBack}
              >
                Anterior
              </Button>
            </Grid>
          ) : (
            <Grid xs={6} sm={2} />
          )}
          {!isLoading && hasNextButton && (
            <Grid justifyContent="flex-end" container xs={6} sm={2}>
              <Grid item className={classes.buttonsGrid}>
                <Button
                  disableElevation
                  disabled={blockNext}
                  onClick={async () => {
                    await handleNextButtonClick()
                  }}
                  variant="outlined"
                  style={{
                    backgroundColor: blockNext ? '#D8D8D8' : '#FFCA00',
                    color: blockNext ? '#858585' : '#003C78',
                    opacity: blockNext ? 0.75 : 1,
                  }}
                  className={classes.buttonNext}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}
StepForm.propTypes = {
  questions: PropTypes.array,
}
StepForm.defaultProps = {
  questions: [],
}

export default StepForm
