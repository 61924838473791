import { Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'

const TextFieldForm = ({ value, error, handleChange, setFieldValue, setValue, question }) => {
  const questionTextSize = question.answerKey.textSize
  const characterLimitText = `${value.textValue ? value.textValue.length : '0'}/${
    questionTextSize === 'SHORT' ? '300' : '500'
  }`

  return (
    <Grid
      id="grid-combo-box"
      style={{
        display: 'grid',
        flexWrap: 'wrap',
        width: '100%',
        transform: 'scale(1)',
      }}
    >
      <TextField
        style={{ width: '100%' }}
        inputProps={{ style: { color: '#003C78', fontSize: 16 } }}
        name="textValue"
        error={value.textValue !== null && error}
        helperText={value.textValue !== null ? error : ''}
        handleChange={handleChange}
        multiline
        value={value.textValue}
        minRows={questionTextSize === 'SHORT' ? 1 : 5}
        onChange={event => {
          const innerValue = event.target.value
          setValue({ textValue: innerValue })
          setFieldValue(question.id, { parentValue: innerValue, childValue: null })
        }}
        label="Digite a sua resposta aqui"
        variant="outlined"
      />
      {!error && (
        <Typography style={{ color: '#00000099', fontSize: 12 }}>{characterLimitText}</Typography>
      )}
    </Grid>
  )
}

export default TextFieldForm
