import * as DOMPurify from 'dompurify'

const defaultSanitizationOptions = {
  ALLOWED_TAGS: ['b', 'i', 'u'],
}

const getSanitizedHtmlString = (htmlText, options = defaultSanitizationOptions) => {
  return DOMPurify.sanitize(htmlText, options)
}

export { getSanitizedHtmlString }
