import { useContext, useCallback } from 'react'
import { LoadingContext } from '../../contexts/LoadingContext/LoadingContext'

export function useLoading() {
  const { setIsLoading, setNeedPopUp } = useContext(LoadingContext)

  const showLoading = useCallback(() => setIsLoading(true), [setIsLoading])

  const hideLoading = useCallback(() => setIsLoading(false), [setIsLoading])

  const showLoadingWithWait = useCallback(() => {
    setIsLoading(true)
    setNeedPopUp(true)
  }, [setIsLoading, setNeedPopUp])

  const hideLoadingWithWait = useCallback(() => {
    setIsLoading(false)
    setNeedPopUp(false)
  }, [setIsLoading, setNeedPopUp])

  return {
    showLoading,
    showLoadingWithWait,
    hideLoading,
    hideLoadingWithWait,
  }
}
